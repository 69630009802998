import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import { Atm, AtmStatus, AtmType } from '../../../../../../api/atmApi';
import { SwipexLinkStatus } from '../../../../../../api/swipexApi';
import Button from '../../../../../../components/button/Button';
import { ColoredAtmStatus } from '../../../../../../components/colored-atm-status/ColoredAtmStatus';
import Loader from '../../../../../../components/loader/Loader';
import Notification from '../../../../../../components/notification/Notification';
import Pager from '../../../../../../components/pager/Pager';
import SwipexLinkNotification from '../../../../../../components/swipex-link-notification/SwipexLinkNotification';
import Table from '../../../../../../components/table/Table';
import View from '../../../../../../components/view/View';
import WithPermission from '../../../../../../components/with-permission/WithPermission';
import { ITEMS_PER_PAGE, RoutesUrls, Scopes, GLOBAL_DATE_FORMAT } from '../../../../../../constants';
import { VatmColSizes } from '../../../../../../gfx/constants';
import { ColumnedFlex, H1 } from '../../../../../../gfx/globals';
import { downloadFile } from '../../../../../../services/download-file';
import { useStoreActions, useStoreState } from '../../../../../../services/store';
import { RouteProps } from '../../../../../../typings';

import { NotificationWrapper } from './VatmListViewStyle';

const EnvironmentNameWrapper = styled.div`
	display: flex;
	font-family: PrimaryMedium;
`;

function VatmListView(props: RouteProps) {
	const [isSettingsOpened, setIsSettingsOpened] = useState<boolean[]>([]);
	const [page, setPage] = useState(1);
	const [pdfDownloadButtonEnabled, setPdfDownloadButtonEnabled] = useState<boolean>(true);

	const { vAtmList, verifications, accountLinkInfo, activeViewer } = useStoreState((state) => ({
		vAtmList: state.atm.vAtmList,
		verifications: state.verification.verifications,
		accountLinkInfo: state.swipex.accountLinkInfo,
		activeViewer: state.viewer.activeViewer,
	}));

	const { getVatmList, getAtmLoyaltyPdfBase64, getAccountLinkInfo } = useStoreActions((actions) => ({
		...actions.atm,
		...actions.swipex,
	}));

	useEffect(() => {
		const fetchAtmList = async () => {
			await getVatmList({ typeList: [AtmType.VIRTUAL], page: page, itemsPerPage: ITEMS_PER_PAGE });
		};

		const fetchAccountLinkInfo = async () => {
			if (!activeViewer) {
				return;
			}
			await getAccountLinkInfo({ userId: activeViewer.id });
		};
		if (!vAtmList) {
			fetchAtmList();
		}
		// const qsPage = qs.parse(props.location.search).page;

		// const newPage = qsPage && typeof qsPage === 'string' ? parseInt(qsPage, 10) : page;

		// has listeners when created or edited
		// if (!vAtmList || newPage !== page) {
		// }

		fetchAccountLinkInfo();
	}, [page, props.location, vAtmList, getAccountLinkInfo, getVatmList, activeViewer]);

	if (!vAtmList || !activeViewer) {
		return <Loader />;
	}

	const verificationsCompleted = verifications ? verifications.isComplete : false;

	const formatVatmName = (name: string) => {
		return name.replace(/ /g, '_');
	};

	const handleDownloadLoyaltyCode = async (atmName: string | null, vAtmId: string) => {
		if (!pdfDownloadButtonEnabled) {
			return;
		}
		// get base64
		const response = await getAtmLoyaltyPdfBase64({ atmId: vAtmId });

		if (!response || !response.payload) {
			toast.error('Could not download file, pleas try again later. If problem persists, contact support@dagpay.io');

			return;
		}

		const formattedName = atmName ? formatVatmName(atmName) : '';

		return downloadFile(atob(response.payload), `Vatm_${formattedName}_loyalty_code.pdf`);
	};

	const toggleSettingsVisibility = (atm: Atm, index: number) => (
		_e: React.MouseEvent<HTMLSpanElement | HTMLDivElement> | KeyboardEvent,
	) => {
		const isSettingsOpenedCopy = isSettingsOpened.slice();

		isSettingsOpenedCopy[index] = !isSettingsOpened[index];

		setIsSettingsOpened(isSettingsOpenedCopy);
	};

	const handlePageChanged = (newPage: number) => {
		setPage(newPage);
	};

	const handleRedirectToAddBusiness = () => {
		props.history.push(RoutesUrls.VATMS_DETAIL);
	};

	const swipexLinkPending = () => {
		if (accountLinkInfo && accountLinkInfo.linkStatus === SwipexLinkStatus.REQUESTED) {
			return true;
		}

		return false;
	};

	const renderVatmTable = (vAtm: Atm, index: number) => (
		<Table key={index}>
			<Table.Thead>
				<tr>
					<th colSpan={4}>
						<EnvironmentNameWrapper>{vAtm.name}</EnvironmentNameWrapper>
					</th>
				</tr>
			</Table.Thead>

			<tbody>
				<Table.TrPrimary
					key={vAtm.id}
					onMouseLeave={isSettingsOpened[index] ? toggleSettingsVisibility(vAtm, index) : () => null}
				>
					<Table.FixedWidthTd cellWidth={VatmColSizes.DATE_ADDED}>
						<Table.NoLabelDataWrapper justifyOption="flex-start" data-label="Date added">
							<ColumnedFlex fullWidth={false}>
								<Table.TdInsideTitle>Date added</Table.TdInsideTitle>
								{dayjs(vAtm.createdDate).format(GLOBAL_DATE_FORMAT)}
							</ColumnedFlex>
						</Table.NoLabelDataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={VatmColSizes.ADDRESS}>
						<Table.NoLabelDataWrapper justifyOption="flex-start" data-label="Address">
							<ColumnedFlex fullWidth={false}>
								<Table.TdInsideTitle>Address</Table.TdInsideTitle>
								{vAtm.location.formattedAddress}
							</ColumnedFlex>
						</Table.NoLabelDataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={VatmColSizes.STATUS} noBeforeElement={vAtm.status === AtmStatus.PENDING}>
						<Table.NoLabelDataWrapper justifyOption="flex-start" data-label="Status">
							<ColumnedFlex fullWidth={false}>
								<Table.TdInsideTitle>Status</Table.TdInsideTitle>
								<ColoredAtmStatus status={vAtm.status} />
							</ColumnedFlex>
						</Table.NoLabelDataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={VatmColSizes.ACTIONS}>
						{vAtm.status !== AtmStatus.PENDING && (
							<Table.DataWrapper data-label="Actions">
								<Table.SettingsIcon onClick={toggleSettingsVisibility(vAtm, index)}>
									<ReactSVG src="/files/svg/private/TableSettings.svg" />
								</Table.SettingsIcon>
								{isSettingsOpened[index] && (
									<Table.HiddenSettings>
										<Link to={`${RoutesUrls.VATMS_DETAIL}/${vAtm.id}`}>
											<Table.HiddenSettingsItem>
												<ReactSVG src="/files/svg/private/Edit.svg" />
												<span>Details</span>
											</Table.HiddenSettingsItem>
										</Link>
										{vAtm.status !== AtmStatus.REJECTED && (
											<Table.HiddenSettingsItem
												onClick={() => {
													setPdfDownloadButtonEnabled(false);
													handleDownloadLoyaltyCode(vAtm.name, vAtm.id);
													setTimeout(() => setPdfDownloadButtonEnabled(true), 3000);
												}}
												isDisable={!pdfDownloadButtonEnabled}
											>
												<ReactSVG src="/files/svg/private/transactions/Export.svg" />
												<span>Loyalty code</span>
											</Table.HiddenSettingsItem>
										)}
									</Table.HiddenSettings>
								)}
							</Table.DataWrapper>
						)}
					</Table.FixedWidthTd>
				</Table.TrPrimary>
			</tbody>
		</Table>
	);

	return (
		<View paddingBottom={37} paddingTop={37}>
			<H1>Dagloyalty V-ATM</H1>
			<View.ListHeader>
				<NotificationWrapper>
					<Notification>
						Connect your business or store with a Dagcoin V-ATM and accept Dagloyalty payments
					</Notification>
					{!verificationsCompleted && <Notification>Complete verifications to join Virtual ATM services</Notification>}
				</NotificationWrapper>
				{verificationsCompleted && vAtmList.items.length > 0 && (
					<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT]}>
						<Button.Secondary green onClick={handleRedirectToAddBusiness}>
							<span>Add business</span>
						</Button.Secondary>
					</WithPermission>
				)}
			</View.ListHeader>

			{swipexLinkPending() && <SwipexLinkNotification />}
			{vAtmList && vAtmList.items.length > 0 ? (
				<WithPermission permissions={[Scopes.VATMS, Scopes.GET_VATMS]}>
					{vAtmList.items.map(renderVatmTable)}
				</WithPermission>
			) : (
				<View.NoItemsFound>
					<ReactSVG src="/files/svg/private/merchant-tools/icon-disconnected.svg" />
					<p>You haven’t connected any business(es) with a Virtual ATM yet</p>
					<Button.Secondary white onClick={handleRedirectToAddBusiness}>
						Add business
					</Button.Secondary>
				</View.NoItemsFound>
			)}
			<Pager
				totalItems={vAtmList.itemCount}
				currentPage={page}
				onChange={handlePageChanged}
				totalPages={vAtmList.pageCount}
			/>
		</View>
	);
}

export default withRouter(VatmListView);
