import styled from 'styled-components/macro';
import { Color } from '../../../../../../gfx/constants';
import { media } from '../../../../../../services/media';

export const BorderSpacer = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${Color.GRAY_5};

	${media.maxTablet`
		margin-bottom: 12px;
	`}
`;

export const Table = styled.div`
	tr th:first-child {
		padding-left: 20px;
	}
`;
