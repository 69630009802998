import styled, { css, keyframes } from 'styled-components/macro';
import { Color } from '../../gfx/constants';

import PrimaryMedium from '../../gfx/fonts/Roboto-Medium.ttf';
import { FieldHeight } from '../field/FieldStyle';

export const ParentWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 170px;
`;

export const InputWrapper = styled.div`
	position: relative;
	display: flex;
	width: 170px;
	height: ${FieldHeight};
	margin-bottom: 6px;
	overflow: hidden;

	input {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		border: none;
		outline: none;
		background-color: transparent;
		background: transparent;
		color: transparent;
		text-decoration: none;

		:focus {
			text-indent: -1000px;
		}

		::selection {
			color: transparent;
		}
	}
`;

export const CellWrapper = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

export const blinkAnimation = keyframes`
	to {
		visibility: hidden;
	}
`;

export const Cell = styled.div<{ blink: boolean; fillValue: string; hasFocus: boolean; maxLengthReached?: boolean }>`
	position: relative;
	width: 35px;
	height: 100%;
	border: 1px solid ${Color.GRAY_PANELBORDER};
	border-radius: 4px;
	font-size: 16px;
	line-height: 21px;
	color: ${Color.RED_2};
	text-align: center;

	::before {
		position: absolute;
		display: block;
		content: '';
		width: 1px;
		top: 13px;
		bottom: 13px;
		left: 8px;
		background: #000;
		opacity: 0;
		transition: opacity 0.1s;
		will-change: opacity;
		animation: ${blinkAnimation} 1s steps(2, start) infinite;
	}

	::after {
		position: absolute;
		top: 13px;
		left: 12px;
		content: '';
	}

	${(props) =>
		props.hasFocus &&
		props.blink &&
		css`
			border: 1px solid ${Color.GREEN};
			::before {
				opacity: 1;
			}
		`}

	${(props) =>
		props.fillValue &&
		css`
			border: 1px solid ${Color.GRAY_PANELBORDER};
			::before {
				opacity: 0;
			}
			::after {
				content: '${props.fillValue}';
			}
		`}

	${(props) =>
		props.hasFocus &&
		props.fillValue &&
		props.maxLengthReached &&
		css`
			border: 1px solid ${Color.GREEN};
		`}
`;

export const FieldTitle = styled.div`
	height: 19px;
	width: 100%;
	color: ${Color.OPTIONAL_TEXT};
	font-size: 14px;
	line-height: 19px;
	margin-bottom: 9px;
	font-family: ${PrimaryMedium};
`;

export const ErrorWrapper = styled.div`
	height: 18px;
	width: 100%;
	color: red;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 8px;
	font-family: Secondary;
`;
