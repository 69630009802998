import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Color } from '../../../gfx/constants';
import { FieldOptionProps } from '../../field/Field';
import { defaultToBeginning, getInitialSelectedIndex, SingleValue, Option } from '../hook-select-field/HookSelectField';

import { StyledSelect, AddonFieldWrapper } from './HookAddonFieldStyles';

interface Props {
	name: string;
	options: FieldOptionProps[];
	isMulti?: boolean;
	tabIndex?: number;
	isSearchable?: boolean;
	selectedColor?: Color;
	focusedColor?: Color;
	closeMenuOnSelect?: boolean;
	formatOptionLabel?: Function;
}

export default function HookAddonSelectField(props: Props) {
	const {
		name,
		options,
		isMulti,
		tabIndex,
		isSearchable,
		selectedColor = Color.BLUE_5,
		focusedColor = Color.GRAY_5,
		closeMenuOnSelect,
		formatOptionLabel
	} = props;
	const { register, control } = useFormContext();

	const sortedOptions = defaultToBeginning(options);
	const defaultIndex = getInitialSelectedIndex(sortedOptions);
	const defaultValue = defaultIndex !== null ? sortedOptions[defaultIndex] : null;

	return (
		<AddonFieldWrapper>
			<Controller
				name={name}
				control={control}
				render={({ onBlur, onChange }: { onBlur: any; onChange: any }) => (
					<StyledSelect
						name={name}
						ref={register}
						options={sortedOptions}
						classNamePrefix={'Select'}
						components={{ SingleValue, Option }}
						onBlur={onBlur}
						onChange={onChange}
						isSearchable={!!isSearchable}
						isMulti={isMulti}
						tabIndex={tabIndex}
						defaultValue={defaultValue}
						selectedColor={selectedColor}
						focusedColor={focusedColor}
						closeMenuOnSelect={closeMenuOnSelect}
						formatOptionLabel={formatOptionLabel}
						// menuIsOpen={true}
					/>
				)}
			/>
		</AddonFieldWrapper>
	);
}
