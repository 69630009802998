import { Form as FormikForm, FormikFormProps } from 'formik';
import React from 'react';
import { css, StyledComponentProps } from 'styled-components';
import styled from 'styled-components/macro';

import { fadeInAnimation } from '../../gfx/animations';
import { Color, Gutter } from '../../gfx/constants';
import { H2 } from '../../gfx/globals';
import { media } from '../../services/media';
import { SearchField } from '../../views/private/merchant/transactions/TransactionViewStyle';
import { AccountStatus } from '../../views/private/settings/SettingsStyle';
import StyledField from '../field/FieldStyle';

const fieldColumnMargin = '50px';

interface SectionProps {
	noWrap?: boolean;
}

interface ColumnProps {
	withMargin?: boolean;
	noWidth?: boolean;
	flex?: number;
	marginBottom?: boolean;
}

const FormMixin = css`
	width: 100%;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
	border: 1px solid rgba(220, 221, 224, 0.5);
	border-radius: 4px;
	background-color: ${Color.WHITE};
	padding: 50px;

	${media.maxDesktop`
		padding: 0;
		border: none;
		background-color: transparent;
		box-shadow: none;
	`}

	.react-phone-number-input__icon--international {
		svg {
			fill: ${Color.OPTIONAL_TEXT};
		}
	}

	.react-phone-number-input__country-select-arrow {
		color: ${Color.OPTIONAL_TEXT};
	}

	label {
		color: ${Color.OPTIONAL_TEXT};
		font-family: PrimaryMedium;
		font-size: 15px;
		font-weight: 500;
		line-height: 18px;
	}

	textarea {
		color: ${Color.OPTIONAL_TEXT};
		font-family: Secondary;
		font-size: 15px;
		line-height: 19px;
		padding-left: 0.75rem;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		box-shadow: 0 0 0 30px ${Color.WHITE} inset;
		-webkit-text-fill-color: black;
	}
`;

const Private = styled(FormikForm)<any>`
	${FormMixin}
	margin: 1% 0%;
`;

const Public = styled(FormikForm)`
	background: linear-gradient(180deg, #1a2b56 0%, ${Color.BLUE_10} 100%);
	min-height: 100vh;
	color: ${Color.WHITE};
	flex-direction: column;
	padding: 88px;
	flex: 0.8;
	min-width: 520px;

	${media.maxDesktop`
		min-width: 100%;
		padding: 20px;
	`}

	input {
		color: ${Color.WHITE} !important;
	}

	i[disabled] {
		background: #2e354b;
	}

	input:disabled {
		background: #2e354b;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		box-shadow: 0 0 0 30px #162044 inset !important;
		-webkit-text-fill-color: ${Color.WHITE};
	}
`;

const Pos = styled(FormikForm)`
	${FormMixin}
	border: 1px solid rgba(220, 221, 224, 0.5);
	border-radius: 4px;
	background-color: ${Color.WHITE};
	padding: 52px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
	max-width: 685px;
	margin-left: auto;
	margin-right: auto;

	${media.maxDesktop`
		padding: 5px;
		border: none;
		background-color: transparent;
		box-shadow: none;
	`}
`;

const SignUp = styled(FormikForm)`
	display: flex;
	color: ${Color.WHITE};
	flex-direction: column;
	padding-bottom: 20px;

	input {
		color: ${Color.WHITE};
	}

	input,
	.Select__single-value,
	.Select__input,
	.Select__placeholder {
		color: ${Color.WHITE};
	}

	.Select__menu {
		background: linear-gradient(180deg, #1a2b56 0%, ${Color.BLUE_10} 100%);
	}

	.Select__option--is-focused {
		background-color: ${Color.GREEN_3};
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		box-shadow: 0 0 0 30px #162044 inset !important;
		-webkit-text-fill-color: ${Color.WHITE};
	}
`;

const Receipt = styled(FormikForm)`
	width: 100%;
	padding: 24px 20px 0px 20px;

	input {
		border: 1px solid ${Color.GRAY_BUTTON};
		border-radius: 20px;
		width: 100%;
		height: 40px;
		min-height: 40px;
		color: #444444;
		font-family: Secondary;
		font-size: 15px;
		padding-left: 1.35rem;

		&:focus {
			& ~ span {
				display: block;
			}

			font-size: 13px;
			padding-top: 21px;
			animation: ${fadeInAnimation} 0.5s;
		}
	}

	${StyledField.InputWrapper} {
		border: none;
	}

	i {
		display: none;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		box-shadow: 0 0 0 30px rgb(250, 255, 189) inset !important;
		-webkit-text-fill-color: black;
	}

	button {
		border-radius: 20px;
		background-color: ${Color.ORANGE};
		height: 40px;
	}

	@media (max-width: 320px) {
		padding: 5px 0px 0px 0px;
	}
`;

const Filters = styled(FormikForm)`
	display: flex;
	margin-right: 0;

	${media.maxTransactionTable`
		flex-direction: column;
		width: 100%;
	`}

	${media.fromTransactionTable`
		align-items: flex-end;
	`}

	${StyledField.Wrapper} {
		margin-bottom: 0;
		margin-left: ${Gutter.SMALL};

		${media.maxTransactionTable`
			width: 100%;
		`}
	}

	${SearchField} {
		${media.maxTransactionTable`
			margin-left: 0;
		`}
	}

	button {
		margin-bottom: ${Gutter.MEDIUM};

		${media.fromTransactionTable`
			margin-left: ${Gutter.SMALL};
		`}

		&[type='submit'] {
			margin-right: 0;
		}

		&:nth-child(n + 2) {
			${media.maxTransactionTable`
				margin-left: ${Gutter.SMALL};
			`}
		}
	}
`;

const Description = styled.div`
	font-family: Secondary;
	font-size: 14px;
	line-height: 19px;
	padding-top: 7px;
	padding-bottom: 18px;
`;

const PublicHeader = styled.div`
	padding-bottom: 91px;

	@media (max-width: 320px) {
		padding-bottom: 33px;
	}
`;

const PublicWrapper = styled.div`
	width: 325px;
	margin-left: auto;
	align-self: flex-end;

	${H2} {
		color: ${Color.WHITE};
	}

	@media (max-width: 1280px) {
		width: 100%;
	}
`;

const Modal = styled(FormikForm)`
	border: 0px;
	box-shadow: none;
	padding: 0px;
	min-width: 320px;

	@media (max-width: 1024px) {
		min-width: 100%;
	}

	h1 {
		text-align: center;
	}
`;

const Separator = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: calc(${Gutter.MEDIUM} + ${Gutter.MEDIUM});

	&:after {
		content: '';
		position: absolute;
		top: 100%;
		right: 0;
		margin-top: ${Gutter.MEDIUM};
		width: 100%;
		height: 1px;
		background: ${Color.GRAY_5};
	}

	svg {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	${AccountStatus} {
		${media.maxSize481`
			bottom: -23px;
		`}
	}
`;

const Block = styled.div`
	margin-bottom: 55px;

	${media.maxDesktop`
		margin-bottom: ${Gutter.LARGE};
	`}
`;

const ResponsiveBlock = styled.div`
	display: flex;
	flex-wrap: wrap;

	${media.maxDesktop`
		flex-direction: column;
	`}

	${media.fromDesktop`
		position: relative;
	`}

	& > div {
		${media.fromDesktop`
			width: calc(50% - 25px);

			&:nth-child(odd) {
				margin-right: 25px;
			}

			&:nth-child(even) {
				margin-left: 25px;
			}
		`}

		${media.maxDesktop`
			width: 100%;
			margin-right: 0px;
			margin-left: 0px;
		`}
	}
`;

const Form = (props: StyledComponentProps<'form', FormikFormProps, FormikFormProps, never>) => <Private {...props} />;

Form.InvoiceColumn = styled.div<ColumnProps>`
	display: flex;
	flex-direction: column;
	font-family: Secondary;

	${media.fromDesktop`
		margin-right: ${({ withMargin }: ColumnProps) => withMargin && '50px'};
		width: ${({ withMargin }: ColumnProps) => withMargin && `calc(50% - ${fieldColumnMargin} / 2)`};
	`}

	${media.fromNewInvoiceTable`
		margin-right: ${({ withMargin }: ColumnProps) => withMargin && '50px'};
		width: ${({ withMargin }: ColumnProps) => withMargin && `calc(50% - ${fieldColumnMargin} / 2)`};
	`}
`;

Form.InvoiceSection = styled.div<SectionProps>`
	display: flex;
	flex-wrap: wrap;

	${media.maxNewInvoiceTable`
		flex-direction: column;
	`}

	${(props) =>
		props.noWrap &&
		css`
			flex-wrap: nowrap;
		`};

	${Form.InvoiceColumn} {
		width: 100%;

		${media.fromNewInvoiceTable`
			width: calc(50% - ${fieldColumnMargin} / 2);
		`}
	}

	> div {
		${media.maxNewInvoiceTable`
			margin-right: 0;
		`}
	}
`;

Form.Column = styled.div<ColumnProps>`
	display: flex;
	flex-direction: column;
	flex: ${({ flex }) => flex && flex};
	margin-right: ${({ withMargin }) => withMargin && ''};
	width: ${({ noWidth }) => (noWidth ? '' : '100%')};
	font-family: Secondary;

	${media.maxDesktop`
		margin-bottom: ${({ marginBottom }: ColumnProps) => marginBottom && '30px'};
	`}

	${media.fromDesktop`
		margin-right: ${({ withMargin }: ColumnProps) => withMargin && '50px'};
		width: ${({ withMargin }: ColumnProps) => withMargin && `calc(50% - ${fieldColumnMargin} / 2)`};
	`}
`;

Form.Section = styled.div<SectionProps>`
	display: flex;
	flex-wrap: wrap;

	${media.maxDesktop`
		flex-direction: column;
	`}

	${(props) =>
		props.noWrap &&
		css`
			flex-wrap: nowrap;
		`};

	${Form.Column} {
		margin-right: 0;

		${media.fromDesktop`
			width: calc(50% - ${fieldColumnMargin} / 2);
		`}

		+ ${Form.Column} {
			${media.fromDesktop`
				margin-left: 50px;
			`}
		}
	}
`;

Form.TaxSection = styled.div`
	width: 120px;

	input {
		+ div {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
		}
	}
`;

Form.DiscountSection = styled.div`
	color: ${Color.GREEN_3};
`;

Form.UserModal = styled(FormikForm)`
	${StyledField.Wrapper} {
		margin-bottom: 5px;
	}

	${Form.Column} {
		margin-bottom: 0;
	}

	${H2} {
		margin-bottom: 10px;
	}
`;

Form.Public = Public;
Form.Block = Block;
Form.Separator = Separator;
Form.Modal = Modal;
Form.Description = Description;
Form.PublicHeader = PublicHeader;
Form.PublicWrapper = PublicWrapper;
Form.Pos = Pos;
Form.ResponsiveBlock = ResponsiveBlock;
Form.SignUp = SignUp;
Form.Receipt = Receipt;
Form.Filters = Filters;

export default Form;
