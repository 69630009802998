import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Loader from '../../../components/loader/Loader';
import Logo from '../../../components/logo/Logo';
import HookBaseField from '../../../components/react-hook-form/HookBaseField';
import HookPasswordField from '../../../components/react-hook-form/HookPasswordField';
import ReactHookForm, { RowPlacement } from '../../../components/react-hook-form/ReactHookFormStyle';
import { RoutesUrls, Validations } from '../../../constants';
import { useStoreActions, useStoreState } from '../../../services/store';
import { RouteProps } from '../../../typings';
import Login from '../login/LoginStyle';

interface RouteParams {
	inviteId: string;
}

interface RegisterInviteFields {
	email: string;
	password: string;
	retypePassword: string;
}

function RegisterInviteView(props: RouteProps<RouteParams>) {
	const [errorMessage, setErrorMessage] = useState('');

	const { invite } = useStoreState((state) => ({
		invite: state.invite.invite,
	}));

	const schema = Yup.object().shape({
		email: Validations.INVALID_EMAIL,
		password: Validations.PASSWORD_NOT_SECURE,
		retypePassword: Validations.PASSWORDS_DO_NOT_MATCH,
	});

	const defaultValues = {
		password: '',
		retypePassword: '',
	};

	const methods = useForm<RegisterInviteFields>({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
		mode: 'onChange',
	});

	const { getInvite, registerInvite, getViewer } = useStoreActions((actions) => ({
		...actions.invite,
		...actions.viewer,
	}));

	useEffect(() => {
		const fetchInvite = async () => {
			if (props.match.params.inviteId) {
				const { inviteId } = props.match.params;
				await getInvite({ inviteId });
			}
		};
		fetchInvite();
	}, [getInvite]);

	if (props.match.params.inviteId && !invite) {
		return <Loader />;
	}
	const businessName = invite ? invite.businessName : '';

	const handleRegisterInvite = async (input: RegisterInviteFields) => {
		const { email, retypePassword, ...filteredInput } = input;

		const response = await registerInvite({ ...filteredInput, inviteId: props.match.params.inviteId });

		if (response.success) {
			await getViewer();
			props.history.push(RoutesUrls.DASHBOARD);
			toast.success('Account successfully created');
		}

		if (response.error) {
			setErrorMessage('Something went wrong, please try again');
		}
	};

	return (
		<Login>
			<Login.Left>
				<FormProvider {...methods}>
					<ReactHookForm onSubmit={methods.handleSubmit(handleRegisterInvite)}>
						<Login.LogoWrapper>
							<Logo hasWhiteBackground />
						</Login.LogoWrapper>
						<ReactHookForm.Title>
							{`Finish creating your account to join `}
							<span>{businessName}</span>
						</ReactHookForm.Title>
						<ReactHookForm.Fields direction="column">
							<HookBaseField
								label="Email"
								name="email"
								value={invite ? invite.email : undefined}
								readOnly={invite ? true : false}
							/>
							<HookPasswordField label="Password" name="password" showPasswordStrength />
							<HookPasswordField label="Retype password" name="retypePassword" />
						</ReactHookForm.Fields>
						<ReactHookForm.Row placement={RowPlacement.RIGHT}>
							<ReactHookForm.Button type="submit">
								Sign up
								<ReactSVG src="/files/svg/icons/HookButtonArrow.svg" />
							</ReactHookForm.Button>
						</ReactHookForm.Row>
						<ReactHookForm.Error>{errorMessage}</ReactHookForm.Error>
					</ReactHookForm>
				</FormProvider>
			</Login.Left>
			<Login.Right>
				<Login.Image />
				<Login.TextWrapper>
					<Login.Welcome>Accept Dagcoin payments, effortlessly.</Login.Welcome>
					<Login.Text>Become part of a growing community of more than 500,000 users</Login.Text>
				</Login.TextWrapper>
			</Login.Right>
		</Login>
	);
}

export default withRouter(RegisterInviteView);
