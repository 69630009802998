import * as React from 'react';
import { Invoice } from '../../../../api/invoiceApi';
import { UserVerificationStatus } from '../../../../api/verificationApi';
import { Wallet } from '../../../../api/walletApi';
import DashboardSection from '../../../../components/dashboard-section/DashboardSection';
import Panel from '../../../../components/panel/Panel';
import View from '../../../../components/view/View';
import WithPermission from '../../../../components/with-permission/WithPermission';
import { BusinessType, Currency, RoutesUrls, Scopes, VerificationState } from '../../../../constants';
import { ColumnedFlex, H1, LinkButton } from '../../../../gfx/globals';
import DashboardIdentitySection from './DashboardIdentitySection';
import StatisticsBlock from './statistics-block/StatisticsBlock';
import LatestTransaction from './statistics-block/latest-transactions/LatestTransactions';
import VolumeLimitDisplay from '../../../../components/volume-limit-display/VolumeLimitDisplay';

interface Props {
	emailVerified: boolean;
	email: string;
	identityVerificationStatus: null | UserVerificationStatus;
	businessVerificationStatus: string | VerificationState;
	isBusinessVerified: boolean;
	isWalletAdded: boolean;
	isUserVerified: boolean;
	isExpired?: boolean;
	wallets: Wallet[];
	invoices: Invoice[];
	baseCurrency: Currency | null;
	handleResendEmail(): void;
	businessType: BusinessType;
}

export enum DashboardIconPath {
	SUCCESS = '/files/svg/private/Success.svg',
	EMAIL_VERIFICATION = '/files/svg/private/dashboard/EmailVerification.svg',
	PENDING = '/files/svg/private/dashboard/Pending.svg',
	FAIL = '/files/svg/private/dashboard/Fail.svg',
	IDENTITY_VERIFICATION = '/files/svg/private/dashboard/IdentityVerification.svg',
	BUSINESS_VERIFICATION = '/files/svg/private/dashboard/BusinessVerification.svg',
	ADD_WALLET = '/files/svg/private/dashboard/AddWallet.svg',
	MERCHANT_FINDER = '/files/svg/private/dashboard/MerchantFinder.svg',
	INFO = '/files/svg/private/Info.svg',
}

export default function DashboardViewTpl(props: Props) {
	const { isUserVerified, emailVerified } = props;
	const [resendEnabled, setResendEnabled] = React.useState(true);

	const getStatisticsAndLatestTransactions = () => {
		const { invoices, baseCurrency } = props;

		return (
			baseCurrency && (
				<>
					<VolumeLimitDisplay />
					<StatisticsBlock baseCurrency={baseCurrency} />
					<LatestTransaction invoices={invoices} />
				</>
			)
		);
	};

	const getDashboard = () => (
		<WithPermission
			permissions={[Scopes.INVOICES, Scopes.GET_INVOICES, Scopes.GET_RECURRING_INVOICES, Scopes.GET_EMAIL_INVOICES]}
		>
			{getStatisticsAndLatestTransactions()}
		</WithPermission>
	);

	const getIdentificationContainers = () => {
		const { identityVerificationStatus } = props;

		return (
			<React.Fragment>
				<H1 center className="getStartedTitle">Before we get started, lets setup your Dagpay account</H1>
				<View.Description className="getStartedSubTitle">
					Before you can start accepting Dagcoin payments, we need some more information from you
				</View.Description>

				<ColumnedFlex fullWidth className="verificationItems">
					<WithPermission permissions={[Scopes.AUTHENTICATION, Scopes.RESEND_EMAIL_VERIFICATION]}>
						{getEmailVerificationSection()}
					</WithPermission>
					<WithPermission permissions={[Scopes.VERIFY, Scopes.VERIFY_IDENTITY, Scopes.GET_IDENTITY_VERIFICATION]}>
						<DashboardIdentitySection
							identityVerificationStatus={identityVerificationStatus}
							expired={props.isExpired}
						/>
					</WithPermission>
					<WithPermission permissions={[Scopes.VERIFY, Scopes.VERIFY_BUSINESS, Scopes.GET_BUSINESS_VERIFICATION]}>
						{getBusinessSection()}
					</WithPermission>
					<WithPermission permissions={[Scopes.WALLETS, Scopes.CREATE_WALLET]}>
						{getWalletAddedSection()}
					</WithPermission>
					{getMerchantFinderSection()}
				</ColumnedFlex>
			</React.Fragment>
		);
	};

	function getEmailVerificationSection() {
		const { emailVerified, email, handleResendEmail } = props;

		if (emailVerified) {
			return (
				<DashboardSection
					icon={DashboardIconPath.SUCCESS}
					title="Confirm your email address"
					text="Your email has been successfully verified!"
					className="scale_icon"
				/>
			);
		}

		return (
			<DashboardSection
				icon={DashboardIconPath.EMAIL_VERIFICATION}
				title="Confirm your email address"
				text={
					<React.Fragment>
						A verification email has been sent to <span>{email}</span>. <br />If you have not received the email, check your
						spam folder{' '}
						<WithPermission permissions={[Scopes.AUTHENTICATION, Scopes.RESEND_EMAIL_VERIFICATION]}>
							or{' '}
							<LinkButton
								onClick={() => {
									if (resendEnabled) {
										setResendEnabled(false);
										handleResendEmail();
										setTimeout(() => setResendEnabled(true), 2000);
									}
								}}
							>
								resend email
							</LinkButton>
						</WithPermission>
					</React.Fragment>
				}
			/>
		);
	}

	function getBusinessSection() {
		const { businessVerificationStatus } = props;

		switch (businessVerificationStatus) {
			case VerificationState.APPROVED:
				return (
					<DashboardSection
						icon={DashboardIconPath.SUCCESS}
						title="Verify your business"
						text="Your business has been successfully verified!"
						className="scale_icon"
					/>
				);

			case VerificationState.PENDING:
				return (
					<DashboardSection
						icon={DashboardIconPath.PENDING}
						title="Business verification pending"
						text="You will receive an email regarding the status of your verification. This may take up to 48h in most cases"
						className="scale_icon"
					/>
				);

			case VerificationState.REJECTED:
				if (props.businessType === BusinessType.FREELANCE) {
					return (
						<DashboardSection
							icon={DashboardIconPath.FAIL}
							title="Business verification rejected"
							text="Your business verification has been rejected. Please review your submission to learn more about the reason for rejection"
							link={RoutesUrls.VERIFY_FREELANCE}
							className="scale_icon"
						/>
					);
				}
				return (
					<DashboardSection
						icon={DashboardIconPath.FAIL}
						title="Business verification rejected"
						text="Your business verification has been rejected. Please review your submission to learn more about the reason for rejection"
						link={RoutesUrls.VERIFY_BUSINESS}
						className="scale_icon"
					/>
				);

			default:
				if (props.businessType === BusinessType.FREELANCE) {
					return (
						<DashboardSection
							icon={DashboardIconPath.BUSINESS_VERIFICATION}
							title="Verify your business"
							text="Provide your business details and required documentation for payment processing"
							link={RoutesUrls.VERIFY_FREELANCE}
						/>
					);
				}
				return (
					<DashboardSection
						icon={DashboardIconPath.BUSINESS_VERIFICATION}
						title="Verify your business"
						text="Provide your business details and required documentation for payment processing"
						link={RoutesUrls.VERIFY_BUSINESS}
					/>
				);
		}
	}

	function getWalletAddedSection() {
		const { wallets } = props;

		if (wallets.length > 0) {
			return (
				<DashboardSection
					icon={DashboardIconPath.SUCCESS}
					title="Add a Dagcoin wallet address"
					text="Dagcoin wallet added successfully!"
					className="scale_icon"
				/>
			);
		}

		return (
			<DashboardSection
				icon={DashboardIconPath.ADD_WALLET}
				title="Add a Dagcoin wallet address"
				text="Add your DagWallet address to receive payments for invoices"
				link={RoutesUrls.INSTANT_WALLETS_DETAIL}
			/>
		);
	}

	function getMerchantFinderSection() {
		return (
			<Panel.AnchorWrapper
				href={process.env.REACT_APP_MERCHANT_PUBLIC_URL ? process.env.REACT_APP_MERCHANT_PUBLIC_URL : '/'}
				target="_blank"
			>
				<DashboardSection
					icon={DashboardIconPath.MERCHANT_FINDER}
					title="Add your business to Merchant Finder"
					tag="Recommended"
					text="Sign up with your Dagpay account and make your business discoverable on Dag Merchant Finder"
				/>
			</Panel.AnchorWrapper>
		);
	}

	return (
		<View noUnderlineForLinks paddingTop={42}>
			{isUserVerified && props.wallets.length > 0 && emailVerified && props.isBusinessVerified
				? getDashboard()
				: getIdentificationContainers()}
		</View>
	);
}
