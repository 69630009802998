import styled from 'styled-components/macro';

import { Color } from '../../gfx/constants';

export const SelectWrapper = styled.div`
	position: relative;
`;

export const LoaderWrapper = styled.div`
	margin-right: 20px;
`;

export const customReactSelectStyles = {
	container: (_provided: any, state: any) => {
		const display = 'flex';
		const backgroundColor = state.isDisabled ? '#fafafa' : 'transparent';
		const border = 'none';
		const borderRadius = '3px';
		const height = '100%';
		const width = '100%';
		const alignItems = 'center';
		const fontFamily = 'Primary';
		const fontSize = '16px';
		const lineHeight = '29px';

		return {
			display,
			alignItems,
			border,
			borderRadius,
			backgroundColor,
			height,
			width,
			fontFamily,
			fontSize,
			lineHeight,
		};
	},
	placeholder: (provided: any, state: any) => {
		const color = '#adadad';
		const display = 'flex';
		const flexGrow = 1;

		return { ...provided, display, color, flexGrow };
	},
	indicatorSeparator: (provided: any, state: any) => {
		const display = 'none';
		return { ...provided, display };
	},
	dropdownIndicator: (provided: any, state: any) => {
		const display = 'none';
		return { ...provided, display };
	},
	loadingIndicator: (provided: any, state: any) => {
		const display = 'none';
		return { ...provided, display };
	},
	control: (_: any, state: any) => {
		const padding = '14px 18px';
		const flexGrow = 1;
		const display = 'flex';

		return { padding, flexGrow, display };
	},
	option: (provided: any, state: any) => {
		const color = state.isFocused ? 'white' : 'initial';
		const cursor = 'pointer';
		const backgroundColor = state.isFocused ? Color.GREEN_3 : state.isOptionSelected ? '#43ff92' : 'initial';
		return { ...provided, cursor, backgroundColor, color };
	},
	valueContainer: (provided: any, state: any) => {
		return {};
	},
	singleValue: (provided: any, state: any) => {
		const paddingRight = '18px';
		return { ...provided, paddingRight };
	},
};
