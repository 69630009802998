import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Link, Redirect, withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import styled from 'styled-components/macro';

import { Color } from '../../../gfx/constants';
import { FieldOptionProps } from '../../../components/field/Field';
import HookBaseField from '../../../components/react-hook-form/HookBaseField';
import HookCheckboxField from '../../../components/react-hook-form/HookCheckboxField';
import HookSelectField from '../../../components/react-hook-form/hook-select-field/HookSelectField';
import ReactHookForm, { RowPlacement } from '../../../components/react-hook-form/ReactHookFormStyle';
import { RoutesUrls } from '../../../constants';
import { RouteProps } from '../../../typings';
import { SignUpFields } from './SignUpView';
import HookPasswordField from '../../../components/react-hook-form/HookPasswordField';
import { AccountTypeHeading, Agreement } from './SignUpViewStyle';
import { ButtonTypes } from '../../../components/button/Button';

const PreviousWrapper = styled.div`
	a div div svg path {
		fill: ${Color.BLUE_5};
	}

	& a :hover {
		color: ${Color.BLUE_5};
	}
`;
export const GoBack = styled.button`
	width: 60px;
	height: 21px;
	position: relative;
	padding: 0;
	margin-top: 24px;
	text-decoration: underline;
	display: flex;
	white-space: nowrap;
	color: ${Color.BLUE_10};
	font-size: 16px;
	line-height: 21px;
	font-family: primaryMedium;
	border: none;
	background-color: transparent;
	cursor: pointer;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	border-radius: 0;
`;

export const GoBackLink = styled(Link)`
	width: 60px;
	height: 21px;
	position: relative;
	padding: 0;
	margin-top: 24px;
	text-decoration: underline;
	display: flex;
	white-space: nowrap;
	color: ${Color.BLUE_10};
	font-size: 16px;
	line-height: 21px;
	font-family: primaryMedium;
	border: none;
	background-color: transparent;
	cursor: pointer;
	:hover {
		background: transparent;
		box-shadow: 0px 0px 0px transparent;
		border: 0px solid transparent;
		text-shadow: 0px 0px 0px transparent;
	}
`;

export const GoBackText = styled.div`
	padding-left: 13px;
`;
interface Props extends RouteProps {
	errorElement: JSX.Element | null;
	countries: FieldOptionProps[];
}

const FillDetailsStep = (props: Props) => {
	const { countries } = props;
	const fields = useFormContext<SignUpFields>();
	//redirect if email field is empty(unmounted form)
	if (fields.getValues().businessType === null) {
		return <Redirect to={RoutesUrls.SIGN_UP_CREATE_ACCOUNT} />;
	}
	return (
		<>
			<AccountTypeHeading>Create your Dagpay account</AccountTypeHeading>
			<ReactHookForm.FieldsTitle>
				Account details
				<ReactHookForm.Info>
					{`Already have an account? `}
					<ReactHookForm.Link to={RoutesUrls.LOGIN}>Log in</ReactHookForm.Link>
				</ReactHookForm.Info>
			</ReactHookForm.FieldsTitle>
			<ReactHookForm.Fields direction="column">
				<HookBaseField label="Company name" name="companyName" />
				<HookBaseField label="Email" name="email" />
				<HookPasswordField label="Password" name="password" showPasswordStrength />
			</ReactHookForm.Fields>
			<ReactHookForm.FieldsTitle>Personal details</ReactHookForm.FieldsTitle>
			<ReactHookForm.Fields direction="column">
				<ReactHookForm.Fields direction="row">
					<HookBaseField label="First name" name="firstName" />
					<HookBaseField label="Last name" name="lastName" />
				</ReactHookForm.Fields>
				<HookSelectField label="Country" name="countryCode" options={countries} isSearchable />
				<HookBaseField label="Referral code" name="referralCode" optional />
				<HookCheckboxField name="termsAgreement">
					<Agreement>
						{`I agree to the `}
						<a href="/files/Dagpay_Terms_of_Services.pdf" target="_blank">
							Terms of Service
						</a>
						{` and `}
						<a href="/files/DagPay_Privacy_Policy.pdf" target="_blank">
							Privacy Policy
						</a>
					</Agreement>
				</HookCheckboxField>
				<HookCheckboxField name="agreement">
					<Agreement>I am the owner of my business</Agreement>
				</HookCheckboxField>
			</ReactHookForm.Fields>
			<ReactHookForm.Row placement={RowPlacement.SPACE_BETWEEN_COLUMN}>
				<PreviousWrapper>
					<GoBack type={ButtonTypes.BUTTON} onClick={props.history.goBack}>
						<ReactSVG src="/files/svg/backArrow.svg" />
						<GoBackText>Back</GoBackText>
					</GoBack>
				</PreviousWrapper>
				<ReactHookForm.Button type="submit" form="signUp">
					Sign up
					<ReactSVG src="/files/svg/icons/HookButtonArrow.svg" />
				</ReactHookForm.Button>
			</ReactHookForm.Row>
		</>
	);
};

export default withRouter(FillDetailsStep);
