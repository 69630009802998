import React from 'react';
import styled, { StyledComponentProps } from 'styled-components/macro';

import cartoonPublicBackground2x from '../../../gfx/images/cartoonPublicBackground@2x.png';
import { media } from '../../../services/media';

const Wrapper = styled.div`
	min-height: 100vh;
	display: flex;
	overflow-y: auto;
`;

const Login = (props: StyledComponentProps<'div', {}, {}, never>) => <Wrapper {...props} />;

Login.LogoWrapper = styled.div`
	width: 140px;

	svg {
		width: 140px;
		height: 38px;
	}
`;

Login.Left = styled.div`
	padding: 20px;
	flex-grow: 1;
	flex-basis: 50%;
	margin: auto;

	form {
		max-width: 477px;
		margin: auto;
	}

	${media.maxDesktop`
		flex-basis: 100%;
	`}
`;

Login.Right = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-basis: 50%;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	background-color: rgb(3, 10, 33);
	padding: 20px;

	${media.maxDesktop`
		display: none;
	`}
`;

Login.Image = styled.div`
	position: relative;
	width: 462px;
	height: 377px;
	margin-bottom: 46px;
	background-image: url(${cartoonPublicBackground2x});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
`;

Login.TextWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
`;

Login.Welcome = styled.div`
	font-family: RobotoLight;
	font-size: 25px;
	line-height: 74px;
	letter-spacing: 0;
`;

Login.Text = styled.div`
	font-family: Secondary;
	font-size: 16px;
	line-height: 30px;
	letter-spacing: 0;
`;

Login.VerificationCodeCheckBox = styled.div`
	input {
		margin-top: -5px;
	}
`;

Login.Maintenance = styled.div`
	background-color: #09142e;
	position: fixed;
	top: 0;
	bottom: 0;
	height: 100vh;
	width: 100%;
	z-index: 1000;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;

	p {
		color: white;
		font-size: 20px;
	}

	h1 {
		color: white;
		font-size: 50px;
	}

	a {
		color: white;
		text-decoration: underline;
	}
`;

Login.MaintenanceWrapper = styled.div`
	padding: 40px;
	max-width: 700px;
`;

export default Login;
