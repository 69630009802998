import React from 'react';
import styled, { StyledComponentProps } from 'styled-components/macro';
import { Color } from '../../../gfx/constants';

const StyledSignUp = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	min-height: 100vh;
	padding: 20px;

	form {
		margin: auto;
		max-width: 477px;

		padding-top: 40px;
		padding-bottom: 40px;
	}
`;

export const Agreement = styled.div`
	margin: 0;
	font-family: Secondary;
	font-size: 14px;
	letter-spacing: 0px;
	line-height: 23px;
	word-break: keep-all;
	white-space: nowrap;
	a {
		margin: 0;
		font-family: Secondary;
		font-size: 14px;
		letter-spacing: 0px;
		line-height: 23px;

		:visited,
		:hover,
		:link {
			color: ${Color.LIGHT_BLUE};
			outline: none;
		}
		:active,
		:focus {
			color: #${Color.LIGHT_BLUE};
			outline: 1px solid ${Color.LIGHT_BLUE};
		}
	}
`;

export const AccountTypeHeading = styled.div`
	margin-top: 74px;
	margin-bottom: 47px;
	font-family: PrimaryMedium;
	font-size: 26px;
	letter-spacing: 0px;
	line-height: 30px;
`;

export const AccountTypeDescription = styled.div`
	font-family: Primary;
	font-size: 14px;
	line-height: 21px;
`;

export const PreviousWrapper = styled.div`
	a div div svg path {
		fill: ${Color.BLUE_5};
	}

	& a :hover {
		color: ${Color.BLUE_5};
	}
`;

export const GoBackToPrev = styled.div`
	padding-left: 0px;
	background: ${Color.WHITE};
	border: 1px solid ${Color.GRAY_5};
	color: ${Color.GRAY_8};
	font-weight: 500;
	font-family: 'PrimaryMedium';
	font-size: 16px;
	line-height: 24px;
	box-shadow: 0px 1px 1px rgba(16, 18, 78, 0.1);
	border-radius: 4px;
	flex: none;
	order: 0;
	flex-grow: 0;
	padding-left: 36px;
	padding-top: 7px;
	padding-bottom: 6px;
	padding-right: 12px;
	cursor: pointer;
	position: relative;
`;

const SignUp = (props: StyledComponentProps<'div', {}, {}, never>) => <StyledSignUp {...props} />;

export default SignUp;
