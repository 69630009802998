import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import { InvoiceState } from '../../../../api/invoiceApi';
import { VerificationStatus } from '../../../../api/verificationApi';
import Loader from '../../../../components/loader/Loader';
import View from '../../../../components/view/View';
import WithPermission from '../../../../components/with-permission/WithPermission';
import { ITEMS_PER_PAGE_DASHBOARD, Scopes, UserRole } from '../../../../constants';
import { ColumnedFlex, H1 } from '../../../../gfx/globals';
import isParentVerified from '../../../../services/is-parent-verified';
import isUserVerified from '../../../../services/is-user-verified';
import isWalletAdded from '../../../../services/is-wallet-added';
import { useStoreActions, useStoreState } from '../../../../services/store';

import DashboardIdentitySection from './DashboardIdentitySection';
import DashboardViewTpl from './DashboardViewTpl';

function Dashboard() {
	const { wallets, activeViewer, verifications, invoices, parentVerifications } = useStoreState((state) => ({
		activeViewer: state.viewer.activeViewer,
		verifications: state.verification.verifications,
		parentVerifications: state.verification.parentUserVerifications,
		wallets: state.wallet.wallets,
		invoices: state.invoice.invoices,
	}));

	const { getInvoices, resendEmailNotification, clearStatistics } = useStoreActions((actions) => ({
		...actions.invoice,
		...actions.viewer,
		...actions.wallet,
	}));
	const fetchInvoices = useCallback(async () => {
		await getInvoices({ state: [InvoiceState.PAID], itemsPerPage: ITEMS_PER_PAGE_DASHBOARD });
	}, [getInvoices]);

	useEffect(() => {
		clearStatistics();
		if (activeViewer && activeViewer.role !== UserRole.PENDING_ADMIN) {
			fetchInvoices();
		}
	}, [activeViewer, getInvoices, clearStatistics, fetchInvoices]);

	const invoicesNotLoaded = !invoices && activeViewer && activeViewer.role !== UserRole.PENDING_ADMIN;

	if (!activeViewer || !verifications || invoicesNotLoaded) {
		return <Loader />;
	}
	const identityState = verifications.identity ? verifications.identity.verificationStatus : null;
	const parentProfileCompleted = parentVerifications ? parentVerifications.isComplete : false;

	// Notify admin / team member when organistation owner is rejected / has uncomplete profile setup
	if (activeViewer.role !== UserRole.OWNER && !parentProfileCompleted) {
		return (
			<View noUnderlineForLinks>
				<H1 center>Organisation owner account setup not completed</H1>
				<View.Description>
					Your organisation account owner must first complete their account setup in order to continue using Dagpay
				</View.Description>
			</View>
		);
	}

	// identity verification is required for admin
	if (activeViewer.role === UserRole.PENDING_ADMIN) {
		return (
			<View noUnderlineForLinks>
				<H1 center>Before we get started, let's setup your Dagpay account</H1>
				<View.Description>
					In order to start accepting Dagcoin payments, we need some more information from you
				</View.Description>

				<ColumnedFlex fullWidth>
					<WithPermission permissions={[Scopes.VERIFY_IDENTITY]}>
						<DashboardIdentitySection identityVerificationStatus={identityState} />
					</WithPermission>
				</ColumnedFlex>
			</View>
		);
	}

	const checkBusinessState = () => {
		if (verifications && verifications.business) {
			return verifications.business.state;
		}

		return 'NOT_STARTED';
	};

	const handleResendEmail = async () => {
		try {
			const response = await resendEmailNotification();

			if (response.success) {
				toast.success('Verification email has been sent.');
			}
		} catch (e) {
			toast.error('Resending verification email failed');
		}
	};

	const isVerified = () => (activeViewer.role === UserRole.ADMIN ? isParentVerified() : isUserVerified());
	return (
		<DashboardViewTpl
			baseCurrency={activeViewer ? activeViewer.baseCurrency : null}
			emailVerified={verifications ? verifications.isEmailVerified : false}
			email={activeViewer ? activeViewer.email : ''}
			identityVerificationStatus={identityState}
			isExpired={
				verifications.identity?.identityVerificationStatus === VerificationStatus.DOCUMENT_EXPIRED_REJECTED ||
				verifications.identity?.identityVerificationStatus === VerificationStatus.VERIFICATION_EXPIRED_REJECTED
			}
			businessVerificationStatus={checkBusinessState()}
			isBusinessVerified={
				verifications.isBusinessVerified || (!!parentVerifications && parentVerifications.isBusinessVerified)
			}
			isWalletAdded={isWalletAdded()}
			isUserVerified={isVerified()}
			handleResendEmail={handleResendEmail}
			wallets={wallets && wallets.items ? wallets.items : []}
			invoices={invoices ? invoices.items : []}
			businessType={activeViewer.businessType}
		/>
	);
}

export default Dashboard;
