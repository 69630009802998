import styled, { css } from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

export const FieldWrapper = styled.div<{ size?: number }>`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	position: relative;

	width: ${(props) => (props.size ? `${props.size}px` : '100%')};

	.Select__single-value {
		color: ${Color.BLUE_9};
	}

	.Select__placeholder {
		color: ${Color.GRAY_8};
	}

	.right {
		position: absolute;
		left: 50%;
		top: 29px;
	}

	.half {
		width: 50%;
	}

	${media.maxSize800`
		width: 100%;

		.right {
			position: relative;
			left: 0px;
			top: 0px;
		}
	
		.half {
			width: 100%;
		}
	`}
`;

export const Label = styled.div<{ optional?: boolean; optionalPrivate?: boolean; optionalBubble?: boolean }>`
	position: relative;
	display: flex;
	font-family: PrimaryMedium;
	font-size: 14px;
	letter-spacing: 0px;
	line-height: 16px;
	margin-bottom: 8px;
	margin-right: 14px !important;
	color: ${Color.GRAY_8};
	/* cursor: pointer; */
	${(props) =>
		props.optional &&
		css`
			::after {
				content: '(Optional)';
				color: ${Color.BLUE_5};
				font-family: PrimaryMedium;
				font-size: 14px;
				letter-spacing: 0px;
				line-height: 16px;
				margin-bottom: 0px;
				margin-left: 5px;
			}
		`}

	${(props) =>
		props.optionalPrivate &&
		css`
			::after {
				content: '(optional)';
				color: ${Color.GRAY_8};
				font-family: PrimaryMedium;
				font-size: 14px;
				letter-spacing: 0px;
				line-height: 16px;
				margin-bottom: 0px;
				margin-left: 5px;
			}
		`}

	${(props) =>
		props.optionalBubble &&
		css`
			align-items: center;

			::after {
				content: 'Optional';
				background: #ced0d5 0% 0% no-repeat padding-box;
				border-radius: 11px;
				color: ${Color.OPTIONAL_TEXT};
				font-family: PrimaryMedium;
				font-size: 12px;
				letter-spacing: 0px;
				line-height: 16px;
				padding: 2px 18px;
				margin-left: 5px;
			}
		`}
`;

export const CheckMarkLabel = styled.span<{ optional?: boolean; optionalBubble?: boolean }>`
	position: relative;
	width: 24px;
	height: 24px;
	display: flex;
	font-family: PrimaryMedium;
	font-size: 14px;
	letter-spacing: 0px;
	line-height: 16px;
	margin-bottom: 8px;
	margin-right: 14px !important;
	cursor: pointer;
	${(props) =>
		props.optional &&
		css`
			::after {
				content: '(Optional)';
				color: ${Color.BLUE_5};
				font-family: PrimaryMedium;
				font-size: 14px;
				letter-spacing: 0px;
				line-height: 16px;
				margin-bottom: 8px;
				margin-left: 5px;
			}
		`}
`;

export const LabelLink = styled.a`
	color: ${Color.ORANGE_0};
	margin-left: 5px;
`;

export const LabelTooltipIcon = styled.span`
	margin-left: 7px;
	cursor: pointer;

	div {
		display: flex;
	}

	& .injected-svg {
		fill: ${Color.GRAY_6};
	}
`;

export const BaseInput = styled.input<{ readOnly?: boolean; prefix?: boolean }>`
	width: 100%;
	height: 100%;
	border: 1px transparent;
	border-radius: 4px;
	padding: ${({ prefix }) => (prefix ? '14px 72px' : '14px 18px')};
	outline: none;
	font-family: Primary;
	font-size: 16px;
	line-height: 22px;
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${Color.BLUE_9};
	::placeholder {
		color: ${Color.BEIGE};
		font-family: Primary;
	}
	:disabled {
		background: ${Color.GRAY_2};
		color: ${Color.BLUE_9};
	}
	${(props) =>
		props.readOnly === true &&
		css`
			background-color: #f2f2f2;
		`}

	&[type='number'] {
		appearance: textfield;
	}

	&[type='number']::-webkit-inner-spin-button,
	&[type='number']::-webkit-outer-spin-button {
		appearance: none;
		margin: 0;
	}

	${media.maxSize800`
	font-size: 14px;
	`}
`;

export const Border = styled.div<{ isMulti?: boolean; size?: number }>`
	display: flex;
	width: 100%;
	height: 50px;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 1px solid ${Color.GRAY_5};
	border-radius: 4px;

	${(props) =>
		props.isMulti &&
		css`
			height: auto;
		`}
	width: ${(props) => (props.size ? `${props.size}px` : '100%')};
`;

export const Error = styled.div`
	min-height: 19px;
	font-family: Secondary;
	font-size: 14px;
	letter-spacing: 0px;
	line-height: 19px;
	color: ${Color.RED_ERROR};
`;

export const AddOnWrapper = styled.div<{ addonSize?: number; hookBaseBackground?: string; addonHasToggle?: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${Color.GRAY_2};
	height: 100%;
	cursor: pointer;
	white-space: nowrap;
	border-left: 1px solid ${Color.GRAY_5};
	border-radius: 0 4px 4px 0;
	color: ${Color.BLUE_9};

	${(props) =>
		props.addonSize &&
		css`
			min-width: ${props.addonSize}px;
		`};

	${(props) =>
		props.hookBaseBackground === 'white' &&
		css`
			background-color: ${Color.WHITE};
		`};

	${(props) =>
		props.addonHasToggle === true &&
		css`
			& a:after {
				display: none;
			}
			& a div {
				height: 24px;
				margin-left: 4px;
				margin-right: -2px;
			}
		`};
`;

export const SubLabelWrapper = styled.div`
	left: 20px;
	color: ${Color.GRAY_8};
`;

export const PrefixNode = styled.div`
	color: ${Color.GRAY_6};
	user-select: none;
	font-size: 16px;
	line-height: 22px;
	padding-left: 18px;
`;
