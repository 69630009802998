import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import styled from 'styled-components/macro';

import { DAGCOIN_CARD_NUMBER_LENGTH, DAGCOIN_CARD_NUMBER_PART_LENGTH } from '../../constants';
import { Color } from '../../gfx/constants';
import PrimaryMedium from '../../gfx/fonts/Roboto-Medium.ttf';
import { DagcoinCardFields } from '../../views/invoices/components/DagcoinCard/DagcoinCardContent';

const StyledCardNumberInput = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	input {
		height: 48px;
		width: 100%;
		border: 1px solid ${Color.GRAY_PANELBORDER};
		border-radius: 4px;
		outline: none;
		font-size: 16px;
		line-height: 21px;
		color: ${Color.BLUE_10};
		padding: 0 18px 0;
		margin-bottom: 6px;
		background: transparent;
		background-color: transparent;
	}
`;

const FieldTitle = styled.div`
	height: 19px;
	width: 100%;
	color: ${Color.OPTIONAL_TEXT};
	font-size: 14px;
	line-height: 19px;
	margin-bottom: 9px;
	font-family: ${PrimaryMedium};
`;

const ErrorWrapper = styled.div`
	height: 18px;
	width: 100%;
	color: red;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 8px;
	font-family: Secondary;
`;

const CARD_NUMBER_GAP = '   ';
const FIELD_MAX_LENGTH =
	DAGCOIN_CARD_NUMBER_LENGTH +
	(DAGCOIN_CARD_NUMBER_LENGTH / DAGCOIN_CARD_NUMBER_PART_LENGTH - 1) * CARD_NUMBER_GAP.length;
const BETWEEN = '';
const SPACE = ' ';

interface Props {
	name: string;
	methods: UseFormMethods<DagcoinCardFields>;
	setCardNumber: (value: string) => void;
	setMoveFocus: (value: boolean) => void;
	setFieldHasFocus: (value: boolean) => void;
	id?: string;
	title?: string;
}

export default function CardNumberField({
	name,
	id = 'cardNumber',
	setCardNumber,
	setMoveFocus,
	title,
	setFieldHasFocus,
	methods,
}: Props) {
	const { register, trigger, formState, errors } = methods;

	//allow digits and group them
	const formatCardNumber = (value: string) => {
		const onlyDigits = value.replace(/\s+/g, BETWEEN).replace(/[^0-9]/gi, BETWEEN);
		const groupsRegex = new RegExp(`\\d{${DAGCOIN_CARD_NUMBER_PART_LENGTH},${DAGCOIN_CARD_NUMBER_LENGTH}}`, 'g');

		const matches = onlyDigits.match(groupsRegex);
		const match = (matches && matches[0]) || BETWEEN;
		const parts = [];

		for (let i = 0; i < match.length; i += 4) {
			parts.push(match.substring(i, i + 4));
		}

		if (parts.length) {
			return parts.join(CARD_NUMBER_GAP);
		} else {
			return onlyDigits;
		}
	};

	const moveCaret = (
		event:
			| React.KeyboardEvent<HTMLInputElement>
			| React.MouseEvent<HTMLInputElement, MouseEvent>
			| React.ChangeEvent<HTMLInputElement>,
		direction?: string,
	) => {
		const value = event.currentTarget.value;
		const currentPosition = event.currentTarget.selectionStart;

		//move over gap to left when delete or click
		if (!direction) {
			if (currentPosition) {
				const valueArray = value.substring(0, currentPosition).split('').reverse();

				let moveCount = 0;
				let hasMoved = false;
				valueArray.forEach((char) => {
					if (!hasMoved && char === SPACE) {
						moveCount += 1;
					} else {
						hasMoved = true;
					}
				});

				event.currentTarget.setSelectionRange(currentPosition - moveCount, currentPosition - moveCount);
			}

			return;
		}
		event.preventDefault();

		const moveLeft = direction === 'left';

		if (currentPosition && event.currentTarget.setSelectionRange) {
			// can take substring to according direction means movable
			const moveableString = moveLeft
				? value.substring(0, currentPosition)
				: value.substring(currentPosition, value.length);

			// to map in correct direction
			const moveableChars = moveLeft ? moveableString.split(BETWEEN).reverse() : moveableString.split(BETWEEN);

			let hasMoved = false;
			let hasMovedOverGap = false;
			let moveCount = 0;

			moveableChars.forEach((char) => {
				// digit next, move cursor and end of moving
				if (!hasMoved && char !== SPACE) {
					hasMoved = true;

					// when gap, then leave caret between number and gap, otherwise move over digit
					moveCount = hasMovedOverGap ? (moveCount += 0) : (moveCount += 1);
				}

				// move over all space gap
				if (!hasMoved && char === SPACE) {
					moveCount += 1;
					hasMovedOverGap = true;
				}
			});

			const newPosition = moveLeft ? currentPosition - moveCount : currentPosition + moveCount;
			event.currentTarget.setSelectionRange(newPosition, newPosition);
		}
	};

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const oldCursorPos = event.target.selectionStart;
		const oldValueLen = event.target.value.length;
		const formattedValue = formatCardNumber(event.target.value);
		const pureDigitValue = formattedValue.split(CARD_NUMBER_GAP).join(BETWEEN);

		event.target.value = formattedValue;

		//prevent cursor jumping
		if (oldValueLen !== oldCursorPos) {
			event.currentTarget.setSelectionRange(oldCursorPos || 0, oldCursorPos || 0);
		}

		// for displaying on visual card
		if (setCardNumber) {
			setCardNumber(pureDigitValue);
		}

		if (pureDigitValue.length === DAGCOIN_CARD_NUMBER_LENGTH && setMoveFocus) {
			trigger('cardNumber');
			// setError(name as never, { message: 'Invalid card number', type: 'manual' });
			setMoveFocus(true);
		}
	};

	const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		// backspace
		if (event.key === 'Backspace') {
			moveCaret(event);

			return;
		}

		// left
		if (event.key === 'ArrowLeft') {
			moveCaret(event, 'left');

			return;
		}

		// right
		if (event.key === 'ArrowRight') {
			const currentPosition = event.currentTarget.selectionStart;
			if (!currentPosition || !event.currentTarget.setSelectionRange) {
				return;
			}

			moveCaret(event, 'right');

			return;
		}

		// non numeric input, up, down
		if (!/\d/.exec(event.key)) {
			event.preventDefault();

			return;
		}
	};

	const handleOnFocus = () => {
		if (setFieldHasFocus) {
			setFieldHasFocus(true);
		}
	};

	const handleOnBlur = () => {
		if (formState.isDirty) {
			trigger('cardNumber');
		}
		// if (formState.isDirty) {
		// 	setError(name as never, { message: 'Invalid card number', type: 'manual' });
		// }

		if (setFieldHasFocus) {
			setFieldHasFocus(false);
		}
	};

	const handleOnClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		moveCaret(event);
	};

	return (
		<StyledCardNumberInput>
			<FieldTitle>{title}</FieldTitle>
			<input
				id={id}
				name={name}
				type="tel"
				spellCheck={false}
				maxLength={FIELD_MAX_LENGTH}
				ref={register}
				onChange={handleOnChange}
				onKeyDown={handleOnKeyDown}
				onBlur={handleOnBlur}
				onFocus={handleOnFocus}
				onClick={handleOnClick}
			/>
			<ErrorWrapper>{errors.cardNumber && errors.cardNumber.message}</ErrorWrapper>
			{/* <ErrorWrapper>
				<ErrorMessage name={name} />
			</ErrorWrapper> */}
		</StyledCardNumberInput>
	);
}
