import { NavSidebarIcons, ParentViews, RoutesUrls, Scopes } from '../../constants';
import DocumentationView from '../public/documentation/DocumentationView';

import LogOutView from './LogOutView';
import DashboardView from './merchant/dashboard/DashboardView';
import EscrowListView from './merchant/escrow/EscrowListView';
import EscrowNewDealView from './merchant/escrow/EscrowNewDealView';
import AtmDetailView from './merchant/merchant-tools/atm/detail/AtmDetailView';
import AtmListView from './merchant/merchant-tools/atm/list/AtmListView';
import EnvironmentsDetailView from './merchant/merchant-tools/environments/detail/EnvironmentsDetailView';
import EnvironmentTypeChoosingView from './merchant/merchant-tools/environments/detail/EnvironmentTypeChoosingView';
import EnvironmentsListView from './merchant/merchant-tools/environments/list/EnvironmentsListView';
import MerchantToolsView from './merchant/merchant-tools/MerchantToolsView';
import PosPairingsListView from './merchant/merchant-tools/pos-pairings/PosPairingsListView';
import VatmDetailView from './merchant/merchant-tools/vatm/detail/VatmDetailView';
import VatmListView from './merchant/merchant-tools/vatm/list/VatmListView';
import OneTimeInvoiceDetailView from './merchant/one-time-invoice/OneTimeInvoiceDetailView';
import OneTimeInvoicesListView from './merchant/one-time-invoice/OneTimeInvoicesListView';
import PosCheckoutView from './merchant/pos-checkout/PosCheckoutView';
import RecurringInvoiceDetailView from './merchant/recurring-invoice/RecurringInvoiceDetailView';
import RecurringInvoicesListView from './merchant/recurring-invoice/RecurringInvoicesListView';
import TransactionsView from './merchant/transactions/TransactionsView';
import AccountDetailsView from './settings/account-details/AccountDetailsView';
import CompanyDetailsView from './settings/company-details/CompanyDetailsView';
import CustomerLimitDetailView from './settings/customer-limits/CustomerLimitDetailView';
import CustomerLimitsListView from './settings/customer-limits/CustomerLimitsListView';
import MerchantProfileView from './settings/merchant-profile/MerchantProfileView';
import SecurityView from './settings/security/SecurityView';
import UsersDetailView from './settings/users/UsersDetailView';
import UsersListView from './settings/users/UsersListView';
import WalletDetailView from './settings/wallet/WalletDetailView';
import WalletListView from './settings/wallet/WalletListView';
import VerifyBusinessView from './verification/VerifyBusinessView';
import VerifyFreelanceView from './verification/VerifyFreelanceView';
import VerifyBusinessAddressView from './verification/VerifyBusinessAddressView';
import VerifyBusinessProofOfSharesView from './verification/VerifyBusinessProofOfSharesView';
import VerifyBusinessTiers from './verification/VerifyBusinessTiers';
import VerifyIdentityView from './verification/VerifyIdentityView';

export interface PrivateRoute {
	permissions: Scopes[];
	path: string;
	// tslint:disable-next-line:no-any
	component: React.ComponentType<any>;
	parentView?: ParentViews;
	navLinkTitle?: string;
	navSidebarTitle?: string;
	url: string;
	children?: PrivateRoute[];
	icon?: NavSidebarIcons;
	openInNewTab?: boolean;
	expanded?: boolean;
}

export const privateRoutes: PrivateRoute[] = [
	/*
		NAVIGATION SIDEBAR ROUTES
	*/

	/* Dashboard */

	{
		navSidebarTitle: 'Dashboard',
		permissions: [
			Scopes.VIEWER,
			Scopes.VIEWER_INFO,
			Scopes.VIEWER_STATISTICS,
			Scopes.GET_INVOICES,
			Scopes.GET_EMAIL_INVOICES,
			Scopes.GET_RECURRING_INVOICES,
		],
		path: RoutesUrls.DASHBOARD,
		url: RoutesUrls.DASHBOARD,
		component: DashboardView,
		icon: NavSidebarIcons.DASHBOARD,
	},

	/* Wallet details */
	
	{
		icon: NavSidebarIcons.DASHBOARD,
		permissions: [
			Scopes.WALLETS,
			Scopes.CREATE_WALLET,
			Scopes.DELETE_WALLET,
			Scopes.UPDATE_WALLET,
			Scopes.GET_WALLET,
		],
		path: `${RoutesUrls.INSTANT_WALLETS_DETAIL}/:id?`,
		url: RoutesUrls.INSTANT_WALLETS_DETAIL,
		component: WalletDetailView,
	 },

	/* Email Invoices */

	{
		navLinkTitle: 'Invoices',
		navSidebarTitle: 'Email Invoice',
		permissions: [
			Scopes.EMAIL_INVOICES,
			Scopes.RECURRING_INVOICES,
			Scopes.GET_EMAIL_INVOICE,
			Scopes.GET_EMAIL_INVOICES,
			Scopes.GET_RECURRING_INVOICE,
			Scopes.GET_RECURRING_INVOICES,
			Scopes.CREATE_EMAIL_INVOICE,
			Scopes.CREATE_RECURRING_INVOICE,
		],
		path: RoutesUrls.EMAIL_INVOICES,
		url: RoutesUrls.EMAIL_INVOICES,
		component: OneTimeInvoicesListView,
		parentView: ParentViews.EMAIL_INVOICES,
		icon: NavSidebarIcons.EMAIL_INVOICE,
		children: [
			{
				permissions: [Scopes.EMAIL_INVOICES, Scopes.GET_EMAIL_INVOICE, Scopes.CREATE_EMAIL_INVOICE],
				path: RoutesUrls.EMAIL_INVOICES_DETAIL,
				url: RoutesUrls.EMAIL_INVOICES_DETAIL,
				component: OneTimeInvoiceDetailView,
			},
			{
				navLinkTitle: 'Recurring invoices',
				permissions: [Scopes.RECURRING_INVOICES, Scopes.GET_RECURRING_INVOICES, Scopes.CREATE_RECURRING_INVOICE],
				path: RoutesUrls.RECURRING_INVOICES,
				component: RecurringInvoicesListView,
				url: RoutesUrls.RECURRING_INVOICES,
			},
			{
				permissions: [Scopes.RECURRING_INVOICES, Scopes.GET_RECURRING_INVOICE, Scopes.CREATE_RECURRING_INVOICE],
				path: RoutesUrls.RECURRING_INVOICES_DETAIL,
				url: RoutesUrls.RECURRING_INVOICES_DETAIL,
				component: RecurringInvoiceDetailView,
			},
		],
	},

	/* POS checkout */

	{
		navSidebarTitle: 'POS checkout',
		permissions: [Scopes.POS, Scopes.CREATE_POS_INVOICE],
		path: RoutesUrls.POS_CHECKOUT,
		url: RoutesUrls.POS_CHECKOUT,
		component: PosCheckoutView,
		icon: NavSidebarIcons.POS,
	},

	/* Transactions */

	{
		navSidebarTitle: 'Transactions',
		permissions: [
			Scopes.INVOICES,
			Scopes.EMAIL_INVOICES,
			Scopes.RECURRING_INVOICES,
			Scopes.GET_INVOICES,
			Scopes.GET_RECURRING_INVOICES,
			Scopes.GET_EMAIL_INVOICES,
		],
		path: RoutesUrls.TRANSACTIONS,
		url: RoutesUrls.TRANSACTIONS,
		component: TransactionsView,
		icon: NavSidebarIcons.TRANSACTIONS,
	},

	// /* Escrow */

	// {
	// 	navSidebarTitle: 'Escrow',
	// 	permissions: [Scopes.ESCROW, Scopes.CREATE_ESCROW, Scopes.UPDATE_ESCROW, Scopes.GET_ESCROW, Scopes.GET_ESCROWS],
	// 	path: RoutesUrls.ESCROW_DEALS,
	// 	url: RoutesUrls.ESCROW_DEALS,
	// 	parentView: ParentViews.ESCROW_DEALS,
	// 	component: EscrowListView,
	// 	icon: NavSidebarIcons.ESCROW,
	// 	children: [
	// 		{
	// 			navLinkTitle: 'Sales',
	// 			permissions: [Scopes.ESCROW, Scopes.CREATE_ESCROW, Scopes.UPDATE_ESCROW, Scopes.GET_ESCROW, Scopes.GET_ESCROWS],
	// 			component: EscrowListView,
	// 			path: RoutesUrls.ESCROW_DEALS,
	// 			url: RoutesUrls.ESCROW_DEALS,
	// 		},
	// 		{
	// 			navLinkTitle: 'Purchases',
	// 			permissions: [Scopes.ESCROW, Scopes.CREATE_ESCROW, Scopes.UPDATE_ESCROW, Scopes.GET_ESCROW, Scopes.GET_ESCROWS],
	// 			component: EscrowListView,
	// 			path: RoutesUrls.ESCROW_BUYER_DEALS,
	// 			url: RoutesUrls.ESCROW_BUYER_DEALS,
	// 		},
	// 		{
	// 			permissions: [Scopes.ESCROW, Scopes.CREATE_ESCROW, Scopes.UPDATE_ESCROW],
	// 			path: RoutesUrls.ESCROW_NEW_DEAL,
	// 			url: RoutesUrls.ESCROW_NEW_DEAL,
	// 			component: EscrowNewDealView,
	// 		},
	// 	],
	// },

	/* Merchant Tools */

	{
		navSidebarTitle: 'Merchant Tools',
		permissions: [],
		path: RoutesUrls.MERCHANT_TOOLS,
		url: RoutesUrls.MERCHANT_TOOLS,
		component: MerchantToolsView,
		icon: NavSidebarIcons.MERCHANT_TOOLS,
		children: [
			{
				permissions: [Scopes.POS, Scopes.POS_PAIRING, Scopes.GET_POS_PAIRINGS],
				path: RoutesUrls.POS_PAIRINGS,
				url: RoutesUrls.POS_PAIRINGS,
				component: PosPairingsListView,
			},
			{
				permissions: [Scopes.ATMS, Scopes.GET_ATM, Scopes.GET_ATMS],
				path: RoutesUrls.ATMS,
				url: RoutesUrls.ATMS,
				component: AtmListView,
			},
			{
				permissions: [Scopes.ATMS, Scopes.GET_ATM, Scopes.GET_ATMS],
				path: `${RoutesUrls.ATMS_DETAIL}/:atmId?`,
				url: RoutesUrls.ATMS_DETAIL,
				component: AtmDetailView,
			},
			{
				permissions: [Scopes.VATMS, Scopes.GET_VATM, Scopes.GET_VATMS],
				path: RoutesUrls.VATMS,
				url: RoutesUrls.VATMS,
				component: VatmListView,
			},
			{
				permissions: [Scopes.VATMS, Scopes.GET_VATM, Scopes.GET_VATMS],
				path: `${RoutesUrls.VATMS_DETAIL}/:vAtmId?`,
				url: RoutesUrls.VATMS_DETAIL,
				component: VatmDetailView,
			},
		],
	},

	/* Settings */

	{
		navSidebarTitle: 'Settings',
		permissions: [
			Scopes.USERS,
			Scopes.WALLETS,
			Scopes.CHANGE_PASSWORD,
			Scopes.GET_AUTH_QR,
			Scopes.UPDATE_USER,
			Scopes.UPDATE_INVITED_USER,
			Scopes.UPDATE_SUPPORT_EMAIL,
		],
		path: RoutesUrls.SETTINGS,
		url: RoutesUrls.SETTINGS,
		component: MerchantProfileView,
		parentView: ParentViews.SETTINGS,
		icon: NavSidebarIcons.SETTINGS,
		children: [
			{
				navLinkTitle: 'Merchant profile',
				permissions: [
					Scopes.USERS,
					Scopes.WALLETS,
					Scopes.CHANGE_PASSWORD,
					Scopes.GET_AUTH_QR,
					Scopes.UPDATE_USER,
					Scopes.UPDATE_INVITED_USER,
					Scopes.UPDATE_SUPPORT_EMAIL,
				],
				path: RoutesUrls.MERCHANT_PROFILE,
				url: RoutesUrls.MERCHANT_PROFILE,
				component: MerchantProfileView,
			},			
			{
				navLinkTitle: 'Wallets',
				permissions: [
					Scopes.WALLETS,
					Scopes.CREATE_WALLET,
					Scopes.UPDATE_WALLET,
					Scopes.DELETE_WALLET,
					Scopes.GET_WALLET,
					Scopes.GET_WALLETS,
				],
				path: RoutesUrls.WALLETS,
				url: RoutesUrls.WALLETS,
				component: WalletListView,
			},
			{
				permissions: [
					Scopes.WALLETS,
					Scopes.CREATE_WALLET,
					Scopes.DELETE_WALLET,
					Scopes.UPDATE_WALLET,
					Scopes.GET_WALLET,
				],
				path: `${RoutesUrls.WALLETS_DETAIL}/:id?`,
				url: RoutesUrls.WALLETS_DETAIL,
				component: WalletDetailView,
			},
			{
				navLinkTitle: 'Users',
				permissions: [
					Scopes.INVITES,
					Scopes.INVITED_USERS,
					Scopes.UPDATE_INVITED_USER,
					Scopes.CREATE_INVITE,
					Scopes.GET_INVITE,
					Scopes.GET_INVITES,
					Scopes.GET_INVITED_USERS,
					Scopes.DELETE_INVITE,
				],
				path: RoutesUrls.USERS,
				url: RoutesUrls.USERS,
				component: UsersListView,
			},
			{
				permissions: [Scopes.USERS, Scopes.UPDATE_INVITED_USER, Scopes.CREATE_INVITE, Scopes.UPDATE_INVITED_USER],
				path: `${RoutesUrls.USERS_DETAIL}/:id?`,
				url: RoutesUrls.USERS_DETAIL,
				component: UsersDetailView,
			},
			{
				navLinkTitle: 'Account Details',
				permissions: [Scopes.USERS, Scopes.UPDATE_USER],
				path: RoutesUrls.ACCOUNT_DETAILS,
				url: RoutesUrls.ACCOUNT_DETAILS,
				component: AccountDetailsView,
			},
			{
				navLinkTitle: 'Company Details',
				permissions: [Scopes.VERIFY, Scopes.GET_BUSINESS_VERIFICATION],
				path: RoutesUrls.COMPANY_DETAILS,
				url: RoutesUrls.COMPANY_DETAILS,
				component: CompanyDetailsView,
			},
			{
				navLinkTitle: 'Security',
				permissions: [Scopes.AUTHENTICATION, Scopes.ENABLE_AUTHENTICATOR, Scopes.CHANGE_PASSWORD],
				path: RoutesUrls.SECURITY,
				url: RoutesUrls.SECURITY,
				component: SecurityView,
			},
			{
				permissions: [
					Scopes.CUSTOMER_LIMITS,
					Scopes.GET_CUSTOMER_LIMIT,
					Scopes.GET_CUSTOMER_LIMITS,
					Scopes.CREATE_CUSTOMER_LIMIT,
					Scopes.UPDATE_CUSTOMER_LIMIT,
					Scopes.DELETE_CUSTOMER_LIMIT,
				],
				path: RoutesUrls.LIMITS,
				url: RoutesUrls.LIMITS,
				component: CustomerLimitsListView,
			},
			{
				permissions: [
					Scopes.CUSTOMER_LIMITS,
					Scopes.GET_CUSTOMER_LIMIT,
					Scopes.CREATE_CUSTOMER_LIMIT,
					Scopes.UPDATE_CUSTOMER_LIMIT,
					Scopes.DELETE_CUSTOMER_LIMIT,
				],
				path: `${RoutesUrls.LIMITS_DETAIL}/:id?`,
				url: RoutesUrls.LIMITS_DETAIL,
				component: CustomerLimitDetailView,
			},
		],
		expanded: true,
	},

	/* Documentation */

	{
		navSidebarTitle: 'Documentation',
		permissions: [],
		path: RoutesUrls.DOCUMENTATION,
		url: RoutesUrls.DOCUMENTATION,
		component: DocumentationView,
		icon: NavSidebarIcons.DOCUMENTATION,
		openInNewTab: true,
	},

	/* OTHER ROUTES */

	{
		permissions: [],
		path: RoutesUrls.LOGOUT,
		url: RoutesUrls.LOGOUT,
		component: LogOutView,
	},
	{
		permissions: [Scopes.VERIFY, Scopes.VERIFY_IDENTITY],
		path: RoutesUrls.VERIFY_IDENTITY,
		url: RoutesUrls.VERIFY_IDENTITY,
		component: VerifyIdentityView,
	},
	{
		permissions: [Scopes.VERIFY, Scopes.VERIFY_BUSINESS],
		path: RoutesUrls.VERIFY_BUSINESS,
		url: RoutesUrls.VERIFY_BUSINESS,
		component: VerifyBusinessView,
	},
	{
		permissions: [Scopes.VERIFY, Scopes.VERIFY_BUSINESS],
		path: RoutesUrls.VERIFY_FREELANCE,
		url: RoutesUrls.VERIFY_FREELANCE,
		component: VerifyFreelanceView,
	},
	{
		permissions: [Scopes.VERIFY, Scopes.VERIFY_BUSINESS],
		path: RoutesUrls.VERIFY_BUSINESS_DETAILS,
		url: RoutesUrls.VERIFY_BUSINESS_DETAILS,
		component: VerifyBusinessView,
	},
	{
		permissions: [Scopes.VERIFY, Scopes.VERIFY_BUSINESS],
		path: RoutesUrls.VERIFY_BUSINESS_REGISTRATION_DOCUMENTS,
		url: RoutesUrls.VERIFY_BUSINESS_REGISTRATION_DOCUMENTS,
		component: VerifyBusinessView,
	},
	{
		permissions: [Scopes.VERIFY, Scopes.VERIFY_BUSINESS],
		path: RoutesUrls.VERIFY_BUSINESS_ADDRESS_DOCUMENTS,
		url: RoutesUrls.VERIFY_BUSINESS_ADDRESS_DOCUMENTS,
		component: VerifyBusinessAddressView,
	},
	{
		permissions: [Scopes.VERIFY, Scopes.VERIFY_BUSINESS],
		path: RoutesUrls.VERIFY_BUSINESS_PROOF_OF_SHARES_DOCUMENTS,
		url: RoutesUrls.VERIFY_BUSINESS_PROOF_OF_SHARES_DOCUMENTS,
		component: VerifyBusinessProofOfSharesView,
	},
	{
		permissions: [Scopes.VERIFY, Scopes.VERIFY_BUSINESS],
		path: RoutesUrls.VERIFY_BUSINESS_TIERS,
		url: RoutesUrls.VERIFY_BUSINESS_TIERS,
		component: VerifyBusinessTiers,
	},
	{
		permissions: [Scopes.ENVIRONMENTS, Scopes.GET_ENVIRONMENTS, Scopes.UPDATE_ENVIRONMENT, Scopes.CREATE_ENVIRONMENT],
		path: RoutesUrls.ENVIRONMENTS,
		url: RoutesUrls.ENVIRONMENTS,
		component: EnvironmentsListView,
	},
	{
		permissions: [Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT],
		path: RoutesUrls.CHOOSE_ENVIRONMENT_TYPE,
		url: RoutesUrls.CHOOSE_ENVIRONMENT_TYPE,
		component: EnvironmentTypeChoosingView,
	},
	{
		permissions: [
			Scopes.ENVIRONMENTS,
			Scopes.GET_ENVIRONMENT,
			Scopes.UPDATE_ENVIRONMENT,
			Scopes.DELETE_ENVIRONMENT,
			Scopes.CREATE_ENVIRONMENT,
		],
		path: `${RoutesUrls.ENVIRONMENTS_DETAIL}/:integrationType/:id?`,
		url: RoutesUrls.ENVIRONMENTS_DETAIL,
		component: EnvironmentsDetailView,
	},
];
