import styled, { css } from 'styled-components/macro';

import Field from '../../../components/field/Field';
import RadioButtonGroup from '../../../components/radio-group/RadioGroup';
import { Color, Gutter } from '../../../gfx/constants';
import { H1, H2, SubtextDescriptionTextMixin } from '../../../gfx/globals';
import { media } from '../../../services/media';

interface TitleLinkProps {
	prefix?: string;
}

interface VerificationProgressUnitProps {
	active?: boolean;
	complete?: boolean;
}

export const VerificationProgressBlock = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 auto ${Gutter.SMALL};
	width: 85%;
`;

export const VerificationProgressBar = styled.div`
	background: ${Color.GRAY_5};
	display: flex;
	height: 10px;
	margin-bottom: 10px;
`;

export const VerificationProgressText = styled.div`
	color: ${Color.GREY_INFO};
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: 14px;
	font-weight: 500;

	span {
		width: 22px;
		height: 22px;
		color: ${Color.WHITE};
		text-align: center;
		margin-right: 8px;
		background: ${Color.GRAY_5};
		border-radius: 50%;
		line-height: 22px;
	}

	p {
		color: ${Color.GRAY_8};
		font-family: primary;
		line-height: 22px;
		font-weight: 500;
		margin: 0;
	}
`;

export const VerificationProgressUnit = styled.div<VerificationProgressUnitProps>`
	display: flex;
	flex-direction: column;
	flex: 1;

	${media.maxTablet`
		${VerificationProgressText} {
			color: ${Color.GREEN_3};

			p {
				display: none;
			}
		}
	`}

	&:nth-child(2) {
		margin-left: 6px;
	}

	&:nth-child(3) {
		margin-left: 6px;
	}

	&:first-child ${VerificationProgressBar} {
		border-top-left-radius: 7px;
		border-bottom-left-radius: 7px;
	}

	&:last-child ${VerificationProgressBar} {
		border-top-right-radius: 7px;
		border-bottom-right-radius: 7px;
	}

	${(props) =>
		props.active &&
		css`
			${media.maxTablet`
				flex: 4;
			`}

			${VerificationProgressBar} {
				background: ${Color.GREEN_3};
			}

			${VerificationProgressText} {
				color: ${Color.GREEN_3};

				span {
					background: ${Color.GREEN_3};
				}
				p {
					display: block;
					font-family: primaryMedium;
					color: ${Color.BLUE_9};
				}
			}
		`}

	${(props) =>
		props.complete &&
		css`
			${VerificationProgressBar} {
				background: ${Color.GREEN_3};
			}

			${VerificationProgressText} {
				span {
					position: relative;
					background: ${Color.GREEN_3};

					&:after {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						border-radius: 50%;
						border: 2px solid ${Color.GREEN_3};
					}
				}
			}
		`}
`;

export const KycNotification = styled.div`
	display: flex;
	color: ${Color.GRAY_8};
	text-align: center;
	font-family: Secondary;
	font-size: 16px;
	line-height: 24px;
	max-width: 70%;
	margin-bottom: 20px !important;
	margin: 0 auto;

	${media.maxDesktop`
		max-width: 100%;
	`}
`;

export const BusinessRequirementsList = styled.ul`
	color: #2d2d32;
	font-family: Secondary;
	font-size: 14px;
	font-weight: 500;
	justify-content: center;
	line-height: 27px;
	list-style-type: none;
	text-align: left;
`;

export const DropzoneSection = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 80px;
	padding: 0 ${Gutter.LARGE};

	${media.maxDesktop`
		flex-direction: column;
	`}
	${media.maxSize481`
		flex-direction: column;
		padding: 0 ${Gutter.SMALL};
	`}
`;

export const IdentityRequirementsList = styled.div`
	color: #2d2d32;
	display: flex;
	font-family: Secondary;
	font-size: 14px;
	font-weight: 300;
	justify-content: center;
	line-height: 21px;
	text-align: left;
`;

export const RequirementsDescription = styled.div`
	text-align: left;
	width: 50%;

	@media (max-width: 1024px) {
		width: 100%;
	}
`;

export const IdentitySection = styled.div`
	border-bottom: 1px solid #d6d6d6;

	h2 {
		text-align: center;
	}

	h3 {
		text-align: center;
	}
`;

export const IdentityDropzone = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	margin-bottom: 20px;

	@media (max-width: 1024px) {
		flex-direction: column;
	}
`;

export const DropzonesWrap = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-around;

	@media (max-width: 1160px) {
		flex-direction: column;
	}
`;

export const VerifyIdentityChooseIdRadioWrapper = styled(RadioButtonGroup)`
	display: flex;
	justify-content: flex-start;
	margin-bottom: 120px;

	${media.maxMobile`
		flex-direction: column;
		margin-bottom: 60px;
	`}
`;

export const VerifyIdentityFieldTitle = styled.label`
	font-size: 15px;
	font-weight: 400;
	font-family: 'PrimaryMedium';
	display: block;
	line-height: 18px;
	margin-bottom: ${Gutter.MEDIUM};
`;

export const DropzoneNotificationWrapper = styled.div`
	${media.fromDesktop`
		width: calc(50% - 40px);
	`}
`;

export const DropZoneWrapper = styled.div`
	${media.fromDesktop`
		width: calc(50% - 40px);
	`}
`;

export const DropZoneLabel = styled.label`
	width: 100%;
	position: absolute;
`;

export const DropzoneFieldTitle = styled.label`
	text-align: center;
	margin-left: ${Gutter.LARGE};
	font-size: 15px;
	font-weight: 400;
	font-family: 'PrimaryMedium';
	display: block;
	line-height: 18px;

	${media.fromDesktop`
		width: calc(50% - 80px);
	`}
`;

export const DoubleSidedCheckboxWrapper = styled(Field)`
	margin-bottom: 25px;
	padding: 0 ${Gutter.LARGE};
`;

const chainLength = '50px';

export const TitleLink = styled.a<TitleLinkProps>`
	display: inline-flex;
	align-items: center;
	position: relative;
	padding: 8px 20px 8px 50px;
	background: ${Color.WHITE};
	border-radius: 20px;
	border: 1px solid ${Color.GRAY_NAVBORDER};
	color: ${Color.OPTIONAL_TEXT};
	text-decoration: none;
	font-family: PrimaryMedium;
	font-size: 14px;

	&:before {
		content: '${({ prefix }) => prefix && prefix}';
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		padding-top: 1px;
		top: 50%;
		right: calc(100% - 40px);
		width: 20px;
		height: 20px;
		background: ${Color.ORANGE};
		border-radius: 50%;
		color: ${Color.WHITE};
		font-size: 14px;
		transform: translateY(-50%);
	}

	&:nth-child(n + 2) {
		margin-left: ${chainLength};

		&:after {
			content: '';
			position: absolute;
			width: ${chainLength};
			height: 1px;
			top: 50%;
			right: calc(100% + 1px);
			background: ${Color.GRAY_NAVBORDER};
		}
	}
`;

export const TitleLinkWrapper = styled.div`
	display: flex;
`;

export const TitleWithLinkWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	${H1} {
		display: flex;
		align-items: center;
		margin: 10px ${Gutter.LARGE} 10px 0;
	}
`;

export const VerifyOnceIframe = styled.iframe`
	width: 100%;
	height: 770px;
	border: 1px solid ${Color.GRAY_5};
	border-radius: 0px;
`;

export const IdentityVerifyStatusBlock = styled.div`
	width: 100%;
	height: auto;
	border: 1px solid ${Color.GRAY_5};
	border-radius: 0px;
	background-color: ${Color.WHITE};
	padding: 20px 50px;
	padding-bottom: 50px;
	border-radius: 8px;

	h2 {
		margin-bottom: 0px;
	}

	${media.maxTablet`
		padding: 25px;

		h2 {
			text-align: center;
			width: 100%;
		}
	`}
`;

export const StatusMessageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 100px;

	${media.maxTablet`
		padding: 0;
	`}

	button {
		align-self: center;

		${media.maxTable`
			font-size: 12px;
		`}
	}
	p {
		text-align: center;
	}
`;

export const StatusTitle = styled(H2)<{ color: string }>`
	${(props) =>
		props.color &&
		css`
			color: ${props.color};
		`}
`;

export const StatusMessage = styled.div`
	${SubtextDescriptionTextMixin}
	margin-bottom: 41px;
	color: ${Color.BLUE_9};
	text-align: center;

	svg {
		max-width: 48px;
		margin: 0 auto;
		margin-top: 40px;
		margin-bottom: 5px;
	}

	p {
		font-size: 16px;
		line-height: 24px;
		color: ${Color.BLUE_8};
	}

	.lighter {
		color: ${Color.GRAY_8};
	}

	${media.maxTablet`
		p {
			text-align: justify;
		}

		.lighter {
			text-align: center;
			font-size: 14px;
			line-height: 18px;
		}
	`}
`;

export const ProcessNotification = styled.div`
	display: flex;
	margin-bottom: 20px;

	& > :first-child {
		margin-right: 30px;
	}
`;

export const IconWrapper = styled.div<{ color: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ color }) => color};

	svg {
		align-content: center;
		margin-right: 10px;
		height: 20px;
		width: 20px;
		fill: ${({ color }) => color};
	}
`;

export const BusinessVerificationFormDescription = styled.div`
	${SubtextDescriptionTextMixin}
	max-width: 95%;
	margin-bottom: 30px;
	margin-top: 8px;
	color: ${Color.GRAY};
	text-align: center;
	a,
	a:visited {
		color: ${Color.ORANGE_0};
	}
`;

export const BusinessVerificationViewDescription = styled.p<{ left?: boolean }>`
	${SubtextDescriptionTextMixin}
	max-width: 630px;
	margin-bottom: 60px;
	margin-top: 0;
	color: ${Color.GRAY};
	text-align: center;
	a {
		color: ${Color.ORANGE};
	}

	${(props) =>
		props.left &&
		css`
			width: 100%;
		`};
`;
