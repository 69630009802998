import React, { useState } from 'react';
import ReactSVG from 'react-svg';
import styled, { Keyframes, keyframes } from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { handleCopyToClipboard } from '../../services/handle-copy-to-clipboard';

interface PopupProps {
	animation: Keyframes;
}

const Wrapper = styled.div`
	position: relative;
`;

const fadeAnimation = keyframes`
   0% { opacity: 0; }
   15% { opacity: 1; }
   85% { opacity: 1; }
   100% { opacity: 0; }
`;

const PopUp = styled.div<PopupProps>`
	position: absolute;
	z-index: 2;
	height: 32px;
	top: -35px;
	right: -20px;
	white-space: nowrap;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${Color.GRAY_5};
	border-radius: 4px;
	background-color: ${Color.GRAY_2};
	animation: ${({ animation }) => animation};
	animation-duration: 2s;
	animation-iteration-count: 1;
	pointer-events: none;
`;

const PopUpIcon = styled.span`
	margin-left: 9px;
	margin-right: 5px;

	div,
	div div {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 !important;
	}
`;

const PopupMessage = styled.p`
	margin-right: 10px;
	color: ${Color.BLUE_10};
`;

export interface CircleCopyProps {
	value?: string;
}

export const CircleCopy = (props: CircleCopyProps) => {
	const [copyClicked, setCopyClicked] = useState<boolean>(false);
	const { value } = props;

	const handleCopyClicked = () => {
		if (!value) {
			return;
		}

		setCopyClicked(true);
		handleCopyToClipboard(value);
	};

	return (
		<Wrapper>
			<img onClick={handleCopyClicked} src="/files/svg/icons/Copy.svg" alt="Copy" />
			{copyClicked && (
				<PopUp animation={fadeAnimation} onAnimationEnd={() => setCopyClicked(false)}>
					<PopUpIcon>
						<ReactSVG src="/files/svg/icons/CopyBoxes.svg" />
					</PopUpIcon>
					<PopupMessage>Copied to clipboard</PopupMessage>
				</PopUp>
			)}
		</Wrapper>
	);
};
