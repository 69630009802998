import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import styled from 'styled-components/macro';
import { RoutesUrls } from '../../constants';
import { Color } from '../../gfx/constants';
import { Avatar } from '../../gfx/globals';
import { media } from '../../services/media';
import { useStoreState } from '../../services/store';
import { RouteProps } from '../../typings';
import Logo from '../logo/Logo';

const PrivateHeaderWrap = styled.div`
	position: sticky;
	background: ${Color.WHITE};
	top: 0;
	width: 100%;
	height: 80px;
	z-index: 100;

	${media.fromDesktop`
		border-bottom: 1px solid ${Color.GRAY_5};
	`}

	${media.maxDesktop`
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		background-color: transparent;
		width: 100%;
		
		p {
			color: ${Color.WHITE};
		}
	`}
`;

const PrivateHeaderMenuWrap = styled.div`
	display: flex;
	float: right;
`;

const PrivateHeaderUserMenu = styled.div`
	display: flex !important;
	padding: 24px 70px 24px 0;
	position: relative;
	float: right;
	cursor: pointer;
	margin-left: auto;

	p {
		font-size: 14px;
		margin: 0;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 400px;
		color: ${Color.GRAY_15};
		font-family: 'PrimaryMedium';
		margin-bottom: 2px;
		&:last-of-type {
			font-size: 12px;
			line-height: 16px;
			color: ${Color.GRAY_8};
			font-family: 'Primary';
		}
	}

	svg {
		right: 44px;
		position: absolute;
		top: 37px;
		transform: rotate(-90deg);
	}

	svg path {
		fill: ${Color.GRAY_6};
	}

	${media.maxDesktop`
		display: none !important;
	`}
`;

const RotateArrow = styled.div`
	svg { 
		transform: rotate(90deg);
	}
`;

const MobileNavLogo = styled.div`
	display: none;

	${media.maxDesktop`
		position: absolute;
		top: 20px;
		left: 20px;
	`}
`;

const PrivateHeaderUserDropDown = styled.ul`
	background: ${Color.WHITE};
	border: 1px solid ${Color.GRAY_5};
    position: absolute;
    padding: 0px;
    border-radius: 4px;
    top: 67%;
    right: 40px;
    min-width: 158px;

	li {
		padding: 15px 7px 15px 46px;
		list-style: none;
		font-size: 16px;
		font-family: Secondary;
		color: ${Color.GRAY_9};
		cursor: pointer;
		position: relative;
	}

	li:hover {
		background: ${Color.GRAY_0};
	}

	li svg {
		width: auto;
		heigh: 24px;
		right: unset;
		top: 0px;
		left: 10px;
		transform: none;
		bottom: 0px;
		margin: auto;
	}

	li svg path {
		fill: ${Color.GREEN_3};
	}

	li a {
		text-decoration: none;
		color: ${Color.GRAY_9};
	}

	${media.maxDesktop`
		top: 40px;
		right: 40px;
		min-width: 150px;
	`}
`;

function PrivateHeader(props: RouteProps) {
	const [isDropDownVisible, setIsDropDownVisible] = useState(false);
	const [wrapperRef] = useState(React.createRef<HTMLDivElement>());

	const { activeViewer } = useStoreState((state) => ({
		activeViewer: state.viewer.activeViewer,
	}));

	const handleClickOutside: EventListenerOrEventListenerObject = (event) => {
		const currentElement = event.target;

		if (
			wrapperRef &&
			wrapperRef.current &&
			currentElement instanceof Node &&
			!wrapperRef.current.contains(currentElement)
		) {
			setIsDropDownVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	});

	const toggleDropDownVisibility = () => {
		setIsDropDownVisible(!isDropDownVisible);
	};

	const handleLogout = () => {
		props.history.push(RoutesUrls.LOGOUT);
	};

	return (
		<PrivateHeaderWrap> 
			<MobileNavLogo>
				<Logo />
			</MobileNavLogo>
			<PrivateHeaderMenuWrap>
				<PrivateHeaderUserMenu onClick={toggleDropDownVisibility} ref={wrapperRef} id='HeaderProfile'>
					{activeViewer && (
						<>
							<Avatar>
								{activeViewer.firstName.charAt(0).toUpperCase()}
								{activeViewer.lastName.charAt(0).toUpperCase()}
							</Avatar>
							<div>
								<p>
									{activeViewer.firstName} {activeViewer.lastName}
								</p>
								<p>{activeViewer.companyName}</p>
							</div>
							{isDropDownVisible ? 
								<RotateArrow>
									<ReactSVG src="/files/svg/private/Arrow.svg"/>
								</RotateArrow> :
								<ReactSVG src="/files/svg/private/Arrow.svg"/>
							}
						</>
					)}
					{isDropDownVisible && (
						<PrivateHeaderUserDropDown>
							<li>
								<a href="https://help.dagpay.io/en/" target="_blank" rel="noopener noreferrer"><ReactSVG src="/files/svg/private/SupportIcon.svg"/>Support</a>
							</li>
							<li onClick={handleLogout} id="logout"><ReactSVG src="/files/svg/private/LogoutIcon.svg"/>Log out</li>
						</PrivateHeaderUserDropDown>
					)}
				</PrivateHeaderUserMenu>
			</PrivateHeaderMenuWrap>
		</PrivateHeaderWrap>
	);
}

export default withRouter(PrivateHeader);
