import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import PublicBackground from '../gfx/images/PublicBackground.jpg';
import { media } from '../services/media';

import { Color } from './constants';

interface CenteredFlexProps {
	isJustified?: boolean;
	marginTop?: number;
	flex?: number;
}

interface AvatarProps {
	color?: Color;
	height?: number;
	width?: number;
}

interface ColumnedFlexProps {
	isCentered?: boolean;
	flex?: number;
	marginRight?: number;
	marginLeft?: number;
	fullWidth?: boolean;
	fullHeight?: boolean;
	isAmountToPayField?: boolean;
}

export const HorizontalLine = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${Color.GRAY_5};
	margin-bottom: 22px;
`;

export const NoOverflowDiv = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
`;

export const SpaceBetweenFlex = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const ColumnedLeftCenterAlignedFlex = styled.div<CenteredFlexProps>`
	display: flex;
	flex: ${({ flex }) => flex && flex};
	justify-content: center;
	align-items: start;
	flex-direction: column;
`;

export const JustifiedRightFlex = styled.div<CenteredFlexProps>`
	display: flex;
	width: 100%;
	align-items: center;
	flex: ${({ flex }) => flex && flex};
	justify-content: flex-end;
`;

export const JustifiedLeftFlex = styled.div<CenteredFlexProps>`
	display: flex;
	width: 100%;
	align-items: center;
	flex: ${({ flex }) => flex && flex};
	justify-content: flex-start;
`;

export const CenteredFlex = styled.div<CenteredFlexProps>`
	display: flex;
	align-items: center;
	flex: ${({ flex }) => flex && flex};
	justify-content: ${({ isJustified }) => isJustified && 'center'};
	margin-top: ${({ marginTop }) => marginTop && `${marginTop}px`};
	margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
`;

export const ColumnedFlex = styled.div<ColumnedFlexProps>`
	display: flex;
	flex: ${({ flex }) => flex && flex};
	flex-direction: column;
	justify-content: ${({ isCentered }) => isCentered && 'center'};
	align-items: ${({ isCentered }) => isCentered && 'center'};
	width: ${({ fullWidth, isAmountToPayField }) => {
		if (fullWidth) {
			return '100%';
		}
		if (isAmountToPayField) {
			return 'calc(100% - 120px - 30px)';
		}

		return 'auto';
	}};
	height: ${({ fullHeight }) => fullHeight && '100%'};
	margin-right: ${({ marginRight, isAmountToPayField }) => {
		if (marginRight) {
			return `${marginRight}px`;
		}
		if (isAmountToPayField) {
			return 0;
		}

		return 0;
	}};
	text-align: ${({ isCentered }) => isCentered && 'center'};
	outline: none;

	@media (max-width: 1024px) {
		margin-right: 0px;
		text-align: left;
	}
`;

export const Strong = styled.span`
	font-family: PrimaryMedium;
`;

export const Light = styled.span`
	font-family: Primary;
	font-size: 12px;
	color: ${Color.GRAY_8};
	padding-top: 5px;
`;

export const PublicBackgroundImage = styled.div`
	background-image: url(${PublicBackground});
	background-size: cover;
	width: 100%;
	background-position: center center;
	flex: 1.5;

	${media.maxDesktop`
		display: none;
	`}
`;

export const Avatar = styled.div<AvatarProps>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-left: ${(props) => (props.marginLeft ? `${props.marginLeft}px` : "0px")};
	margin-right: ${(props) => (props.marginRight ? `${props.marginRight}px` : "10px")};
	width: ${(props) => (props.width ? `${props.width}px` : "32px")};
	height: ${(props) => (props.width ? `${props.height}px` : "32px")};
	border-radius: 50%;
	background: ${(props) => (props.color ? props.color : Color.BLUE_4)};
	color: ${Color.WHITE};
	font-size: 14px;
	user-select: none;

	${media.maxDesktop`
		background-color: ${Color.GREEN_3};
		line-height: 32px;
		text-align: center;
	`}
`;

export const H1 = styled.h1<{ center?: boolean }>`
	max-width: 100%;
	margin: 0 auto;
	margin-left: 0;
	margin-bottom: 8px;
	margin-top: 5px;
	font-size: 24px;
	line-height: 28px;
	color: ${Color.GRAY_14};

	${(props) =>
		props.center &&
		css`
			text-align: center;
			margin-left: auto;
		`}
`;

export const H2 = styled.h2<{ alignLeft?: boolean }>`
	font-size: 18px;
	font-weight: 400;
	line-height: 21px;
	color: ${Color.BLUE_9};

	${(props) =>
		props.alignLeft &&
		css`
			margin-right: auto;
		`}
`;

export const VerificationTitle = styled.h2<{ alignLeft?: boolean }>`
	font-size: 18px;
	font-weight: 400;
	line-height: 21px;
	margin: 0px;
	color: ${Color.BLUE_9};

	${(props) =>
		props.alignLeft &&
		css`
			margin-right: auto;
		`}
`;

export const H3 = styled.h2<{ alignLeft?: boolean }>`
	font-size: 16px;
	font-weight: 400;
	font-family: PrimaryMedium;
	line-height: 22px;
	color: ${Color.GRAY_12};
	margin-bottom: 22px;

	${(props) =>
		props.alignLeft &&
		css`
			margin-right: auto;
		`}
`;

export const LinkButton = styled.button`
	background: none !important;
	border: none;
	padding: 0 !important;
	cursor: pointer;
`;

export const GoBackLink = styled(Link)`
	height: 21px;
	position: relative;
	padding: 0;
	margin: 0 24px;
	text-decoration: underline;
	display: flex;
	white-space: nowrap;
	color: ${Color.BLUE_10};
	font-size: 16px;
	line-height: 21px;
	font-family: primaryMedium;
	border: none;
	background-color: transparent;
	cursor: pointer;
`;

export const SubtextDescriptionTextMixin = css`
	font-family: secondary;
	font-size: 14px;
	line-height: 21px;
	font-weight: 400;
`;

export const SubmenuTextMixin = css`
	font-family: PrimaryMedium;
	font-size: 16px;
	line-height: 24px;
`;

export const PageTitleTextMixin = css`
	font-family: PrimaryMedium;
	font-size: 24px;
	line-height: 32px;
`;

export const SectionTitleTextMixin = css`
	font-family: PrimaryMedium;
	font-size: 18px;
	line-height: 27px;
`;

export const TableHeaderTextMixin = css`
	font-family: PrimaryMedium;
	font-size: 12px;
	line-height: 18px;
	text-transform: uppercase;
`;
export const TableTextMixin = css`
	font-family: Primary;
	font-size: 14px;
	line-height: 21px;
`;

export const TableSmallTextMixin = css`
	font-family: Primary;
	font-size: 12px;
	line-height: 18px;
`;

export const secondaryTextMixin = css`
	font-family: Secondary;
	font-size: 16px;
	line-height: 24px;
`;

export const NotificationRowWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 28px;

	${media.maxNewInvoiceTable`
		flex-direction: column;
		align-items: flex-start;
	`}
`;

export const NotificationText = styled.div`
	text-align: center;
	font-family: Secondary;
	font-size: 14px;
	line-height: 36px;
	color: ${Color.GRAY_10};

	a {
		text-decoration: none;
		color: ${Color.BLUE_1};
	}

	${media.maxSize800`
		text-align: start;
		line-height: 21px;
	`}
`;
export const TitleWrapper = styled.div`
	width: 100%;
	height: 100%;

	${H1} {
		margin-bottom: 0 !important;
	}

	${media.maxDesktop`
		${H1} {
			margin-bottom: 10px !important;
		}
	`}
`;
export const FadeInWrapper = styled.div`
	margin-top: -20px;
`;