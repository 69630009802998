import dayjs from 'dayjs';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

import Loader from '../../../../../components/loader/Loader';
import { Currency, DashboardFilters } from '../../../../../constants';
import { H1 } from '../../../../../gfx/globals';
import { formatGraphData, substractUnits } from '../../../../../services/format-graph-data';
import { useStoreActions, useStoreState } from '../../../../../services/store';

import AreaChartBlock from './area-chart-block/AreaChartBlock';
import DataUnits from './data-units/DataUnits';
import Statistics from './StatisticsBlockStyle';

interface Props {
	baseCurrency: Currency;
}

const StatisticsBlock = (props: Props) => {
	const [activeFilter, setActiveFilter] = useState<DashboardFilters>(DashboardFilters.MONTH);
	const [startDate, setStartDate] = useState<Date>(dayjs().subtract(1, 'month').toDate());
	const [endDate, setEndDate] = useState<Date>(dayjs().toDate());

	const { viewerStatistics } = useStoreState((state) => ({
		viewerStatistics: state.viewer.viewerStatistics,
	}));

	const { getViewerStatistics } = useStoreActions((actions) => ({
		getViewerStatistics: actions.viewer.getViewerStatistics,
	}));

	const fetchStatistics = useCallback(async () => {
		await getViewerStatistics({
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
		});
	}, [startDate, endDate, getViewerStatistics]);

	useEffect(() => {
		fetchStatistics();
	}, [startDate, endDate, fetchStatistics]);

	const formattedData = formatGraphData({
		dataIn: viewerStatistics && viewerStatistics.graphData,
		filter: activeFilter,
	});

	const getFilterRange = (range: DashboardFilters) => {
		const subtractOptions = {
			YEAR: { amount: 1, unit: substractUnits.year },
			QUARTER: { amount: 4, unit: substractUnits.month },
			MONTH: { amount: 1, unit: substractUnits.month },
			WEEK: { amount: 1, unit: substractUnits.week },
			DAY: { amount: 1, unit: substractUnits.day },
			ALL: { amount: 5, unit: substractUnits.year },
		};
		return {
			startDate: moment().subtract(subtractOptions[range].amount, subtractOptions[range].unit).toDate(),
			endDate: dayjs().toDate(),
		};
	};

	return (
		<>
			<Statistics.Header>
				<H1>Dashboard</H1>
				<Statistics.FilterList>
					{Object.values(DashboardFilters).map((filter: DashboardFilters, index) => (
						<Statistics.Filter
							isActive={filter === activeFilter}
							key={`filter-${index}`}
							onClick={() => {
								setStartDate(getFilterRange(filter).startDate);
								setEndDate(getFilterRange(filter).endDate);
								setActiveFilter(filter);
							}}
						>
							{filter.toLowerCase()}
						</Statistics.Filter>
					))}
				</Statistics.FilterList>
			</Statistics.Header>
			<Statistics>
				{viewerStatistics ? (
					<>
						<DataUnits statistics={viewerStatistics} baseCurrency={props.baseCurrency} />
						<Statistics.ChartWrapper>{<AreaChartBlock data={formattedData} />}</Statistics.ChartWrapper>
					</>
				) : (
					<Loader />
				)}
			</Statistics>
		</>
	);
};

export default StatisticsBlock;
