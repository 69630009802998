import axios from 'restyped-axios';
import { Currency } from '../constants';
import { ApiArrayPayload, ApiGetRequest, ApiResponse } from '../typings';

import { Invoice } from './invoiceApi';

export const api = axios.create<PosInvoiceApi>();

export interface PosInvoiceApi {
	'/pos/invoice': {
		POST: {
			query: CreatePosInvoiceRequest;
			response: ApiResponse<Invoice>;
		};
	};

	'/pos/generate-pairing-code': {
		POST: {
			response: ApiResponse<GeneratePosPairingCodeResponse>;
		};
	};

	'/pos/revoke': {
		POST: {
			query: RevokePosPairingRequest;
			response: ApiResponse<GeneratePosPairingCodeResponse>;
		};
	};

	'/pos/pairings': {
		GET: {
			query: ApiGetRequest;
			response: ApiResponse<ApiArrayPayload<PosPairing>>;
		};
	};
}

export interface PosPairing {
	id: string;
	userId: string;
	deviceName: string;
	createdDate: string;
	pairingUserRole: string;
	userEmail: string;
}

export interface CreatePosInvoiceRequest {
	walletId: string;
	paymentId: string;
	currencyAmount: number;
	currency: Currency;
	atmOnly: boolean;
}

export interface RevokePosPairingRequest {
	pairingId: string;
}

export interface GeneratePosPairingCodeResponse {
	pairingCode: string;
	pairingCodeExpiryDate: string;
}

export const createPosInvoiceApi = async (params: CreatePosInvoiceRequest) => {
	const response = await api.post('/pos/invoice', {
		...params,
	});

	return response.data;
};

export const generatePosPairingCodeApi = async () => {
	const response = await api.post('/pos/generate-pairing-code');

	return response.data;
};

export const revokePosPairingCodeApi = async (params: RevokePosPairingRequest) => {
	const response = await api.post('/pos/revoke', { ...params });

	return response.data;
};

export const getPosPairingsApi = async (params: ApiGetRequest) => {
	const response = await api.get('/pos/pairings', {
		params: { ...params },
	});

	return response.data;
};
