import styled, { css } from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { media } from '../../services/media';
import { TimelineNodeStatus } from './TimeLineProgressBar';

interface NodePointProps {
	status: TimelineNodeStatus;
	width: number;
}

export const TimeLineLabelHeader = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	div:first-child {
		position: absolute;
		left: -10px;
		transform: translate(-50%, -50%);
	}
	div:last-child {
		text-align: right;
	}
`;

export const TimeLineLabel = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	padding-top: calc(25px + 24px); // 24px is icon height
	padding-left: 34px;
	/* outline: 1px solid; */

	${media.maxSize800`
		padding-top: 0;
		margin-left: 50px;
		width: 100%;
		padding-bottom: calc(25px + 24px); // 24px is icon height
		flex-direction: row;
		justify-content: space-between;
	`}
	${media.maxSize481`
		padding-top: 0;
		margin-left: 50px;
		width: 100%;
		flex-direction: column;
		justify-content: space-between;
	`}
`;

export const TimeAddonNode = styled.div<{ status: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 19px;
	font-size: 12px;
	line-height: 19px;
	span {
		font-style: primaryMedium;
		color: ${(props) => (props.status === TimelineNodeStatus.ACTIVE ? Color.GRAY_10 : Color.GRAY_7)};
	}
	p {
		margin: 0 0 auto 0;
		display: block;
	}
	${media.maxSize800`
		bottom: unset;
		right: 0;
		text-align: right;
		align-items: flex-end;
		margin-top: 0px;
	`}
`;

export const NodePoint = styled.div<NodePointProps>`
	list-style-type: none;
	color: ${(props) => (props.status === TimelineNodeStatus.INACTIVE ? Color.GRAY_7 : Color.GRAY_12)};
	font-size: 16px;
	width: ${(props) => props.width && `${props.width}%`};
	position: relative;
	//circle marker
	::before {
		content: '';
		${(props) =>
			props.status === TimelineNodeStatus.ACTIVE &&
			css`
				content: url('/files/svg/escrows/done.svg');
			`};
		${(props) =>
			props.status === TimelineNodeStatus.FAIL &&
			css`
				content: url('/files/svg/escrows/fail.svg');
			`};
		${(props) =>
			props.status === TimelineNodeStatus.NEXT &&
			css`
				border: 6px solid ${Color.BLUE_3};
			`};

		color: ${Color.WHITE};
		text-align: center;
		width: 24px;
		height: 24px;
		display: block;
		font-size: 12px;
		background: ${(props) =>
			props.status === TimelineNodeStatus.ACTIVE
				? Color.BLUE_3
				: props.status === TimelineNodeStatus.NEXT
				? Color.WHITE
				: Color.GRAY_5};
		border-radius: 50%;
		margin: auto;
		z-index: 2;
		position: absolute;
	}
	::after {
		content: '';
		width: calc(100% - 24px - 2px);
		height: 1px;
		position: absolute;
		left: calc(24px + 2px);
		top: 12px;
		background: ${(props) => (props.status === TimelineNodeStatus.ACTIVE ? Color.BLUE_2 : Color.GRAY_5)};
		z-index: 1;
	}

	&&:last-child {
		margin-right: 0;

		${TimeLineLabel} {
			justify-content: flex-end;
			${TimeLineLabelHeader} {
				justify-content: flex-end;
				margin-left: auto;
			}
			${TimeAddonNode} {
				span {
					margin-left: auto;
				}
				p {
					margin-left: auto;
				}
			}
		}
	}

	& + & {
		margin-left: 2px;
	}
	${media.maxSize800`
		width: 100%;
		display:flex;
		heighT: 72px;
		::after {
			content: '';
			height: calc(100% - 24px - 4px);
			width: 1px;
			position: absolute;
			left: 11px;
			top: calc(24px + 2px);
		}

		&&:last-child {
			margin-left: unset;
			height: height: calc(25px + 24px);
			justify-content: space-between;
			${TimeLineLabel} {

				justify-content: space-between;
				${TimeLineLabelHeader}{
					justify-content: flex-start;
					margin-left: 0;
				}
			}
			::After {
				display:none;
			}
		}
		& + & {
		margin-left: unset;
	}
	`}
`;

export const TimeLine = styled.ul`
	display: flex;
	justify-content: space-evenly;
	/* div:first-child:after {
		content: none;
	} */

	& > div:last-child {
		margin-left: 0;
	}

	div:last-child:before {
		right: 0;
		left: auto;
	}
	div:last-child:after {
		left: 0;
	}
	padding: 0;
	margin: 0;
	z-index: 0;

	${media.maxSize800`
		flex-direction: column;
		align-items: start;

		div:last-child:before {
			right: unset;
			left: unset;
		}
		div:last-child:after {
			left: unset;
		}
	`}
`;

export const StyledPanel = styled.div`
	width: 100%;
	padding-top: 30px;
	max-height: 100%;
	${media.maxSize800`
		padding-bottom: 0;
	`}
`;
