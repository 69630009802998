import * as React from 'react';
import { StyledComponentProps } from 'styled-components';
import styled, { css } from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

import { GridTableType } from './GridTable';

interface GridTableItemsSize {
	rows: number;
	cssRowSizes: string;
	columns: number;
	cssColumnSizes: string;
}

interface InfoCellProps {
	row: number;
	row2?: number;
	column?: number;
	column2?: number;
}

type GridTableItemsDimensionsType = {
	[name in keyof typeof GridTableType]: GridTableItemsSize;
};

const border = '1px';

/* 
	Information area (buttons row doesn't count) row line counts as row, same with column line.
*/
export const GridTableItemsDimensions: GridTableItemsDimensionsType = {
	TRANSACTION_HISTORY: {
		rows: 5,
		columns: 4,
		cssRowSizes: `auto ${border} auto ${border} auto`,
		cssColumnSizes: `36.4% 38% auto`,
	},
	ONE_TIME_INVOICE: {
		rows: 5,
		columns: 3,
		cssRowSizes: `auto ${border} auto auto auto`,
		cssColumnSizes: `36.4% 38% auto`,
	},
	RECURRING_INVOICE: {
		rows: 7,
		columns: 5,
		cssRowSizes: `auto ${border} auto ${border} auto ${border} auto`,
		cssColumnSizes: `36.4% 13.5% 14.5% 9.5% auto`,
	},
	ESCROW_DEAL: {
		rows: 7,
		columns: 5,
		cssRowSizes: `auto ${border} auto ${border} auto ${border} auto`,
		cssColumnSizes: `32% 27% ${border} auto 20%`,
	},
};

interface GridTableProps {}

interface InfoAreaWrapperProps {
	type: GridTableType;
	hasButtonRow: boolean;
}

const GridTable = styled.div<GridTableProps>`
	margin: -5px 0 5px 0;
	background-color: ${Color.WHITE};
	border: 1px solid ${Color.GREY_BORDER};
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
`;

const StyledGridTable = (props: StyledComponentProps<'div', GridTableProps, GridTableProps, never>) => (
	<GridTable {...props}>{props.children}</GridTable>
);

StyledGridTable.TableRow = styled.tr`

`;

StyledGridTable.TableData = styled.td`
	padding: 0 !important;

	${media.maxSize800`
		.opened {
			border-top: none;
			border-radius: 0 0 4px 4px;
		}
	`}
`;

StyledGridTable.DataWrapper = styled.div`
	${media.maxSize800`
		padding: 11px 14px;
	`}
`;

StyledGridTable.InfoAreaWrapper = styled.div<InfoAreaWrapperProps>`
	display: grid;
	border-radius: 4px;

	${(props) =>
		props.type === GridTableType.TRANSACTION_HISTORY &&
		css`
			grid-template-columns: ${GridTableItemsDimensions[GridTableType.TRANSACTION_HISTORY].cssColumnSizes};
			grid-template-rows: ${GridTableItemsDimensions[GridTableType.TRANSACTION_HISTORY].cssRowSizes};
		`}

	${(props) =>
		props.type === GridTableType.ONE_TIME_INVOICE &&
		css`
			grid-template-columns: ${GridTableItemsDimensions[GridTableType.ONE_TIME_INVOICE].cssColumnSizes};
			grid-template-rows: ${GridTableItemsDimensions[GridTableType.ONE_TIME_INVOICE].cssRowSizes};
		`}

	${(props) =>
		props.type === GridTableType.RECURRING_INVOICE &&
		css`
			grid-template-columns: ${GridTableItemsDimensions[GridTableType.RECURRING_INVOICE].cssColumnSizes};
			grid-template-rows: ${GridTableItemsDimensions[GridTableType.RECURRING_INVOICE].cssRowSizes};
		`}

	${(props) =>
		props.type === GridTableType.ESCROW_DEAL &&
		css`
			grid-template-columns: ${GridTableItemsDimensions[GridTableType.ESCROW_DEAL].cssColumnSizes};
			grid-template-rows: ${GridTableItemsDimensions[GridTableType.ESCROW_DEAL].cssRowSizes};
		`}

	${media.maxSize800`
		display: flex;
		flex-direction: column;

		& > div:first-child{
			padding-top: 20px;
		}
		
		& > div:last-child{
			padding-bottom: 20px;
		}

		.even ${StyledGridTable.DataWrapper}{
			background: ${Color.GRAY_1};
			border-radius: 4px;
		}
	`}
`;

StyledGridTable.ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 18px 28px;
	background-color: ${Color.GRAY_2};
	border-radius: 0 0 4px 4px;
	border-top: 1px solid ${Color.GRAY_5};

	button {
		margin-left: 10px;
		min-width: 120px;

		${media.maxSize800`
			min-width: 92.5px;
			margin: 10px 0 0 10px;
			padding: 0 16px;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
	`}
	}

	${media.maxSize800`
		padding: 14px 20px;
		flex-wrap: wrap;
	`}
`;

StyledGridTable.InfoCell = styled.div<InfoCellProps>`
	display: flex;
	flex-direction: column;
	padding: 22px 32px;
	height: 100%;
	width: 100%;

	${(props) =>
		props.column &&
		css`
			grid-row: ${props.row} / ${props.row + 1};
			grid-column: ${props.column} / ${props.column + 1};
		`}

	${(props) =>
		props.column2 &&
		css`
			grid-column: ${props.column} / ${props.column2 + 1};
		`}

	${(props) =>
		props.row2 &&
		css`
			grid-row: ${props.row} / ${props.row2 + 1};
		`}

	span {
		color: ${Color.BLUE_10};
		display: block;
		font-size: 15px;
		font-family: PrimaryMedium;
		line-height: 18px;
		padding-bottom: 5px;
	}

	${media.maxSize800`
		padding: 0 20px;
	`}
`;

StyledGridTable.PrimaryText = styled.div`
	color: ${Color.BLUE_9};
	font-size: 15px;
	font-family: PrimaryMedium;
	line-height: 14px;
	padding-bottom: 5px;
	word-break: normal;

	${media.maxSize800`
		font-size: 14px;
		line-height: 16px;
	`}
`;

StyledGridTable.SecondaryText = styled.div<{preWrap: boolean;}>`
	color: ${Color.GRAY_10};
	font-family: Secondary;
	font-size: 14px;
	line-height: 22px;
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
	height: 100%;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;

	${media.maxSize800`
		font-size: 14px;
		line-height: 21px;
	`}

	${(preWrap) =>
		preWrap &&
		css`
			white-space: pre-wrap;
	`}
`;

StyledGridTable.SeparatorLine2d = styled.div<{
	startingRow: number;
	endingRow: number;
	startingColumn: number;
	endingColumn: number;
}>`
	background-color: ${Color.OPTIONAL_TEXT};
	opacity: 0.12;

	${(props) => css`
		grid-row: ${props.startingRow} / ${props.endingRow + 1};
		grid-column: ${props.startingColumn} / ${props.endingColumn + 1};
	`}

	${media.maxSize800`
		display: none;
	`}
`;

StyledGridTable.TotalItem = styled.div`
	color: ${Color.BLUE_9};
	font-family: Secondary;
	font-size: 14px;
	line-height: 16px;
	display: flex;

	${StyledGridTable.PrimaryText} {
		color: ${Color.GREEN_3};
		margin-left: 5px;
		font-family: Secondary;
		font-size: 13px;
		line-height: 19px;
	}
`;

StyledGridTable.hiddenCell = styled.div`
	display: none;
`;

export default StyledGridTable;
