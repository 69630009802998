import { FastField as FieldFormik } from 'formik';
import * as React from 'react';
import styled from 'styled-components/macro';

import { FieldTypes } from '../../constants';
import { Color } from '../../gfx/constants';

interface Props {
	name?: string;
	label: string;
	value: string;
	disabled?: boolean;
	tabIndex?: number;
	notification?: string;
	selected?: boolean;
	className?: string;
	isLeftAligned?: boolean;
	isActive?: boolean;
	icon?: React.ReactNode;
	width?: number;
	isCentered?: boolean;
	marginBottom?: number;
	verifyIdentityView?: boolean;
	onClick?(e: React.MouseEvent<any>): void;
}

interface RadioIconProps {
	isActive?: boolean;
	marginBottom?: number;
}

interface RadioWrapProps {
	isLeftAligned?: boolean;
}

interface CheckMarkProps {
	isActive?: boolean;
}

interface RadioBlockProps {
	isFlex?: boolean;
	verifyIdentityView?: boolean;
}

interface RadioContentProps {
	width?: number;
}

interface RadioLabelProps {
	isCentered?: boolean;
}

const RadioWrap = styled.div<RadioWrapProps>`
	width: ${({ isLeftAligned }) => !isLeftAligned && '100%'};

	label {
		cursor: pointer;
		user-select: none;
		position: relative;
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}
`;

const RadioLabel = styled.div<RadioLabelProps>`
	display: flex;
	align-items: center;
	justify-content: ${({ isCentered }) => isCentered && 'center'};
	font-family: PrimaryMedium;
`;

const RadioContent = styled.div<RadioContentProps>`
	display: inline-block;
	width: ${({ width }) => width && `${width}px`};
`;

const RadioWithLabel = styled.div`
	position: relative;
	padding-left: 36px;
	line-height: 24px;

	@media (max-width: 1024px) {
		padding-top: 10px;
		padding-bottom: 10px;
	}
`;

const RadioNotification = styled.p`
	margin-top: 0;
`;

const RadioBlock = styled.div<RadioBlockProps>`
	display: ${({ isFlex }) => isFlex && 'flex'};
	align-items: ${({ isFlex }) => isFlex && 'center'};
	width: ${({ isFlex, verifyIdentityView }) => {
		if (verifyIdentityView) {
			return 'auto';
		}
		if (!isFlex) {
			return '100%';
		}

		return 'auto';
	}};
	padding: 5px;
	padding-right: ${({ verifyIdentityView }) => verifyIdentityView && '45px'};
	&:focus-within {
		background-color: rgba(4, 212, 91, 0.1);
		color: ${Color.WHITE};
		border-radius: 5px;
	}
`;

const CheckMark = styled.div<CheckMarkProps>`
	position: absolute;
	top: 50%;
	left: 0;
	height: 24px;
	width: 24px;
	min-width: 24px;
	background-color: transparent;
	border-radius: 50%;
	border: ${({ isActive }) => (isActive ? `8px solid ${Color.GREEN_3}` : ' 2px solid #969aa4')};
	margin-right: 12px;
	transform: translateY(-50%);
`;

const RadioIcon = styled.div<RadioIconProps>`
	margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
	border: 1px solid rgba(220, 221, 224, 0.5);
	border-radius: 4px;
	background-color: transparent;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
	height: 106.32px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	pointer-events: none;

	svg {
		pointer-events: none;

		g,
		path:first-child,
		polygon {
			fill: ${({ isActive }) => !isActive && '#dfdfe2'};
		}
	}
`;

export default function RadioButton(props: Props) {
	const {
		name,
		label,
		value,
		selected,
		onClick,
		icon,
		disabled,
		isActive,
		className,
		isLeftAligned,
		notification,
		width,
		isCentered,
		marginBottom,
		verifyIdentityView,
		...rest
	} = props;

	return (
		<RadioBlock verifyIdentityView>
			<RadioWrap isLeftAligned={isLeftAligned}>
				<label>
					<RadioContent width={width}>
						{icon && (
							<RadioIcon marginBottom={marginBottom} isActive={isActive}>
								{icon}
							</RadioIcon>
						)}
						<RadioWithLabel>
							<FieldFormik
								type={FieldTypes.RADIO}
								name={name}
								title={label}
								value={value}
								disabled={disabled}
								defaultChecked={selected}
								onClick={onClick}
								{...rest}
							/>
							<RadioLabel isCentered={isCentered}>
								{<CheckMark isActive={isActive} />}
								{label}
							</RadioLabel>
						</RadioWithLabel>
					</RadioContent>
				</label>
			</RadioWrap>
			{notification && <RadioNotification>{notification}</RadioNotification>}
		</RadioBlock>
	);
}
