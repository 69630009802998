import styled, { css } from 'styled-components/macro';

import RadioIcon from '../../../../../../components/radio-icon/RadioIcon';
import RadioSelectorGroup from '../../../../../../components/radio-selector-group/RadioSelectorGroup';
import { Color, Gutter } from '../../../../../../gfx/constants';
import { media } from '../../../../../../services/media';

export const OverlayWithBackground = styled.div`
	background: ${Color.GRAY_1};
	border-radius: 4px;
	padding: 20px 16px 5px 16px;
	margin-bottom: 20px;
	margin-top: 35px;

	div {
		font-size: 14px;
		line-height: 22px;
		color: ${Color.BLUE_9};
	}
`;

export const CreateNewEnvironmentView = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	width: 100%;
	max-width: 1200px;
	padding: 90px 105px 65px;

	${media.maxDesktop`
		padding-left: ${Gutter.LARGE};
		padding-right: ${Gutter.LARGE};
	`}

	form {
		width: 100%;
	}
`;

export const DownloadPluginButton = styled.a`
	border-radius: 4px;
	align-items: center;
	margin-top: 4px;
	font-size: 13px;
	font-weight: 500;
	background-color: ${Color.GREEN_3};
	letter-spacing: -0.22px;
	line-height: 15px;
	height: 34px;
	padding: 10px;
	text-decoration: none;
	min-width: 152px;
	margin-left: auto;
	display: flex;
	justify-content: center;
	color: ${Color.WHITE};

	svg {
		margin-right: 5px;
	}

	&:hover {
		background-color: ${Color.GREEN_HOVER};
	}
`;

export const PluginHeader = styled.div`
	width: 100%;
	& > h1 {
		margin-bottom: 15px;
	}
`;

export const PluginDescription = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: ${Gutter.SMALL};

	${media.maxDesktop`
		flex-direction: column;
		align-items: flex-start;
	`}

	p {
		margin: 0 10px 0 0;
		color: #253a67;
		font-size: 15px;

		${media.maxDesktop`
			margin-bottom: 10px;
		`}

		a {
			color: ${Color.BLUE_1};
		}
	}
`;

export const EnvironmentRadioGroup = styled(RadioSelectorGroup)`
	margin-bottom: 120px;
	flex-wrap: wrap;

	.RadioSelector__Label-sc-1zeuc4-0 {
		margin-bottom: 63px;
	}
	div div div div svg {
		width: 24px;
		height: 24px;
	}

	@media only screen and (max-width: 650px) {
		flex-direction: column;
		margin-bottom: ${Gutter.LARGE};
	}
`;

export const EnvironmentRadioSelector = styled.div`
	label {
		width: 30%;

		@media only screen and (max-width: 650px) {
			display: flex;
			width: auto;
		}

		> div,
		> div + div {
			@media only screen and (max-width: 650px) {
				width: 50%;
			}
		}
	}
`;

export const EnvironmentTypeIcon = styled(RadioIcon)<{ isActive?: boolean }>`
	width: 100%;

	${(props) =>
		props.isActive &&
		css`
			background: #fff;
		`}

	@media only screen and (max-width: 650px) {
		margin-bottom: 0;
	}

	@media only screen and (max-width: 440px) {
		width: 100% !important;
		margin-bottom: ${Gutter.SMALL};
	}
`;

export const EnvironmentButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	button {
		margin: 0;
	}
`;

export const FieldNode = styled.span`
	white-space: nowrap;
	padding-left: 20px;
	margin-top: 10px;
	display: inline-block;

	${media.maxSize800`
		padding-left: 0;
		font-size: 0.9rem;
		text-align: center;
	`}
`;
