import * as React from 'react';
import ReactSVG from 'react-svg';

import { RoutesUrls } from '../../constants';
import { H2 } from '../../gfx/globals';
import { ValueOf } from '../../typings';
import { DashboardIconPath } from '../../views/private/merchant/dashboard/DashboardViewTpl';
import Panel from '../panel/Panel';

interface Props {
	icon: ValueOf<typeof DashboardIconPath>;
	title: string;
	text: string | JSX.Element;
	link?: ValueOf<typeof RoutesUrls>;
	tag?: string;
	className?: string;
}

export default ({ icon, title, text, link, tag, className }: Props) =>
	link ? (
		<Panel.LinkWrapper to={link}>
			<Panel infoPanel>
				<Panel.Icon size={40} className={className}>
					<ReactSVG src={icon} />
				</Panel.Icon>

				<Panel.SectionWrapper>
					<H2>{title}</H2>
					<Panel.Text>{text}</Panel.Text>
				</Panel.SectionWrapper>
			</Panel>
		</Panel.LinkWrapper>
	) : (
		<Panel infoPanel>
			<Panel.Icon size={40} className={className}>
				<ReactSVG src={icon} />
			</Panel.Icon>
			<Panel.SectionWrapper>
				<Panel.TitleWrap>
					<H2>{title}</H2>
					{tag && (
						<Panel.TagWrap>
							<Panel.Tag>{tag}</Panel.Tag>
						</Panel.TagWrap>
					)}
				</Panel.TitleWrap>
				<Panel.Text>{text}</Panel.Text>
			</Panel.SectionWrapper>
		</Panel>
	);
