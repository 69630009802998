import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { AtmType } from '../../../../../../api/atmApi';
import { GeocodeResult } from '../../../../../../api/geoApi';
import Button, { ButtonTypes } from '../../../../../../components/button/Button';
import { Geocoder } from '../../../../../../components/geocoder/Geocoder';
import Loader from '../../../../../../components/loader/Loader';
import Notification from '../../../../../../components/notification/Notification';
import { DayInfo, OpeningHours, openingHoursDefault } from '../../../../../../components/opening-hours/OpeningHours';
import PreviousLink from '../../../../../../components/previous-link/PreviousLink';
import FormSeparator from '../../../../../../components/react-hook-form/FormSeparator';
import HookBaseField from '../../../../../../components/react-hook-form/HookBaseField';
import {
	HookFormColumn,
	HookFormSection,
	WhiteHookForm,
} from '../../../../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../../../../components/view/View';
import { RoutesUrls } from '../../../../../../constants';
import { Color } from '../../../../../../gfx/constants';
import { CenteredFlex, H1 } from '../../../../../../gfx/globals';
import { useStoreActions, useStoreState } from '../../../../../../services/store';
import { RouteProps } from '../../../../../../typings';
import { NotificationWrapper } from '../../vatm/detail/VatmDetailStyle';

import { InformationSection } from './AtmDetailStyle';

interface RouteParams {
	atmId?: string;
}

export interface AtmFields {
	serial: string;
	companyName: string;
	address: string;
	description: string;
}

function AtmDetailView(props: RouteProps<RouteParams>) {
	const [errorMessage, setErrorMessage] = useState<JSX.Element | null>(null);
	const [openingHours, setOpeningHours] = useState<DayInfo[]>(openingHoursDefault.map((og) => Object.assign({}, og)));
	const [address, setAddress] = useState<null | string>(null);
	const [addressError, setAddressError] = useState('');
	const [openingHoursError, setOpeningHoursError] = useState('');
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const { atmId: paramsAtmId } = props.match.params;
	const isConfigureView = paramsAtmId !== undefined;

	const { activeViewer, geocodeResult, atm } = useStoreState((state) => ({
		activeViewer: state.viewer.activeViewer,
		geocodeResult: state.geo.geoCodeResult as GeocodeResult[] | null,
		atm: state.atm.atm,
	}));

	const { createAtm, getAtm } = useStoreActions((actions) => ({
		...actions.atm,
	}));

	const defaultValues: AtmFields = {
		serial: '',
		companyName: '',
		address: '',
		description: '',
	};

	const schema = Yup.object().shape({
		serial: Yup.string().required('Serial is required').trim('Serial cannot be left empty or filled with spaces'),
		companyName: Yup.string()
			.required('Business (brand) name is required')
			.trim('Field cannot be left empty or filled with spaces')
			.strict(true)
			.max(30, 'Company name should not be longer than 30 characters'),
		description: Yup.string()
			.trim('Field cannot be left empty or filled with spaces')
			.strict(true)
			.max(100, 'Description should not be longer than 100 characters'),
	});

	const methods = useForm<AtmFields>({
		defaultValues: defaultValues,
		resolver: yupResolver(schema),
	});

	const { setValue, formState } = methods;
	const { serial, companyName, description } = methods.watch();

	const handleAtmFetch = useCallback(async () => {
		const isNewAtm = paramsAtmId !== undefined && atm !== null && paramsAtmId !== atm.id;
		const shouldFetch = paramsAtmId !== undefined && !atm;

		// different atm from global state atm
		if (paramsAtmId && (shouldFetch || isNewAtm)) {
			await getAtm({ atmId: paramsAtmId });
		}
	}, [atm, paramsAtmId, getAtm]);

	// field population on mount in configure view
	useEffect(() => {
		const populateConfigureMode = () => {
			if (!atm || !paramsAtmId) {
				return;
			}

			methods.reset({
				serial: atm.serial ? atm.serial : '',
				companyName: atm.name ? atm.name : '',
				address: atm.location.formattedAddress ? atm.location.formattedAddress : '',
				description: atm.location.description ? atm.location.description : '',
			});

			if (atm.openingHours) {
				setOpeningHours(atm.openingHours);
			}
		};
		handleAtmFetch();
		populateConfigureMode();
	}, [methods, atm, paramsAtmId, setValue, handleAtmFetch]);

	//refresh after new vatm added
	useEffect(() => {
		handleAtmFetch();
	}, [props.location, handleAtmFetch]);

	// handle error
	useEffect(() => {
		if (!!errorMessage) {
			setErrorMessage(null);
		}
	}, [address, openingHours, serial, companyName, description, errorMessage, setErrorMessage]);

	useEffect(() => {
		const handleAddressError = () => {
			if ((formState.isSubmitting && !address) || (formState.isSubmitting && address === '')) {
				setAddressError('Address is required');
			}
			if (!!address && address !== '' && addressError !== '') {
				setAddressError('');
			}
		};
		handleAddressError();
	}, [address, addressError, formState.isSubmitting]);

	useEffect(() => {
		const handleOpeningHoursError = () => {
			const atLeastOneDayOpen = openingHours.some((dayInfo) => !dayInfo.isClosed);

			if (formState.isSubmitting && !atLeastOneDayOpen) {
				setOpeningHoursError('Opening hours required');
			}

			if (atLeastOneDayOpen && openingHoursError !== '') {
				setOpeningHoursError('');
			}
		};
		handleOpeningHoursError();
	}, [openingHours, openingHoursError, formState.isSubmitting]);

	if (!activeViewer || (isConfigureView && !atm)) {
		return <Loader />;
	}

	const handleOnSubmit = async (input: AtmFields) => {
		if (isSubmitting) {
			return;
		}
		// check if any errors
		if (
			!address ||
			address.length === 0 ||
			addressError.length !== 0 ||
			openingHours.length === 0 ||
			openingHoursError.length !== 0 ||
			!openingHours.some((dayInfo) => !dayInfo.isClosed)
		) {
			return;
		}
		setIsSubmitting(true);
		const resultMatch = geocodeResult && geocodeResult.filter((result) => result.formattedAddress === address)[0];

		// add new atm (business)
		if (!isConfigureView) {
			// send join request to VATM merchant system
			const createAtmResponse = await createAtm({
				address: (resultMatch && resultMatch.address) || null,
				city: (resultMatch && resultMatch.city) || null,
				companyName: input.companyName,
				country: (resultMatch && resultMatch.country) || null,
				countryCode: (resultMatch && resultMatch.countryCode) || null,
				description: input.description,
				// use geocode components state here
				formattedAddress: (resultMatch && resultMatch.formattedAddress) || address,
				lat: (resultMatch && resultMatch.position.lat) || null,
				lng: (resultMatch && resultMatch.position.lng) || null,
				openingHours: openingHours,
				swipexEmail: null,
				state: (resultMatch && resultMatch.state) || null,
				serial: input.serial,
				type: AtmType.PHYSICAL,
				userId: activeViewer.id,
				zipCode: (resultMatch && resultMatch.zipCode) || null,
			});

			// handle serial error as validation error
			if (
				createAtmResponse.validationErrors &&
				createAtmResponse.validationErrors.length > 0 &&
				createAtmResponse.validationErrors[0] &&
				createAtmResponse.validationErrors[0].path === 'serial'
			) {
				methods.setError('serial', { message: createAtmResponse.validationErrors[0].message, type: 'manual' });
				setIsSubmitting(false);
				return;
			}

			if (createAtmResponse.error || !createAtmResponse.payload) {
				setErrorMessage(
					<React.Fragment>
						Something went wrong with submitting application, please try again. If problem persists then contact{' '}
						<a href="mailto:support@dagpay.io">support@dagpay.io</a>
					</React.Fragment>,
				);
				setIsSubmitting(false);
				return;
			}
		}

		// TODO: update atm info (currently all fields disabled)

		toast.success(`ATM created successfully.`);
		props.history.push(RoutesUrls.ATMS);
		setIsSubmitting(false);
	};

	return (
		<View>
			{isConfigureView ? <H1>Dagloyalty ATM connected business details</H1> : <H1>Accept Dagloyalty ATM payments</H1>}
			<NotificationWrapper>
				{isConfigureView ? (
					<Notification>View details of your business connected with a Dagcoin ATM.</Notification>
				) : (
					<Notification>
						Please provide additional information in order to connect your business with Dagloyalty ATM and start
						accepting Dagloyalty payments. Details provided will be also used to display your business listing in
						DagWallets for discovery.
					</Notification>
				)}
			</NotificationWrapper>
			<FormProvider {...methods}>
				<WhiteHookForm onSubmit={methods.handleSubmit(handleOnSubmit)}>
					<FormSeparator title="Business or store details" />
					<InformationSection>
						<HookFormColumn>
							<HookBaseField
								name="serial"
								label="ATM serial number"
								labelLink={
									isConfigureView
										? undefined
										: 'https://help.dagpay.io/en/articles/4560823-joining-the-dagcoin-atm-program'
								}
								disabled={isConfigureView}
							/>
							<HookBaseField
								label="Your store or business (brand) name"
								name="companyName"
								disabled={isConfigureView}
							/>
							{isConfigureView ? (
								<HookBaseField label="Address of business" name="address" disabled={isConfigureView} />
							) : (
								<Geocoder
									title="Address of business"
									formattedAddress={address}
									handleSetFormattedAddress={setAddress}
									placeholder="Type address..."
									errorMessage={addressError}
									disabled={isConfigureView}
								/>
							)}
							<HookBaseField
								label="Additional information"
								name="description"
								placeholder="E.g. 3rd staircase, 2nd floor"
								optionalBubble
								disabled={isConfigureView}
							/>
						</HookFormColumn>
						<HookFormColumn>
							<OpeningHours
								title="Business opening hours"
								state={openingHours}
								setState={setOpeningHours}
								errorMessage={openingHoursError}
								disabled={isConfigureView}
							/>
						</HookFormColumn>
					</InformationSection>
					<HookFormSection>
						{/* as we currently have no need to save, only display purposes */}
						{isConfigureView ? (
							<PreviousLink title="Back" to={RoutesUrls.ATMS} />
						) : (
							<CenteredFlex isJustified style={{ width: '100%', marginTop: 22 }}>
								<PreviousLink title="Back" to={RoutesUrls.ATMS} />
								<Button
									alignedRight
									disabled={isConfigureView}
									isDisabled={
										isConfigureView || addressError.length > 0 || openingHoursError.length > 0 || !!errorMessage
									}
									type={ButtonTypes.SUBMIT}
								>
									{isConfigureView ? 'Save' : 'Submit application'}
								</Button>
							</CenteredFlex>
						)}
					</HookFormSection>
					<View.Error customLinkColor={Color.RED_ERROR}>{errorMessage}</View.Error>
				</WhiteHookForm>
			</FormProvider>
		</View>
	);
}

export default withRouter(AtmDetailView);
