import React from 'react';
import styled from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import Lottie from 'lottie-react';
import releasedAnimation from '../../gfx/json/animations/released.json';
import loaderAnimation from '../../gfx/json/animations/loader.json';
import { media } from '../../services/media';
import { useLottie } from 'lottie-react';

export enum CircularProgressbarState {
	EMPTY = 'EMPTY',
	LOADER = 'LOADER',
	LOCKED = 'LOCKED',
	ERROR = 'ERROR',
	DONE = 'DONE',
	DISPLAY = 'DISPLAY',
}
export interface CircularProgressBarProps {
	size: number;
	maxValue: number;
	currentValue: number;
	lineWidth?: number;
	label?: string;
	blue?: boolean;
	green?: boolean;
	red?: boolean;
	state: CircularProgressbarState;
}

interface ColorProps {
	blue?: boolean;
	green?: boolean;
	red?: boolean;
}

const ProgressBarBackground = styled.circle`
	fill: none;
	stroke: #ddd;
`;

const ProgressBarProgressLine = styled.circle<ColorProps>`
	fill: none;
	stroke: ${Color.BLUE_9};
	stroke: ${(props) =>
		(props.color && Color.GREEN_3) || (props.blue && Color.LIGHT_BLUE) || (props.red && Color.RED_2)};
`;

const ProgressBarWrapper = styled.div`
	margin: auto;
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${media.maxSize800`
		margin: 20px auto;
	`}
`;

const ProgressBarLabel = styled.span`
	font-family: primaryMedium;
	font-size: 15px;
	margin-bottom: 17px;
	text-align: center;
`;

const ExclamationWrapper = styled.div<{ size: number }>`
	width: ${(props) => `${props.size}px`};
	height: ${(props) => `${props.size}px`};
`;

const CircularProgressBar = (props: CircularProgressBarProps) => {
	const { blue, red, green } = props;
	const viewBox = `0 0 ${props.size} ${props.size}`;
	const radius = (props.size - (props.lineWidth ? props.lineWidth : 1)) / 2;
	//circumference value as max possible progress
	const maxProgress = radius * Math.PI * 2;
	const progressOffset = maxProgress - (maxProgress * ((props.currentValue * 100) / props.maxValue)) / 100;

	const loaderAnimationOptions = {
		animationData: loaderAnimation,
		loop: true,
		autoplay: true,
		style: { height: props.size },
	};
	const LoaderView = useLottie(loaderAnimationOptions);

	LoaderView.setSpeed(0.3);

	return (
		<ProgressBarWrapper>
			{props.state !== CircularProgressbarState.EMPTY && props.label && (
				<ProgressBarLabel>{props.label}</ProgressBarLabel>
			)}
			{props.state === CircularProgressbarState.ERROR && (
				<ExclamationWrapper size={props.size}>
					<svg width="106" height="100" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clipPath="url(#clip0)">
							<rect width="106" height="106" />
							<circle cx="53" cy="53" r="49" stroke={`${Color.GRAY_13}`} strokeWidth={`${props.lineWidth}px`} />
							<path
								d="M87.2967 87.6482C68.161 106.784 37.1359 106.784 18.0002 87.6482"
								stroke={`${Color.RED_2}`}
								strokeWidth={`${props.lineWidth}px`}
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M70 63L53 35L36 63L70 63ZM64.9958 60.2L53 40.4422L41.0042 60.2L64.9958 60.2Z"
								fill={`${Color.RED_2}`}
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M54 53.5L54 46L52 46L52 53.5L54 53.5ZM54 58L54 55.75L52 55.75L52 58L54 58Z"
								fill={`${Color.RED_2}`}
							/>
						</g>
						<defs>
							<clipPath id="clip0">
								<rect width="106" height="106" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</ExclamationWrapper>
			)}

			{props.state === CircularProgressbarState.LOCKED && (
				<Lottie animationData={releasedAnimation} style={{ height: props.size }} autoplay={false} loop={false} />
			)}
			{props.state === CircularProgressbarState.LOADER && LoaderView.View}
			{props.state === CircularProgressbarState.DONE && (
				<Lottie animationData={releasedAnimation} style={{ height: props.size }} loop={false} />
			)}
			{props.state === CircularProgressbarState.DISPLAY && (
				<svg width={props.size} height={props.size} viewBox={viewBox}>
					<ProgressBarBackground
						cx={props.size / 2}
						cy={props.size / 2}
						r={radius}
						strokeWidth={`${props.lineWidth}px`}
					/>
					<ProgressBarProgressLine
						red={red}
						green={green}
						blue={blue}
						cx={props.size / 2}
						cy={props.size / 2}
						r={radius}
						strokeWidth={`${props.lineWidth}px`}
						// Start progress marker at 12 O'Clock
						transform={`rotate(-90 ${props.size / 2} ${props.size / 2})`}
						style={{
							strokeDasharray: maxProgress,
							strokeDashoffset: progressOffset,
						}}
					/>
					<text
						className="circle-text"
						x="50%"
						y="50%"
						dy=".3em"
						textAnchor="middle"
						fill={props.currentValue < 0 ? 'red' : 'black'}
					>
						{`${props.maxValue - Math.abs(props.currentValue)} days`}
					</text>
				</svg>
			)}
		</ProgressBarWrapper>
	);
};

export default CircularProgressBar;
