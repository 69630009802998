import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { StyledComponentProps } from 'styled-components';
import { Color } from '../../../../../../gfx/constants';
import { media } from '../../../../../../services/media';

const StyledChart = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${Color.WHITE};
	border-radius: 4px;
	padding: 40px 20px 20px 20px;
	border: 1px solid ${Color.GRAY_5};

	.recharts-text {
		fill: ${Color.GRAY_9};
	}

	.recharts-surface {
		overflow: visible;
	}

	${media.maxDesktop`
		padding-bottom: 20px;
		padding-top: 20px;
	`}
`;

const Chart = (props: StyledComponentProps<'div', {}, {}, never>) => <StyledChart>{props.children}</StyledChart>;

Chart.CustomTooltip = styled.div`
	display: flex;
	background-color: ${Color.WHITE};
	padding: 6px 10px 6px 10px;
	border-radius: 3px;
	color: ${Color.GRAY_14};
	border: 1px solid ${Color.GRAY_5};
`;

Chart.CustomPayload = styled.span`
	color: ${Color.GRAY_14};
	line-height: 24px;
	margin-left: 4px;
`;

Chart.CustomPayloadDate = styled.span`
	color: ${Color.GRAY_14};
	line-height: 24px;
	margin: 0 4px;
`;

Chart.CustomCurrency = styled.span`
	margin: 0 4px;
	line-height: 24px;
`;

Chart.TransactionInfoBlock = styled.div<{ x: number; active: boolean }>`
	height: 40px;
	width: 120px;
	position: absolute;
	border-radius: 50px;
	padding-top: 5px;
	background-color: black;
	text-align: center;
	justify-content: center;

	${(props) =>
		!props.active &&
		css`
			display: none;
		`}
	${(props) =>
		props.x &&
		css`
			margin-left: ${props.x - 57}px;
		`}
`;

Chart.TransactionTime = styled.div`
	color: ${Color.WHITE};
	font-size: 12px;
`;

Chart.TransactionDate = styled.div`
	color: ${Color.ORANGE};
	font-size: 12px;
`;

export default Chart;
