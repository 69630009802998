import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components/macro';

import { media } from '../../services/media';
import { Color } from '../../gfx/constants';

export const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;
export const Title = styled.div`
	font-family: PrimaryMedium;
	font-size: 15px;
	line-height: 20px;
	color: ${Color.OPTIONAL_TEXT};
	margin-bottom: 24px;
`;

export const DayTitle = styled.div`
	font-family: Primary;
	font-size: 15px;
	line-height: 20px;
	color: ${Color.OPTIONAL_TEXT};
`;

export const CheckboxWrap = styled.div`
	display: flex;
	align-items: center;
	justify-self: start;
	align-self: center;
`;

export const TimeWrapper = styled.div`
	.OpeningHoursStyle__TimePicker-d13ms6-6 {
		cursor: pointer;

		${media.maxSize1400`
			width: 118px;
		`}

		${media.maxTransactionTable`
			width: 80px;
		`}

		${media.maxNewInvoiceTable`
			width: 200px;
		`}

		${media.maxTablet`
			width: 118px;
		`}

		${media.maxTable`
			width: 80px;
		`}
	}
	.OpeningHoursStyle__TimePicker-d13ms6-6 :disabled {
		cursor: initial;
	}

	.react-datepicker__triangle {
		display: none;
	}

	.react-datepicker__header--time {
		display: none;
	}

	.react-datepicker-popper {
		top: -5px;
		width: 148px;
	}

	.react-datepicker__time-container {
		width: 148px;
		border-radius: 4px;
		background: #f8f8f8 0% 0% no-repeat padding-box;
		border: none;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);

		${media.maxSize1400`
			width: 118px;
		`}

		${media.maxTransactionTable`
			width: 80px;
		`}

		${media.maxNewInvoiceTable`
			width: 200px;
		`}

		${media.maxTablet`
			width: 118px;
		`}

		${media.maxTable`
			width: 80px;
		`}
	}

	.react-datepicker__time-list {
		/* border: 1px solid green; */
	}

	.react-datepicker__time-list-item {
		padding: 5px 0 !important;
		font-size: 15px;
		line-height: 20px;
	}

	.react-datepicker__time-container
		.react-datepicker__time
		.react-datepicker__time-box
		ul.react-datepicker__time-list
		li.react-datepicker__time-list-item--selected {
		/* border: 1px solid blue; */
	}
`;

export const Row = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 10px;
	margin-bottom: 12px;

	${media.maxSize481`
		grid-template-rows: 1fr 1fr;
		grid-template-columns: 1fr 1fr;


		${CheckboxWrap}{
			grid-column: 1 / 3;
			grid-row: 1 / 2;
			justify-self: start;
		}

		${TimeWrapper}{
			justify-self: start !important;
		}

	`}
`;

export const TimePicker = styled(ReactDatePicker)`
	width: 148px;
	height: 48px;
	border: 1px solid ${Color.GRAY_PANELBORDER};
	border-radius: 4px;
	text-align: center;
`;

export const Checkbox = styled.input`
	position: relative;
	appearance: none;
	outline: none;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	border: 1px solid ${Color.GRAY_LIGHT};
	margin-right: 14px;
	cursor: pointer;

	:checked {
		background-color: ${Color.GREEN_3};
	}

	:checked + ::after {
		content: '';
		position: absolute;
		left: 9px;
		top: 16px;
		width: 6px;
		height: 12px;
		border-style: solid;
		border-color: ${Color.WHITE};
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
		transition: all 0.25s;
	}

	:disabled {
		background-color: ${Color.GRAY_LIGHT};
		cursor: initial;
	}

	:disabled :checked {
		background-color: ${Color.WHITE};
	}

	:disabled :checked + ::after {
		border-color: ${Color.GRAY_LIGHT};
	}
`;
