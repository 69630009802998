import styled from 'styled-components/macro';
import { Color } from '../../../../../gfx/constants';
import { media } from '../../../../../services/media';

export const DownloadApp = styled.div`
	width: 100%;

	a:nth-child(2) {
		margin-left: 7px;
	}
`;

export const DownloadAppDescription = styled.div`
	font-size: 16px;
	line-height: 24px;
	font-family: Secondary;
	padding-top: 20px;
	padding-bottom: 10px;
	display: inline-block;
	width: 100%;
	color: ${Color.BLUE_9};

	${media.maxSize1000`
		padding-top: 8px;
	`}
`;

export const DownloadAppLinks = styled.div`
	display: flex;
`;

export const PairedDevicesTitle = styled.div`
	padding-top: 35px;
	width: 100%;

	p:nth-child(1) {
		font-family: PrimaryMedium;
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 0px;
		color: ${Color.BLUE_9};
	}

	p {
		font-size: 16px;
		line-height: 24px;
		font-family: Secondary;
		margin-top: 10px;
		margin-bottom: 0px;
		color: ${Color.GRAY_8};
	}

	${media.maxSize1000`
		padding-top: 36px;

		p:nth-child(1) {
			font-size: 18px;
			line-height: 22px;
			color: ${Color.GRAY_12};
		}
	`}
`;

export const PairedDevicesWrapper = styled.div`
	width: 100%;
	padding-bottom: 10px;
	display: flex;
	flex-direction: row;

	${media.maxSize1000`
		flex-direction: column;
		text-align: left;

		button {
			align-self: flex-start;
			margin-top: 30px;
		}
	`}
`;

export const ListViewWrap = styled.div`
	width: 100%;

	${media.maxSize1000`
		margin-bottom: 12px;
	`}
`;

export const PairingModalWrapper = styled.div`
	div div div {
		padding-top: 5px;
		padding-bottom: 0px;
		font-size: 14px;
	}

	h1 {
		font-size: 24px;
		line-height: 26px;
		color: ${Color.GRAY_12};
	}

	p {
		font-size: 16px;
		line-height: 22px;
		color: ${Color.GRAY_8};
		font-family: Primary;
		margin-bottom: 10px;
		margin-top: 0px;
	}

	p span {
		color: ${Color.BLUE_9};
	}

	input:disabled {
		background-color: ${Color.GRAY_2};
		color: ${Color.GRAY_14};
	}
`;

export const PairingInputWrapper = styled.div`
	div {
		background-color: ${Color.GRAY_2};
		padding: 0px !important;
	}
`;

export const PairingModalFooter = styled.div`
	div {
		display: block;
	}

	button {
		margin: 0px;
		margin-top: 20px;
		float: right !important;
	}
`;

export const TableWrapper = styled.div`
	width: 100%;

	${media.maxSize800`
		tr {
			position: relative;
		}

		tr td {
			display: inline-block;
    		width: 100%;
		}

		tr td div {
			flex-direction: column-reverse;
    		text-align: left;
			align-items: flex-start !important;
			width: 100%;
		}

		tr td div span {
			display: none;
		}

		tr td div:after {
			width: 100%;
			padding-bottom: 7px;
		}

		tr td:nth-child(1) {
			padding-top: 42px;
		}

		tr td:nth-child(3) {
			padding-bottom: 35px;
			border-bottom: 1px solid ${Color.GRAY_5};
			border-radius: 4px;
		}

		tr td:last-of-type {
			position: absolute;
			top: 14px;
			right: 17px;
			padding: 0px;
			width: 20px;
			height: 20px;
			border: none;
			min-height: 20px;

			div {
				padding: 0px !important;
			}
		}
		
		tr td:last-of-type div:after {
			display: none;
		}
	`}
`;
