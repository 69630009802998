import { ErrorMessage } from '@hookform/error-message';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components/macro';

import { CheckMarkLabel, Error, Label } from './HookFieldsStyle';

import { Color } from '../../gfx/constants';

const FieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;

const Wrapper = styled.label<{ disabled?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	input {
		:focus,
		:hover {
			border: 1px solid ${Color.GREEN_3};
		}
	}

	${(props) =>
		props.disabled === true &&
		css`
			input {
				:focus,
				:hover {
					border: 1px solid ${Color.GRAY_LIGHT};
				}
			}
		`}

	${CheckMarkLabel} {
		margin: 0;
		font-family: Secondary;
		font-size: 14px;
		letter-spacing: 0px;
		line-height: 23px;

		a {
			margin: 0;
			font-family: Secondary;
			font-size: 14px;
			letter-spacing: 0px;
			line-height: 23px;

			:visited,
			:active,
			:focus,
			:hover,
			:link {
				color: ${Color.BLUE_5};
				outline: none;
			}
		}
	}
`;

const ChildWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: left;
	position: relative;
`;

export const StyledCheckbox = styled.input`
	position: absolute;
	appearance: none;
	outline: none;
	width: 24px;
	height: 24px;
	left: 0;
	border-radius: 3px;
	border: 1px solid ${Color.GRAY_LIGHT};
	margin-right: 14px;
	cursor: pointer;
	:checked {
		background-color: ${Color.GREEN_3};
	}

	:checked + ::after {
		content: '';
		position: absolute;
		left: 9px;
		top: 0;
		bottom: 6px;
		margin: auto;
		width: 6px;
		height: 12px;
		border-style: solid;
		border-color: ${Color.WHITE};
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
		transition: all 0.25s;
	}

	:disabled {
		background-color: ${Color.GRAY_LIGHT};
		cursor: initial;
	}
	:disabled + ::after {
		display: none;
	}
`;

interface Props {
	name: string;
	label?: string;
	children?: React.ReactNode;
	disabled?: boolean;
}

export default function HookCheckboxField(props: Props) {
	const { name, label, children, disabled } = props;
	const { register, setValue, getValues } = useFormContext();

	const handleOnKeyDown = (event: React.KeyboardEvent) => {
		// do not submit, check box
		if (event.key === 'Enter') {
			event.preventDefault();

			const currentValue = getValues(name);

			if (currentValue !== undefined) {
				setValue(name as never, !currentValue);
			}
		}
	};

	return (
		<FieldWrapper>
			<ChildWrapper>
				<Wrapper disabled={disabled}>
					<StyledCheckbox type="checkbox" name={name} ref={register} onKeyDown={handleOnKeyDown} disabled={disabled} />
					<CheckMarkLabel />
					<Label>{label}</Label>
				</Wrapper>
				{children}
			</ChildWrapper>
			<Error>
				<ErrorMessage name={name} />
			</Error>
		</FieldWrapper>
	);
}
