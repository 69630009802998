import { yupResolver } from '@hookform/resolvers/yup';
import React, { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { AtmType } from '../../../../../../api/atmApi';
import { GeocodeResult } from '../../../../../../api/geoApi';
import { SwipexLinkStatus } from '../../../../../../api/swipexApi';
import Button, { ButtonTypes } from '../../../../../../components/button/Button';
import { Geocoder } from '../../../../../../components/geocoder/Geocoder';
import Loader from '../../../../../../components/loader/Loader';
import Notification from '../../../../../../components/notification/Notification';
import { DayInfo, OpeningHours, openingHoursDefault } from '../../../../../../components/opening-hours/OpeningHours';
import PreviousLink from '../../../../../../components/previous-link/PreviousLink';
import FormSeparator from '../../../../../../components/react-hook-form/FormSeparator';
import HookBaseField from '../../../../../../components/react-hook-form/HookBaseField';
import {
	HookFormColumn,
	HookFormSection,
	WhiteHookForm,
} from '../../../../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../../../../components/view/View';
import { RoutesUrls } from '../../../../../../constants';
import { CenteredFlex, H1 } from '../../../../../../gfx/globals';
import { useStoreActions, useStoreState } from '../../../../../../services/store';
import { RouteProps } from '../../../../../../typings';

import { InformationSection, NotificationWrapper, SwipexEmail, SwipexEmailError } from './VatmDetailStyle';

interface RouteParams {
	vAtmId?: string;
}

export interface VatmFields {
	swipexEmail: string;
	companyName: string;
	address: string;
	description: string;
}

function VatmDetailView(props: RouteProps<RouteParams>) {
	const [errorMessage, setErrorMessage] = useState('');
	const [openingHours, setOpeningHours] = useState<DayInfo[]>(openingHoursDefault.map((og) => Object.assign({}, og)));
	const [address, setAddress] = useState<null | string>(null);
	const [addressError, setAddressError] = useState('');
	const [swipexLinkStatusError, setSwipexLinkStatusError] = useState<null | JSX.Element>(null);
	const [openingHoursError, setOpeningHoursError] = useState('');
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const { vAtmId: paramsVatmId } = props.match.params;
	const isConfigureView = paramsVatmId !== undefined;

	const { activeViewer, geocodeResult, accountLinkInfo, vAtm } = useStoreState((state) => ({
		activeViewer: state.viewer.activeViewer,
		geocodeResult: state.geo.geoCodeResult as GeocodeResult[] | null,
		accountLinkInfo: state.swipex.accountLinkInfo,
		vAtm: state.atm.vAtm,
	}));

	const { createAccountLink, createAtm, getAccountLinkInfo, getVatm } = useStoreActions((actions) => ({
		...actions.swipex,
		...actions.atm,
	}));

	const defaultValues: VatmFields = {
		swipexEmail: '',
		companyName: '',
		address: '',
		description: '',
	};

	const schema = Yup.object().shape({
		swipexEmail: Yup.lazy(() =>
			accountLinkInfo && accountLinkInfo.swipexEmail
				? Yup.string().notRequired()
				: Yup.string()
						.trim('Field cannot be left empty or filled with spaces')
						.email('Invalid email')
						.required('Email is required'),
		),
		companyName: Yup.string()
			.required('Business (brand) name is required')
			.trim('Field cannot be left empty or filled with spaces')
			.strict(true)
			.max(30, 'Company name should not be longer than 30 characters'),
		description: Yup.string()
			.trim('Field cannot be left empty or filled with spaces')
			.strict(true)
			.max(100, 'Description should not be longer than 100 characters'),
	});

	const methods = useForm<VatmFields>({
		defaultValues: defaultValues,
		resolver: yupResolver(schema),
	});

	const { setValue, formState } = methods;
	const { swipexEmail, companyName, description } = methods.watch();

	// handle swipex account info fetching
	useEffect(() => {
		const handleSwipexLinkState = async () => {
			// add business
			if (!paramsVatmId && !accountLinkInfo && activeViewer && activeViewer.id) {
				await getAccountLinkInfo({ userId: activeViewer.id });
			}

			if (accountLinkInfo && accountLinkInfo.swipexEmail) {
				methods.reset({ swipexEmail: accountLinkInfo.swipexEmail });
				setValue('swipexEmail', accountLinkInfo.swipexEmail);
			}
		};
		handleSwipexLinkState();
	}, [methods, accountLinkInfo, paramsVatmId, activeViewer, getAccountLinkInfo, setValue]);

	// field population on mount in configure view
	useEffect(() => {
		const handleVatmFetch = async () => {
			const isNewVatm = paramsVatmId !== undefined && vAtm !== null && paramsVatmId !== vAtm.id;
			const shouldFetch = paramsVatmId !== undefined && !vAtm;

			// different atm from global state atm
			if (paramsVatmId && (shouldFetch || isNewVatm)) {
				await getVatm({ atmId: paramsVatmId });
			}
		};
		const populateConfigureMode = () => {
			if (!vAtm || !paramsVatmId) {
				return;
			}
			methods.reset({
				swipexEmail: vAtm.swipexEmail ? vAtm.swipexEmail : '',
				companyName: vAtm.name ? vAtm.name : '',
				address: vAtm.location.formattedAddress ? vAtm.location.formattedAddress : '',
				description: vAtm.location.description ? vAtm.location.description : '',
			});

			if (vAtm.openingHours) {
				setOpeningHours(vAtm.openingHours);
			}
		};
		handleVatmFetch();
		populateConfigureMode();
	}, [methods, vAtm, paramsVatmId, getVatm, setValue]);

	// handle error
	useEffect(() => {
		if (errorMessage.length > 0) {
			setErrorMessage('');
		}
	}, [address, openingHours, swipexEmail, companyName, description, setErrorMessage, errorMessage.length]);

	useEffect(() => {
		const handleAddressError = () => {
			if ((formState.isSubmitting && !address) || (formState.isSubmitting && address === '')) {
				setAddressError('Address is required');
			}
			if (!!address && address !== '' && addressError !== '') {
				setAddressError('');
			}
		};
		handleAddressError();
	}, [address, addressError, formState.isSubmitting]);

	useEffect(() => {
		const handleOpeningHoursError = () => {
			const atLeastOneDayOpen = openingHours.some((dayInfo) => !dayInfo.isClosed);

			if (formState.isSubmitting && !atLeastOneDayOpen) {
				setOpeningHoursError('Opening hours required');
			}

			if (atLeastOneDayOpen && openingHoursError !== '') {
				setOpeningHoursError('');
			}
		};
		handleOpeningHoursError();
	}, [openingHours, openingHoursError, formState.isSubmitting]);

	if (!activeViewer || (isConfigureView && !vAtm)) {
		return <Loader />;
	}

	const handleOnSubmit = async (input: VatmFields) => {
		if (isSubmitting) {
			return;
		}

		// check if any errors
		if (
			!address ||
			address.length === 0 ||
			addressError.length !== 0 ||
			openingHoursError.length !== 0 ||
			!openingHours.some((dayInfo) => !dayInfo.isClosed)
		) {
			return;
		}

		setIsSubmitting(true);
		const resultMatch = geocodeResult && geocodeResult.filter((result) => result.formattedAddress === address)[0];
		const needsLinkingWithSwipex =
			!accountLinkInfo ||
			(accountLinkInfo && !accountLinkInfo.swipexEmail) ||
			accountLinkInfo.linkStatus === SwipexLinkStatus.NOT_LINKED;

		if (needsLinkingWithSwipex) {
			// try creating swipex account link
			const createLinkResponse = await createAccountLink({
				swipexEmail: input.swipexEmail,
			});

			if (createLinkResponse.error || !createLinkResponse.payload) {
				// TODO: other errors?
				//setErrorMessage('Make sure SwipeX account email is active and verified');
				setSwipexLinkStatusError(
					<Fragment>
						<SwipexEmailError>Make sure SwipeX account is active and verified</SwipexEmailError>
						<br />
					</Fragment>,
				);
				setIsSubmitting(false);
				return;
			}
		}

		// add new vatm (business)
		if (!paramsVatmId) {
			// send join request to VATM merchant system
			const createAtmResponse = await createAtm({
				address: (resultMatch && resultMatch.address) || null,
				city: (resultMatch && resultMatch.city) || null,
				companyName: input.companyName,
				country: (resultMatch && resultMatch.country) || null,
				countryCode: (resultMatch && resultMatch.countryCode) || null,
				description: input.description,
				// use geocode components state here
				formattedAddress: (resultMatch && resultMatch.formattedAddress) || address,
				lat: (resultMatch && resultMatch.position.lat) || null,
				lng: (resultMatch && resultMatch.position.lng) || null,
				openingHours: openingHours,
				serial: null,
				state: (resultMatch && resultMatch.state) || null,
				swipexEmail:
					accountLinkInfo && accountLinkInfo.swipexEmail ? accountLinkInfo.swipexEmail : input.swipexEmail.trim(),
				type: AtmType.VIRTUAL,
				userId: activeViewer.id,
				zipCode: (resultMatch && resultMatch.zipCode) || null,
			});

			if (createAtmResponse.error || !createAtmResponse.payload) {
				setErrorMessage(
					'Something went wrong with submitting application, please try again. If problem persists then contact support@dagpay.io',
				);
				setIsSubmitting(false);
				return;
			}
		}

		// TODO: update atm info (currently all fields disabled)

		toast.success(`Virtual ATM is created successfully.`);
		props.history.push(RoutesUrls.VATMS);
		setIsSubmitting(false);
	};

	return (
		<View>
			{isConfigureView ? (
				<H1>Dagloyalty V-ATM connected business details</H1>
			) : (
				<H1>Accept Dagloyalty V-ATM payments</H1>
			)}
			<NotificationWrapper>
				{isConfigureView ? (
					<Notification>View details of your business connected with a Dagcoin Virtual ATM.</Notification>
				) : (
					<Notification>
						Please provide additional information in order to connect your business with Dagloyalty V-ATM and start
						accepting Dagloyalty payments. Details provided will be also used to display your business listing in
						DagWallets for discovery.
					</Notification>
				)}
			</NotificationWrapper>
			<FormProvider {...methods}>
				<WhiteHookForm onSubmit={methods.handleSubmit(handleOnSubmit)}>
					<FormSeparator title="Business or store details" />
					<InformationSection>
						<HookFormColumn>
							<SwipexEmail
								error={methods.errors.swipexEmail ? true : false}
								onBlur={() => setSwipexLinkStatusError(null)}
							>
								<HookBaseField
									label="Your SwipeX account email"
									name="swipexEmail"
									disabled={isConfigureView || !!(accountLinkInfo && accountLinkInfo.swipexEmail)}
								/>
								<Notification hasError={swipexLinkStatusError ? true : false}>
									{swipexLinkStatusError}
									You will need an active and verified{' '}
									<a href={process.env.REACT_APP_SWIPEX_BASE_URL} target="_blank" rel="noopener noreferrer">
										SwipeX account
									</a>{' '}
									to be able to sell dagcoins via VATM and accept ATM backed payments
								</Notification>
							</SwipexEmail>
							<HookBaseField
								label="Your store or business (brand) name"
								name="companyName"
								disabled={isConfigureView}
							/>
							{isConfigureView ? (
								<HookBaseField label="Address of business" name="address" disabled={isConfigureView} />
							) : (
								<Geocoder
									title="Address of business"
									formattedAddress={address}
									handleSetFormattedAddress={setAddress}
									placeholder="Type address..."
									errorMessage={addressError}
									disabled={isConfigureView}
								/>
							)}
							<HookBaseField
								label="Additional information"
								name="description"
								placeholder="E.g. 3rd staircase, 2nd floor"
								optionalBubble
								disabled={isConfigureView}
							/>
						</HookFormColumn>
						<HookFormColumn>
							<OpeningHours
								title="Business opening hours"
								state={openingHours}
								setState={setOpeningHours}
								errorMessage={openingHoursError}
								disabled={isConfigureView}
							/>
						</HookFormColumn>
					</InformationSection>
					<HookFormSection>
						{/* as we currently have no need to save, only display purposes */}
						{isConfigureView ? (
							<PreviousLink title="Back" to={RoutesUrls.VATMS} />
						) : (
							<CenteredFlex isJustified style={{ width: '100%', marginTop: 22 }}>
								<PreviousLink title="Back" to={RoutesUrls.VATMS} />
								<Button
									alignedRight
									disabled={isConfigureView}
									isDisabled={
										isSubmitting ||
										isConfigureView ||
										addressError.length > 0 ||
										openingHoursError.length > 0 ||
										errorMessage.length > 0 ||
										!!swipexLinkStatusError
									}
									type={ButtonTypes.SUBMIT}
								>
									{paramsVatmId ? 'Save' : 'Submit application'}
								</Button>
							</CenteredFlex>
						)}
					</HookFormSection>
					<View.Error>{errorMessage}</View.Error>
				</WhiteHookForm>
			</FormProvider>
		</View>
	);
}

export default withRouter(VatmDetailView);
