import * as qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';

import { Wallet, WalletWithUsageDate } from '../../../../api/walletApi';
import ColoredWalletUsageDate from '../../../../components/colored-wallet-usageDate/ColoredWalletUsageDate';
import Button from '../../../../components/button/Button';
import Loader from '../../../../components/loader/Loader';
import Modal from '../../../../components/modal/Modal';
import Pager from '../../../../components/pager/Pager';
import Table from '../../../../components/table/Table';
import View from '../../../../components/view/View';
import WithPermission from '../../../../components/with-permission/WithPermission';
import { ITEMS_PER_PAGE, RoutesUrls, Scopes } from '../../../../constants';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { RouteProps } from '../../../../typings';
import { WalletColSizes } from '../../../../gfx/constants';
import {
	EscrowSupportModalWrapper,
	ModalButtonWrapper,
} from '../../../../components/escrow-state-display/EscrowStateDisplayStyle';
import { TitleWrapper } from '../SettingsStyle';
import { H1, NotificationRowWrapper, NotificationText } from '../../../../gfx/globals';

function WalletsListView(props: RouteProps) {
	const [page, setPage] = useState(1);
	const [isSettingsOpened, setIsSettingsOpened] = useState<boolean[]>([]);
	const [isModalOpen, setIsModalOpen] = useState<boolean[]>([]);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [activeWallet, setActiveWallet] = useState<Wallet | null>(null);
	// const [errorMessage, setErrorMessage] = useState('');

	const { wallets } = useStoreState((state) => ({
		wallets: state.wallet.wallets,
	}));

	const { getWallets, deleteWallet } = useStoreActions((actions) => ({
		...actions.wallet,
	}));

	useEffect(() => {
		const fetchWallets = async () => {
			return await getWallets({ page: page, itemsPerPage: ITEMS_PER_PAGE });
		};
		const pageFromLink = qs.parse(props.location.search).page;

		if (pageFromLink && typeof pageFromLink === 'string') {
			setPage(parseInt(pageFromLink, 10));
			fetchWallets();
		}
	}, [page, getWallets, props.location.search]);

	if (!wallets) {
		return <Loader />;
	}

	const toggleSettingsVisibility =
		(wallet: Wallet, index: number) => (_e: React.MouseEvent<HTMLSpanElement | HTMLDivElement> | KeyboardEvent) => {
			const isSettingsOpenedCopy = isSettingsOpened.slice();

			isSettingsOpenedCopy[index] = !isSettingsOpened[index];

			setIsSettingsOpened(isSettingsOpenedCopy);
			setActiveWallet(wallet);
			setActiveIndex(index);
		};

	const handleRedirectToWalletCreation = () => {
		props.history.push(RoutesUrls.WALLETS_DETAIL);
	};

	const handleOpenModal = (index: number) => {
		const isModalOpenCopy = isModalOpen.slice();
		isModalOpenCopy[index] = true;
		setIsModalOpen(isModalOpenCopy);
	};

	const handleCloseModal = (index: number) => {
		const isModalOpenCopy = isModalOpen.slice();
		isModalOpenCopy[index] = false;
		setIsModalOpen(isModalOpenCopy);
	};

	const handleDeleteWallet = async (walletId: string, index: number) => {
		const isModalOpenCopy = isModalOpen.slice();

		try {
			const result = await deleteWallet({ walletId });

			isModalOpenCopy[index] = false;

			if ((result && result.error) || !result.payload) {
				// setErrorMessage("Can't delete wallet. Please check it's not used in one of environments or invoices. ");
				toast.error("Can't delete wallet. Please check it's not used in one of environments or invoices. ");
				return false;
			}
			setIsModalOpen(isModalOpenCopy);
			toast.success('Wallet successfully deleted');
			return true;
		} catch (e) {
			// setErrorMessage("Can't delete wallet. Please check it's not used in one of environments or invoices. ");
			toast.error("Can't delete wallet. Please check it's not used in one of environments or invoices. ");

			return false;
		}
	};

	const getDeleteModal = () => {
		if (activeIndex === null || activeWallet === null) {
			return null;
		}

		return (
			<Modal
				isOpen={isModalOpen[activeIndex]}
				ariaHideApp={false}
				onRequestClose={(_e) => handleCloseModal(activeIndex)}
				small
				hasCloseButton
			>
				<EscrowSupportModalWrapper>
					<h2>Are you sure you want to delete?</h2>
					<p>
						You are about to delete <strong>{activeWallet.name}</strong>{' '}
					</p>
					<ModalButtonWrapper>
						<Button.Secondary white onClick={() => handleCloseModal(activeIndex)}>
							Cancel
						</Button.Secondary>
						<Button.Secondary type="button" red onClick={() => handleDeleteWallet(activeWallet.id, activeIndex)}>
							Delete
						</Button.Secondary>
					</ModalButtonWrapper>
				</EscrowSupportModalWrapper>
			</Modal>
		);
	};

	const renderWalletRow = (wallet: WalletWithUsageDate, index: number) => (
		<Table.TrPrimary
			key={wallet.id}
			onMouseLeave={isSettingsOpened[index] ? toggleSettingsVisibility(wallet, index) : () => null}
		>
			<Table.FixedWidthTd cellWidth={WalletColSizes.WALLET_NAME}>
				<Table.DataWrapper data-label="Wallet name">{wallet.name}</Table.DataWrapper>
			</Table.FixedWidthTd>
			<Table.FixedWidthTd cellWidth={WalletColSizes.WALLET_ADDRESS}>
				<Table.DataWrapper wordBrake="break-all" data-label="Wallet address">
					{wallet.address}
				</Table.DataWrapper>
			</Table.FixedWidthTd>
			<Table.FixedWidthTd cellWidth={WalletColSizes.LAST_USED}>
				<Table.DataWrapper data-label="Last used">
					<ColoredWalletUsageDate dateString={wallet.usageDate} />
				</Table.DataWrapper>
			</Table.FixedWidthTd>
			<Table.FixedWidthTd cellWidth={WalletColSizes.DEFAULT}>
				<Table.DataWrapper data-label="Default">{wallet.isDefault ? 'Yes' : 'No'}</Table.DataWrapper>
			</Table.FixedWidthTd>
			<WithPermission permissions={[Scopes.WALLETS, Scopes.UPDATE_WALLET]}>
				<Table.FixedWidthTd cellWidth={WalletColSizes.ACTIONS}>
					<Table.DataWrapper data-label="Actions">
						<Table.SettingsIcon>
							<ReactSVG src="/files/svg/private/TableSettings.svg" onClick={toggleSettingsVisibility(wallet, index)} />
						</Table.SettingsIcon>
						{isSettingsOpened[index] && (
							<Table.HiddenSettings>
								<Link to={`${RoutesUrls.WALLETS_DETAIL}/${wallet.id}`}>
									<Table.HiddenSettingsItem>
										<ReactSVG src="/files/svg/private/Edit.svg" />
										<span>Edit</span>
									</Table.HiddenSettingsItem>
								</Link>
								{!wallet.isDefault && (
									<Table.HiddenSettingsItem isDisable key={index} onClick={() => handleOpenModal(index)}>
										<ReactSVG src="/files/svg/private/Delete.svg" />
										<span>Delete</span>
									</Table.HiddenSettingsItem>
								)}
							</Table.HiddenSettings>
						)}
					</Table.DataWrapper>
				</Table.FixedWidthTd>
			</WithPermission>
		</Table.TrPrimary>
	);

	return (
		<View>
			<TitleWrapper>
				<H1>Wallets</H1>
			</TitleWrapper>
			<NotificationRowWrapper>
				<NotificationText>Link your DagWallet or webwallet addresses for receiving payments</NotificationText>
				{wallets && wallets.items.length > 0 ? (
					<WithPermission permissions={[Scopes.WALLETS, Scopes.CREATE_WALLET]}>
						<Button.Secondary green onClick={handleRedirectToWalletCreation}>
							Add wallet
						</Button.Secondary>
					</WithPermission>
				) : " "}
			</NotificationRowWrapper>

			<View.ListHeader hasNoBorder></View.ListHeader>
			{getDeleteModal()}

			{wallets && wallets.items.length !== 0 ? (
				<Table>
					<Table.Thead hiddenOnSmallerScreen>
						<tr>
							<th>Wallet name</th>
							<th>Wallet address</th>
							<th>Last used</th>
							<th>Default</th>
							<WithPermission permissions={[Scopes.WALLETS, Scopes.UPDATE_WALLET]}>
								<th />
							</WithPermission>
						</tr>
					</Table.Thead>
					<tbody>{wallets.items.map(renderWalletRow)}</tbody>
					<Pager
						totalItems={wallets.itemCount}
						currentPage={page}
						onChange={(newPage) => setPage(newPage)}
						totalPages={wallets.pageCount}
					/>
				</Table>
			) : (
				<View.NoItemsFound>
					<ReactSVG src="/files/svg/private/NoWalletAdded.svg" />
					<p>You haven’t add any wallet yet</p>
					<Button.Secondary white onClick={handleRedirectToWalletCreation}>
						Add wallet
					</Button.Secondary>
				</View.NoItemsFound>
			)}
		</View>
	);
}

export default withRouter(WalletsListView);
