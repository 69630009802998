import { StoreProvider } from 'easy-peasy';
import 'normalize.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import axios from 'restyped-axios';
import * as smoothscroll from 'smoothscroll-polyfill';
import { createGlobalStyle } from 'styled-components';

import './../src/gfx/css/DatePicker.css';
import './../src/gfx/css/PhoneNumberInput.css';
import './../src/gfx/css/ReactToastify.css';
import { Color } from './gfx/constants';
import Secondary from './gfx/fonts/PT_Sans-Web-Regular.ttf';
import RobotoLight from './gfx/fonts/Roboto-Light.ttf';
import PrimaryMedium from './gfx/fonts/Roboto-Medium.ttf';
import Primary from './gfx/fonts/Roboto-Regular.ttf';
import store from './services/store';
import App from './views/App';

// kick off the smoothscroll polyfill!
smoothscroll.polyfill();

const GlobalStyle = createGlobalStyle`
	*,
	*:before,
	*:after {
		box-sizing: border-box;
		font-family: Primary;

	}

	select::-ms-expand {
		display: none;
	}

	@font-face {
    	font-family: 'RobotoLight';
		src: url(${RobotoLight});
  	}

	@font-face {
    	font-family: 'Primary';
		src: url(${Primary});
  	}

	@font-face {
    	font-family: 'PrimaryMedium';
		src: url(${PrimaryMedium});
  	}

	@font-face {
    	font-family: 'Secondary';
		src: url(${Secondary});
  	}

	main {
		display: flex;
		flex-direction: column;
		height: 100vh;
	}

	html {
		overflow-y: scroll; 
	}

	body {
		-webkit-font-smoothing: antialiased;
		margin: 0;
		padding: 0;
	}

	h1,
	h2,
	p {
		color: ${Color.BLUE_9};
		font-weight: normal;
	}

	h1, h2, h3 {
		font-family: PrimaryMedium;
	}

	p {
		font-family: Secondary;
		max-width: 100%; /* Global IE fix for text overflow */
	}

	.ReactModal__Body--open {
		overflow-y: hidden;
	}

	.ReactModal__Overlay--after-open {
		background: rgba(0, 0, 0, 0.6) !important;
    	z-index: 9999;
		overflow-y: hidden !important;
		overflow-y: hidden !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.ReactModal__Content--after-open {
		position: unset;
		padding: unset;
		overflow: unset;
		width: unset;
		height: unset;
	}
	.ReactModal__Content--after-open h1 {
		padding-right: 20px;
	}
	.Toastify__close-button {
		right: 10px;
		top: 20px;
	}
	.Toastify__close-button svg {
		fill: ${Color.WHITE};
	}
	.Toastify__toast {
		background: ${Color.GREEN_3};
		color: ${Color.WHITE};
		z-index: 99999;
	}
	.Toastify__toast--error {
		background: ${Color.RED_3} !important;
	}
	.Toastify__toast-container {
		z-index: 10000 !important;
	}
`;

// Setup axios defaults
axios.defaults.baseURL = `${process.env.REACT_APP_API_SSL === 'true' ? 'https' : 'http'}://${
	process.env.REACT_APP_API_URL
}`;
axios.defaults.withCredentials = true;

/*
	Prevent IE from caching
 	https://stackoverflow.com/questions/25157793/why-does-ie11-send-pragma-no-cache-in-request-header-for-ajax-requests
 */
axios.defaults.headers.common.Pragma = 'no-cache';

ReactDOM.render(
	<StoreProvider store={store}>
		<ToastContainer position={'top-right'} hideProgressBar transition={Slide} limit={1} icon={false} autoClose={5000} />
		<GlobalStyle />
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</StoreProvider>,
	document.getElementById('root'),
);
