import dayjs from 'dayjs';
import * as qs from 'query-string';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';

import { CustomerLimit } from '../../../../api/customerLimitApi';
import Button from '../../../../components/button/Button';
import ColoredCustomerLimit from '../../../../components/colored-customer-limit/ColoredCustomerLimit';
import {
	EscrowSupportModalWrapper,
	ModalButtonWrapper,
} from '../../../../components/escrow-state-display/EscrowStateDisplayStyle';
import Loader from '../../../../components/loader/Loader';
import Modal from '../../../../components/modal/Modal';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Notification from '../../../../components/notification/Notification';
import Pager from '../../../../components/pager/Pager';
import Table from '../../../../components/table/Table';
import View from '../../../../components/view/View';
import WithPermission from '../../../../components/with-permission/WithPermission';
import { GLOBAL_DATE_FORMAT, ITEMS_PER_PAGE, ParentViews, RoutesUrls, Scopes } from '../../../../constants';
import { CustomerLimitColSizes } from '../../../../gfx/constants';
import { ColumnedFlex, H1, Strong } from '../../../../gfx/globals';
import isUserVerified from '../../../../services/is-user-verified';
import isWalletAdded from '../../../../services/is-wallet-added';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { RouteProps } from '../../../../typings';
import { TitleWrapper } from '../SettingsStyle';

import CustomerLimitDetailView from './CustomerLimitDetailView';

function CustomerLimitsListView(props: RouteProps) {
	const [page, setPage] = useState(1);
	const [isSettingsOpened, setIsSettingsOpened] = useState<boolean[]>([]);
	const [isModalOpen, setIsModalOpen] = useState<boolean[]>([]);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [activeCustomerLimit, setActiveCustomerLimit] = useState<CustomerLimit | null>(null);
	const [isMutateModalOpen, setIsMutateModalOpen] = useState(false);

	const { customerLimitList } = useStoreState((state) => ({
		customerLimitList: state.customerLimit.customerLimitList,
		verifications: state.verification.verifications,
	}));

	const { getCustomerLimitList: getCustomerLimits, deleteCustomerLimit } = useStoreActions((actions) => ({
		...actions.customerLimit,
	}));

	useEffect(() => {
		const fetchCustomerLimits = async () => {
			return await getCustomerLimits({ page: page, itemsPerPage: ITEMS_PER_PAGE });
		};
		if (!customerLimitList) {
			fetchCustomerLimits();
		}

		const pageFromLink = qs.parse(props.location.search).page;

		if (pageFromLink && typeof pageFromLink === 'string') {
			setPage(parseInt(pageFromLink, 10));
			fetchCustomerLimits();
		}
	}, [page, props.location.search, customerLimitList, getCustomerLimits]);

	if (!customerLimitList) {
		return <Loader />;
	}

	const toggleSettingsVisibility =
		(customerLimit: CustomerLimit, index: number) =>
		(_e: React.MouseEvent<HTMLSpanElement | HTMLDivElement> | KeyboardEvent) => {
			const isSettingsOpenedCopy = isSettingsOpened.slice();

			isSettingsOpenedCopy[index] = !isSettingsOpened[index];

			setIsSettingsOpened(isSettingsOpenedCopy);
			setActiveCustomerLimit(customerLimit);
			setActiveIndex(index);
		};

	const handleOpenMutateCustomerLimitModal = ({ customerLimit }: { customerLimit: CustomerLimit | null }) => {
		if (customerLimit) {
			setActiveCustomerLimit(customerLimit);
		}
		setIsMutateModalOpen(true);
	};

	const handleOpenModal = (index: number) => {
		const isModalOpenCopy = isModalOpen.slice();
		isModalOpenCopy[index] = true;
		setIsModalOpen(isModalOpenCopy);
	};

	const handleCloseModal = (index: number) => {
		const isModalOpenCopy = isModalOpen.slice();
		isModalOpenCopy[index] = false;
		setIsModalOpen(isModalOpenCopy);
		setActiveCustomerLimit(null);
	};

	const handleDeleteCustomerLimit = async (customerLimitId: string, index: number) => {
		const isModalOpenCopy = isModalOpen.slice();

		try {
			const result = await deleteCustomerLimit({ customerLimitId });

			isModalOpenCopy[index] = false;

			setActiveCustomerLimit(null);

			if (result && result.success) {
				setIsModalOpen(isModalOpenCopy);

				toast.success('Customer limit successfully deleted');
			}
		} catch (e) {
			toast.success('Failed to delete customer limit');

			return;
		}
	};

	const getDeleteModal = () => {
		if (activeIndex === null || activeCustomerLimit === null) {
			return null;
		}

		return (
			<Modal
				small
				hasCloseButton
				ariaHideApp={false}
				isOpen={isModalOpen[activeIndex]}
				onRequestClose={() => handleCloseModal(activeIndex)}
			>
				<EscrowSupportModalWrapper>
					<h2>Delete customer limit?</h2>
					<p>
						You are about to delete customer wallet limit{' '}
						{activeCustomerLimit.name && activeCustomerLimit.name.length > 0 ? (
							<strong>{activeCustomerLimit.name}</strong>
						) : (
							<strong>{activeCustomerLimit.walletAddress}</strong>
						)}
					</p>
					<ModalButtonWrapper>
						<Button.Secondary white onClick={() => handleCloseModal(activeIndex)}>
							Cancel
						</Button.Secondary>
						<Button.Secondary red onClick={() => handleDeleteCustomerLimit(activeCustomerLimit.id, activeIndex)}>
							Delete limit
						</Button.Secondary>
					</ModalButtonWrapper>
				</EscrowSupportModalWrapper>
			</Modal>
		);
	};

	const renderCustomerLimitRow = (customerLimit: CustomerLimit, index: number) => (
		<Table.TrPrimary
			key={customerLimit.id}
			onMouseLeave={isSettingsOpened[index] ? toggleSettingsVisibility(customerLimit, index) : () => null}
		>
			<Table.FixedWidthTd cellWidth={CustomerLimitColSizes.WALLET_ADDRESS}>
				<Table.DataWrapper wordBrake="break-all" data-label="Wallet address">
					{customerLimit.walletAddress}
				</Table.DataWrapper>
			</Table.FixedWidthTd>

			<Table.FixedWidthTd cellWidth={CustomerLimitColSizes.LIMIT_NAME}>
				<Table.DataWrapper data-label="Limit name">{customerLimit.name}</Table.DataWrapper>
			</Table.FixedWidthTd>

			<Table.FixedWidthTd cellWidth={CustomerLimitColSizes.DAILY_LIMIT}>
				<Table.DataWrapper data-label="Daily limit">
					<ColoredCustomerLimit coinAmount={customerLimit.dailyLimit} />
				</Table.DataWrapper>
			</Table.FixedWidthTd>
			<Table.FixedWidthTd cellWidth={CustomerLimitColSizes.MONTHLY_LIMIT}>
				<Table.DataWrapper data-label="Monthly limit">
					<ColoredCustomerLimit coinAmount={customerLimit.monthlyLimit} />
				</Table.DataWrapper>
			</Table.FixedWidthTd>

			<Table.FixedWidthTd cellWidth={CustomerLimitColSizes.UPDATED}>
				<Table.DataWrapper data-label="Updated">
					<ColumnedFlex>
						<Strong>{dayjs(customerLimit.updatedDate).format(GLOBAL_DATE_FORMAT)}</Strong>
						{dayjs(customerLimit.updatedDate).format('HH:mm')}
					</ColumnedFlex>
				</Table.DataWrapper>
			</Table.FixedWidthTd>

			<WithPermission
				permissions={[Scopes.CUSTOMER_LIMITS, Scopes.UPDATE_CUSTOMER_LIMIT, Scopes.DELETE_CUSTOMER_LIMIT]}
			>
				<Table.FixedWidthTd cellWidth={CustomerLimitColSizes.ACTIONS}>
					<Table.DataWrapper data-label="Actions">
						<Table.SettingsIcon onClick={toggleSettingsVisibility(customerLimit, index)}>
							<ReactSVG src="/files/svg/private/TableSettings.svg" />
						</Table.SettingsIcon>
						{isSettingsOpened[index] && (
							<Table.HiddenSettings>
								<Table.HiddenSettingsItem onClick={() => handleOpenMutateCustomerLimitModal({ customerLimit })}>
									<ReactSVG src="/files/svg/private/Edit.svg" />
									<span>Edit</span>
								</Table.HiddenSettingsItem>

								<Table.HiddenSettingsItem isDisable key={index} onClick={() => handleOpenModal(index)}>
									<ReactSVG src="/files/svg/private/Delete.svg" />
									<span>Delete</span>
								</Table.HiddenSettingsItem>
							</Table.HiddenSettings>
						)}
					</Table.DataWrapper>
				</Table.FixedWidthTd>
				{/* <td
					data-label="Actions"
					
				>
					
				</td> */}
			</WithPermission>
		</Table.TrPrimary>
	);

	const handleCloseMutateModal = () => {
		setActiveCustomerLimit(null);
		setIsMutateModalOpen(false);
	};

	const renderMutateLimitModal = () => {
		if (!isMutateModalOpen) {
			return;
		}

		return <CustomerLimitDetailView handleCloseModal={handleCloseMutateModal} customerLimit={activeCustomerLimit} />;
	};

	const walletAdded = isWalletAdded();
	const isVerified = isUserVerified();

	return (
		<>
			{renderMutateLimitModal()}
			<View paddingTop={27}>
				<H1>Settings</H1>
				<NavigationBar parentView={ParentViews.SETTINGS} subTitle="Customer limits" />
				<TitleWrapper>Customer limits</TitleWrapper>
				{!walletAdded && (
					<Notification left>
						Please <Link to={RoutesUrls.WALLETS_DETAIL}> add the wallet</Link> to create new email invoice.
					</Notification>
				)}
				{!isVerified && (
					<Notification left>
						Your identity or business is not verified, you can create new email invoice after process is completed.
					</Notification>
				)}
				{walletAdded && isVerified && (
					<View.ListHeader hasNoBorder>
						<Notification>Add limits to customer wallet to limit customer spendings</Notification>
						<WithPermission permissions={[Scopes.CUSTOMER_LIMITS, Scopes.CREATE_CUSTOMER_LIMIT]}>
							<Button.Secondary green onClick={() => handleOpenMutateCustomerLimitModal({ customerLimit: null })}>
								Add wallet limit
							</Button.Secondary>
						</WithPermission>
					</View.ListHeader>
				)}
				{getDeleteModal()}

				{customerLimitList && customerLimitList.items.length !== 0 ? (
					<Table>
						<Table.Thead hiddenOnSmallerScreen>
							<tr>
								<th>Wallet address</th>
								<th>Limit name</th>
								<th>Daily limit</th>
								<th>Monthly limit</th>
								<th>Updated</th>

								<WithPermission permissions={[Scopes.CUSTOMER_LIMITS, Scopes.UPDATE_CUSTOMER_LIMIT]}>
									<th />
								</WithPermission>
							</tr>
						</Table.Thead>
						<tbody>{customerLimitList.items.map(renderCustomerLimitRow)}</tbody>
					</Table>
				) : (
					<View.NoItemsFound>
						<ReactSVG src="/files/svg/private/NoWalletAdded.svg" />
						<p>You haven’t created any customer wallet limit yet</p>
					</View.NoItemsFound>
				)}
				<Pager
					totalItems={customerLimitList.itemCount}
					currentPage={page}
					onChange={(newPage) => setPage(newPage)}
					totalPages={customerLimitList.pageCount}
				/>
			</View>
		</>
	);
}

export default withRouter(CustomerLimitsListView);
