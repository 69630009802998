import { ErrorMessage } from '@hookform/error-message';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import * as Yup from 'yup';
import { Error, Label } from './HookFieldsStyle';

export const CcFieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;
const Input = styled.input<{ changeWidth: boolean }>`
	width: 100%;
	border: 1px transparent;
	border-radius: 4px;
	margin: 0;
	padding: 0;
	outline: none;
	font-family: Primary;
	font-size: 16px;
	line-height: 29px;

	::placeholder {
		color: ${Color.BEIGE};
		opacity: 1;
	}

	${(props) =>
		props.changeWidth === true &&
		css`
			width: auto;
		`}
`;

const InputBorder = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex: 1 0 auto;
	width: 100%;
	min-height: 50px;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	border: 1px solid ${Color.GRAY_LIGHT};
	border-radius: 4px;
	padding: 0 18px;
`;

const EmailBubble = styled.div`
	display: flex;
	max-width: fit-content;
    padding: 0px 9px;
    font-size: 16px;
	color: ${Color.BLUE_9};
	background: ${Color.GRAY_2};
    margin: 5px 18px 5px -11px;
    height: 34px;
    line-height: 34px;
	border-radius: 4px;
`;

const Remove = styled.div`
	position: relative;
	width: 14px;
	height: 32px;
	margin-left: 8px;
	margin-right: 2px;
	cursor: pointer;
	margin-top: 10px;

	::after,
	::before {
		position: absolute;
		content: ' ';
		height: 13px;
		width: 2px;
		background-color: ${Color.GRAY_8};
		right: 5px;
		top: 0;
	}

	::after {
		transform: rotate(-45deg);
	}
	::before {
		transform: rotate(45deg);
	}
`;

interface Props {
	name: string;
	placeholder?: string;
	label?: string;
	optional?: boolean;
	setCcEmails: React.Dispatch<React.SetStateAction<string[]>>;
	ccEmails: string[];
}

export default function HookCcField(props: Props) {
	// input field state tracking
	const [inputFieldValue, setInputFieldValue] = useState<string | null>(null);

	const { name, placeholder, label, optional, ccEmails, setCcEmails } = props;
	const { register, setValue, setError, clearErrors } = useFormContext();

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// user fixing error
		clearErrors(name as never);
		inputFieldValue !== null && setInputFieldValue(event.target.value);
	};

	const handleOnKeyUp = async (event: React.KeyboardEvent) => {
		const inputElement = document.getElementById(name) as HTMLInputElement | null;

		if (inputElement && ((inputElement.value.includes('@') && inputElement.value.includes(',')) || inputElement.value.includes(' '))) {
			event.preventDefault();

			const cleanInputValue = inputElement.value.replace(/,| /g, '');

			if (!cleanInputValue) {
				setError(name as never, { message: 'Insert email', type: 'manual' });

				return;
			}

			const emailsCopy = ccEmails.slice();
			emailsCopy.push(cleanInputValue);

			setCcEmails(emailsCopy);

			setInputFieldValue(null);
			setValue(name as never, '');
		}
	};
	const handleOnPaste = async (event: React.ClipboardEvent) => {
		// user ready to add cc
		event.preventDefault();
		const pasted = event.clipboardData.getData('text/plain');
		// setInputFieldValue(pasted);
		// nothing inserted, raise validation error
		if (!pasted) {
			setError(name as never, { message: 'Insert email', type: 'manual' });

			return;
		}
		//validate before adding
		const isValidEmail = await Yup.string().email().max(255).required().isValid(pasted);

		if (!isValidEmail) {
			setError(name as never, { message: 'Invalid email', type: 'manual' });
			return;
		}

		// add cc mail to list
		const emailsCopy = ccEmails.slice();
		emailsCopy.push(pasted);

		setCcEmails(emailsCopy);

		// clear for another email to be inserted
		setInputFieldValue(null);
		setValue(name as never, '');
	};

	// remove bubble from list
	const handleRemoveEmailBubble = (bubbleIndex: number) => {
		const emailsCopy = ccEmails.slice();
		emailsCopy.splice(bubbleIndex, 1);
		// setEmails(emailsCopy);
		setCcEmails(emailsCopy);

		// move focus back to input
		const inputElement = document.getElementById(name);

		if (!inputElement) {
			return;
		}

		inputElement.focus();
	};

	// make styled email bubbles
	const emailBubbles = () => {
		return ccEmails.map((email, index) => (
			<EmailBubble key={index}>
				{email}
				<Remove onClick={() => handleRemoveEmailBubble(index)} />
			</EmailBubble>
		));
	};

	return (
		<CcFieldWrapper>
			<Label optional={optional}>{label}</Label>
			<InputBorder>
				{emailBubbles()}
				<Input
					id={name}
					name={name}
					ref={register}
					spellCheck={false}
					//when email already added, remove placeholder
					placeholder={ccEmails.length === 0 ? placeholder : undefined}
					onKeyUp={handleOnKeyUp}
					onChange={handleOnChange}
					onPaste={handleOnPaste}
					changeWidth={ccEmails.length > 0}
				/>
			</InputBorder>
			<Error>
				<ErrorMessage name={name} />
			</Error>
		</CcFieldWrapper>
	);
}
