import React from 'react';
import { LoadingQr, LogoWrap, QrCode, QrWrap } from './ExternalInvoiceQrStyle';
import Fade from 'react-reveal';
import { ExternalInvoiceCurrency } from '../../api/externalInvoiceApi';

export const getCurrencyIcon = (currency: ExternalInvoiceCurrency | undefined) => {
	if (!currency) {
		return '';
	}

	switch (currency) {
		case ExternalInvoiceCurrency.BTC:
			return '/files/svg/ext-invoice/Bitcoin.svg';
		case ExternalInvoiceCurrency.BCH:
			return '/files/svg/ext-invoice/BitcoinCash.svg';
		case ExternalInvoiceCurrency.ETH:
			return '/files/svg/ext-invoice/Ethereum.svg';
		case ExternalInvoiceCurrency.LTC:
			return '/files/svg/ext-invoice/Litecoin.svg';
		case ExternalInvoiceCurrency.USDT:
			return '/files/svg/ext-invoice/Tether.svg';
		case ExternalInvoiceCurrency.USDC:
		 	return '/files/svg/ext-invoice/UsdCoin.svg';

		default:
			// assertion?
			break;
	}
};

interface ExternalInvoiceQrProps {
	isDarkBackground?: boolean;
	qrCodeUrl?: string;
	paymentCurrency?: ExternalInvoiceCurrency;
}

const ExternalInvoiceQr = (props: ExternalInvoiceQrProps) => {
	const { isDarkBackground, qrCodeUrl, paymentCurrency } = props;

	return (
		<QrWrap>
			<Fade>
				<QrCode isDarkBackground={isDarkBackground} isLoading={!qrCodeUrl}>
					{qrCodeUrl ? (
						<img alt="QR code" src={`${qrCodeUrl}?v=${Date.now()}`} />
					) : (
						<LoadingQr isDarkBackground={isDarkBackground} />
					)}
					{paymentCurrency && (
						<LogoWrap>
							<img alt="logo" src={getCurrencyIcon(paymentCurrency)} />
						</LogoWrap>
					)}
				</QrCode>
			</Fade>
		</QrWrap>
	);
};

export default ExternalInvoiceQr;
