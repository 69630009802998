import { FormikFieldState } from 'formik-fields';
import * as React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import styled from 'styled-components/macro';

import { StyledInputMixin } from '../field/FieldStyle';

const StyledDatePicker = styled(ReactDatePicker)`
	${StyledInputMixin};
`;

export interface DatePickerProps extends ReactDatePickerProps {
	formikField: FormikFieldState<Date | null>;
}

export default function DatePicker(props: DatePickerProps) {
	const { placeholderText, dateFormat = 'yyyy-MM-dd', formikField, onChange, ...rest } = props;

	const timeInterval = 30;

	const setDatePickerValue = (date: Date | null) => {
		formikField.setValue(date);
	};

	return (
		<StyledDatePicker
			autoComplete="off"
			showDisabledMonthNavigation={false}
			dateFormat={dateFormat}
			showMonthDropdown
			showYearDropdown
			onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.blur()}
			name={formikField.name}
			onChange={setDatePickerValue}
			placeholderText={placeholderText}
			timeFormat="H:mm"
			timeIntervals={timeInterval}
			onChangeRaw={(e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()}
			popperPlacement="bottom-end"
			popperModifiers={{
			  flip: {
				enabled: false
			  },
			  preventOverflow: {
				enabled: true,
				escapeWithReference: false
			  }
			}}
			{...rest}
		/>
	);
}
