import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import styled from 'styled-components/macro';

import Header from '../../components/header/Header';
import NotFounPrivatedView from '../../components/not-found/PrivateNotFound';
import PrivateHeader from '../../components/private-header/PrivateHeader';
import PrivateSidebar from '../../components/private-sidebar/PrivateSidebar';
import ProtectedRoute from '../../components/protected-route/ProtectedRoute';
import VerificationReminder from '../../components/verification-reminder/VerificationReminder';
import getMergedRoutes from '../../services/get-merged-routes';
import { media } from '../../services/media';
import ScrollToTop from '../../components/scroll-to-top/ScrollToTop';

import { privateRoutes } from './routes';
import { Color } from '../../gfx/constants';

const ContentWrapper = styled.div`
	display: flex;
	flex-grow: 1;

	${media.maxSize1400`
		padding-left: 78px;
	`}

	${media.maxDesktop`
		flex-direction: column;
		align-content: stretch;
		padding-left: 0px;
	`}
`;

const MobileHeader = styled.div`
	display: none;

	${media.maxDesktop`
		display: flex;
		flex-direction: column;
	`}
`;

const MiddleColumn = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background: ${Color.GRAY_1};

	${media.maxDesktop`
		height: 100%;
	`}
`;

// This additional div is necessary to help balance cross-browser height calculation
const FlexWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-height: 100vh;
`;

export default function PrivateView() {
	const mergedRoutesList = getMergedRoutes(privateRoutes);

	return (
		<FlexWrapper>
			<VerificationReminder />
			<ContentWrapper>
				<PrivateSidebar />
				<MobileHeader>
					<Header isPrivate />
				</MobileHeader>
				<MiddleColumn>
					<PrivateHeader />
					<ScrollToTop>
						<Switch>
							{mergedRoutesList.map((route) => (
								<ProtectedRoute
									key={route.path}
									exact
									permissions={route.permissions}
									path={route.path}
									component={route.component}
								/>
							))}
							<Route path="*" component={NotFounPrivatedView} />
						</Switch>
					</ScrollToTop>
				</MiddleColumn>
			</ContentWrapper>
		</FlexWrapper>
	);
}
