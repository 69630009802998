import styled from 'styled-components/macro';

import { Color } from '../../gfx/constants';

interface StyledBurgerMenuProps {
	whiteBackground?: boolean;
}

interface BurgerMenuLinkProps {
	isFirst?: boolean;
	isLast: boolean;
}

export const BurgerMenuLink = styled.div<BurgerMenuLinkProps>`
	margin: ${({ isFirst }) => isFirst && 'auto'};
	bottom: ${({ isLast }) => isLast && 0};
	width: ${({ isLast }) => isLast && '100%'};
	padding: 15px 0;
	font-size: ${({ isLast }) => isLast && '15px'};

	&:focus {
		outline: none;
	}
`;

export const StyledBurgerMenu = styled.div<StyledBurgerMenuProps>`
	display: none;

	.bm-cross-button {
		height: 22px !important;
		width: 22px !important;
		right: 20px !important;
		top: 28px !important;
		z-index: 9999;

		button {
			outline: none;
			height: 69px !important;
			top: -28px !important;
			width: 64px !important;
			left: -27px !important;
		}

		> span {
			top: 0 !important;
			right: 0 !important;
			width: 100%;
			height: 100%;

			span {
				top: -2px;
				left: 50%;
			}
		}
	}

	.bm-cross {
		background: ${({ whiteBackground }) => (whiteBackground ? Color.GREEN_3 : Color.WHITE)};
		position: absolute;
		width: 2px !important;
		height: 20px !important;
		transform: rotate(-45deg);
	}

	.bm-menu-wrap {
		top: 0px;
		right: 0px;
		transition: none !important;
	}

	.bm-menu {
		background: ${Color.BLUE_9};
		font-size: 24px;
		text-align: center;
		display: flex;
		flex-direction: column;
	}

	.bm-morph-shape {
		fill: ${Color.BLUE_11};
	}

	.bm-item-list {
		color: ${Color.GRAY_16};
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	.bm-overlay {
		display: none;
	}

	.bm-burger-bars {
		background-color: ${({ whiteBackground }) => (whiteBackground ? Color.GREEN_3 : Color.WHITE)};
		border-radius: 4px;
		height: 2px !important;
		width: 18px;
	}

	.bm-burger-button {
		height: 15px;
		position: absolute;
		right: 20px;
		width: 18px;
		top: 50%;
		transform: translateY(-50%);

		button {
			outline: none;
			height: 69px !important;
			top: -28px !important;
			width: 64px !important;
			left: -27px !important;
		}
	}

	@media (max-width: 1024px) {
		display: block;
	}
`;
