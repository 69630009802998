import { FormikFieldState } from 'formik-fields';
import * as React from 'react';
import { ValueType } from 'react-select';
import { Color } from '../../gfx/constants';

import { FieldOptionProps } from '../field/Field';
import {
	defaultToBeginning,
	SingleValue,
	Option,
	getInitialSelectedIndex,
} from '../react-hook-form/hook-select-field/HookSelectField';

import { StyledSelect } from './SelectStyle';

interface Props extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
	title?: string;
	placeholder?: string;
	options: FieldOptionProps[];
	formikField: FormikFieldState<string>;
	defaultValue?: string | string[];
	disableError?: boolean;
	formatOptionLabel?: Function;
}

export default function Select(props: Props) {
	const { options, formikField, tabIndex, placeholder, formatOptionLabel } = props;

	const blurSelectInput = (_e: React.FocusEvent) => {
		props.formikField.handleBlur();
	};

	const changeSelectInput = (selectOption: ValueType<{ label: string; value: string } | {}>) => {
		if (selectOption && 'value' in selectOption && typeof selectOption.value === 'string') {
			props.formikField.setValue(selectOption.value, true);
		}
	};

	const sortedOptions = defaultToBeginning(options);

	const defaultIndex = formikField.value
		? sortedOptions.findIndex((option) => option.value === formikField.value)
		: getInitialSelectedIndex(sortedOptions);
	const defaultValue = defaultIndex !== null ? sortedOptions[defaultIndex] : null;
	return (
		<StyledSelect
			name={formikField.name}
			isSearchable={true}
			placeholder={placeholder}
			onBlur={blurSelectInput}
			onChange={changeSelectInput}
			classNamePrefix={'Select'}
			tabIndex={tabIndex ? tabIndex.toString() : '0'}
			options={options}
			defaultValue={defaultValue}
			components={{ SingleValue, Option }}
			selectedColor={Color.GRAY_2}
			focusedColor={Color.GRAY_0}
			// menuIsOpen={true}
			formatOptionLabel={formatOptionLabel}
		/>
	);
}
