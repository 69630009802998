import React from 'react';
import { StyledComponentProps } from 'styled-components';
import styled, { css } from 'styled-components/macro';

import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

interface StyledFieldProps {
	hasError?: boolean;
	noRightRadius?: boolean;
	autoWidth?: boolean;
	isInline?: boolean;
	noAddonIcon?: boolean;
	marginTop?: string;
	addOnNodeSize?: string;
	hasNodes?: boolean;
	isPasswordField?: boolean;
}

interface ErrorProps {
	noWidth?: boolean;
	customColor?: boolean;
}

interface AddonIconProps {
	hasError?: boolean;
	disabled?: boolean;
}

interface InputWrapperProps {
	hasError?: boolean;
	hasNoBorder?: boolean;
	height?: string;
}

interface TitleProps {
	isBold?: boolean;
	isCentered?: boolean;
	marginBottom?: number;
}

interface WrapperProps {
	hasErrors: boolean;
	securityView?: boolean;
}

interface OptionalProps {
	isAbsolute?: boolean;
	marginBottom?: number;
}

export const FieldHeight = '48px';

export const StyledInputMixin = css`
	background: transparent;
	background-color: transparent;
	background-clip: padding-box;
	border: none;
	box-shadow: none;
	font-family: Secondary;
	font-size: 15px;
	padding-left: 0.75rem;
	color: ${Color.GRAY_8};
	outline: none;
	width: 100%;
	min-height: ${FieldHeight};
	line-height: 19px;

	::placeholder {
		color: ${Color.GRAY_7};
	}
`;

const AddonNode = styled.div<{ arrowUp?: boolean }>`
	align-items: center;
	border-left: 1px solid ${Color.GRAY_5};
	cursor: pointer;
	display: flex;
	justify-content: center;
	white-space: nowrap;
	width: 90px;
	button {
		background: none !important;
		border: none;
		padding: 0 !important;
		display: flex;
		font-family: PrimaryMedium;
		cursor: pointer;
		align-items: center;
		height: ${FieldHeight};

		&:after {
			content: '';
			width: 0;
			right: 0;
			height: 0;
			border-style: solid;
			border-width: 7px 5px 0 5px;
			border-color: ${Color.GREEN_3} transparent transparent transparent;
			margin-left: 10px;
			position: relative;
		}

		${(props) =>
			props.arrowUp &&
			css`
				&:after {
					border-width: 0 5px 7px 5px;
					border-color: transparent transparent ${Color.GREEN_3} transparent;
				}
			`}
	}

	a {
		display: flex;
		font-family: PrimaryMedium;
		cursor: pointer;
		align-items: center;
		height: ${FieldHeight};

		&:after {
			content: '';
			width: 0;
			right: 0;
			height: 0;
			border-style: solid;
			border-width: 7px 5px 0 5px;
			border-color: ${Color.GREEN_3} transparent transparent transparent;
			margin-left: 10px;
			position: relative;
		}

		${(props) =>
			props.arrowUp &&
			css`
				&:after {
					border-width: 0 5px 7px 5px;
					border-color: transparent transparent ${Color.GREEN_3} transparent;
				}
			`}
	}
`;

const Field = styled.div<StyledFieldProps>`
	display: ${({ isInline, hasNodes }) => {
		if (isInline || hasNodes) {
			return 'flex';
		}

		return;
	}};
	align-items: ${({ isInline, hasNodes }) => {
		if (isInline || hasNodes) {
			return 'center';
		}

		return;
	}};
	position: relative;
	width: 100%;
	flex-wrap: wrap;
	height: auto;
	margin-top: ${({ marginTop }) => marginTop && marginTop};
	font-size: 14px;
	font-family: PrimaryMedium;

	${media.maxDesktop`
		flex-direction: ${({ hasNodes }: StyledFieldProps) => !hasNodes && 'column'};
	`}

	textarea {
		width: 100%;
	}

	input {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	${AddonNode} {
		min-width: ${({ addOnNodeSize }) => addOnNodeSize && addOnNodeSize};
	}
`;

const StyledField = (props: StyledComponentProps<'div', StyledFieldProps, StyledFieldProps, never>) => (
	<Field {...props} />
);

StyledField.Wrapper = styled.div<WrapperProps>`
	display: block;

	margin-bottom: ${({ hasErrors, securityView }) => {
		if (hasErrors && !securityView) {
			return '5px';
		}
		if (securityView && hasErrors) {
			return '15px';
		}

		return '15px';
	}};
	position: relative;
`;

StyledField.Title = styled.label<TitleProps>`
	font-size: 15px;
	font-weight: 400;
	font-family: 'PrimaryMedium';
	display: block;
	line-height: 18px;
	margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '5px')};
	justify-content: ${({ isCentered }) => isCentered && 'center'};
	text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};
	color: ${Color.BLUE_9} !important;
`;

StyledField.InputWrapper = styled.div<InputWrapperProps>`
	position: relative;
	display: flex;
	border-radius: 4px;
	max-width: 100%;
	width: 100%;
	background: ${Color.WHITE};
	height: ${({ height }) => height && height};
	border: ${({ hasError, hasNoBorder }) => {
		if (hasError && !hasNoBorder) {
			return `1px solid ${Color.RED_2}`;
		}

		if (hasNoBorder) {
			return 'none';
		}

		return `1px solid ${Color.GRAY_5}`;
	}};

	/* Fix for auto zoom input field on iOS
	https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone/41487632#41487632
	 */
	input {
		font-size: 16px;
	}

	/* targeting dropdowns */
	.css-1hwfws3 {
		font-size: 16px;
	}
	/* targeting textareas */
	.VApAQ {
		font-size: 16px;
	}
`;

StyledField.AddonNode = AddonNode;

StyledField.AddonIcon = styled.i<AddonIconProps>`
	position: relative;
	border-right: ${({ hasError }) => (hasError ? `1px solid ${Color.RED_2}` : `1px solid ${Color.GRAY_5}`)};
	background: ${({ disabled }) => disabled && '#eeeff2'};
	width: ${FieldHeight};
	max-height: ${FieldHeight};
	min-width: ${FieldHeight};
	padding: 0 !important;

	div {
		position: absolute;
		height: 100%;
		width: 100%;

		div {
			height: 100%;
			width: 100%;
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	svg {
		g,
		path {
			fill: ${({ hasError }) => hasError && `${Color.RED_2}`};
		}
	}
`;

StyledField.Input = styled.input<StyledFieldProps>`
	overflow: hidden;
	text-overflow: ellipsis;
	${StyledInputMixin}
	padding-right: ${({ isPasswordField }) => isPasswordField && '2.85rem'};

	${media.maxDesktop`
		min-width: 0;
	`}

	&:disabled {
		background: #eeeff2;
	}

	&[type='number'] {
		appearance: textfield;
	}

	&[type='number']::-webkit-inner-spin-button,
	&[type='number']::-webkit-outer-spin-button {
		appearance: none;
		margin: 0;
	}

	&::-ms-clear,
	&::-ms-reveal {
		display: none;
	}

	input {
		flex-grow: 1;
	}
`;

StyledField.PasswordIcon = styled.i`
	position: absolute;
	padding: 17px 16px;
	right: 0px;
	cursor: pointer;

	svg {
		width: 18px;
		height: 18px;
	}
`;

StyledField.Optional = styled.span<OptionalProps>`
	position: ${({ isAbsolute }) => isAbsolute && 'absolute'};
	margin-left: 10px;
	top: 0;
	padding: 2px 18px;
	background: ${Color.OPTIONAL_BACKGROUND};
	border-radius: 18px;
	color: ${Color.OPTIONAL_TEXT};
	font-size: 12px;
	font-family: PrimaryMedium;
	text-transform: capitalize;
	margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
`;

StyledField.OptionalIntergration = styled.span<OptionalProps>`
	position: ${({ isAbsolute }) => isAbsolute && 'absolute'};
	margin-left: 10px;
	top: 0;
	padding: 2px 10px;
	margin-top: -7px;
	background: ${Color.GRAY_8};
	color: ${Color.WHITE};
	border-radius: 4px;
	font-size: 14px;
	font-family: PrimaryMedium;
	text-transform: capitalize;
	margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
`;

StyledField.Error = styled.div<ErrorProps>`
	color: ${({ customColor }) => (customColor ? '#fff' : `${Color.RED_2}`)};
	font-size: 13px;
	min-height: 25px;
	line-height: 25px;
	text-align: left;
	width: ${({ noWidth }) => (noWidth ? 'none' : '100%')};
`;

StyledField.Description = styled.div`
	display: flex;
	align-items: center;
	color: ${Color.GRAY_DARK};
	font-size: 0.9rem;
`;

StyledField.Tags = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

StyledField.Tag = styled.div<{ tagPositionBottom?: boolean }>`
	padding: 9px;
	background: ${Color.BLUE};
	margin: 0 5px 10px 0;
	border-radius: 17px;
	color: ${Color.WHITE};
	display: flex;
	max-width: fit-content;
	font-size: 12px;

	${(props) =>
		props.tagPositionBottom &&
		css`
			margin: 10px 5px 0 0;
		`}
`;

StyledField.DeleteTag = styled.div`
	width: 14px;
	height: 14px;
	position: relative;
	cursor: pointer;

	&::before,
	&::after {
		position: absolute;
		content: ' ';
		height: 13px;
		width: 2px;
		background-color: #fdf5f5;
		left: px;

		right: 2px;
		top: 1px;
	}

	&::before {
		transform: rotate(45deg);
	}
	&::after {
		transform: rotate(-45deg);
	}
`;

StyledField.BaseInputWrapper = styled.div`
	flex-grow: 1;
	width: 100%;
`;

export default StyledField;
