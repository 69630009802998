import * as React from 'react';

import { RoutesUrls } from '../../constants';
import { PrivateRoute } from '../../views/private/routes';
import BurgerMenu from '../burger-menu/BurgerMenu';
import Logo from '../logo/Logo';
import PrivateSidebar from '../../components/private-sidebar/PrivateSidebar';
import PrivateHeader from '../../components/private-header/PrivateHeader';

import { HeaderContainer, HeaderWrap, PrivateHeaderContent} from './HeaderStyle';

interface Props {
	white?: boolean;
	isPrivate?: boolean;
	sidebarLinks?: PrivateRoute[];
	userName?: string;
	userCompany?: string;
}

export default function HeaderTpl(props: Props) {
	const { isPrivate = false, white } = props;

	const getPrivateBurgerMenu = () => {
		return (
			<BurgerMenu right>
				<PrivateHeaderContent>
					<PrivateHeader />
				</PrivateHeaderContent>
				<PrivateSidebar />
			</BurgerMenu>
		);
	};

	return (
		<HeaderContainer white={white}>
			<HeaderWrap>
				<Logo to={RoutesUrls.DASHBOARD} hasWhiteBackground={white} />
				{isPrivate && getPrivateBurgerMenu()}
			</HeaderWrap>
		</HeaderContainer>
	);
}
