import React from 'react';
import styled from 'styled-components/macro';

import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

export interface PasswordStrength {
	hasLowerCase: boolean;
	hasUpperCase: boolean;
	hasSpecials: boolean;
	hasTenChars: boolean;
}

interface FieldPasswordProgressProps {
	isChecked: boolean;
}

interface FieldPasswordStrengthProps {
	fair?: boolean;
	strong?: boolean;
	strongest?: boolean;
}

const FieldPasswordProgressBar = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
`;

const FieldPasswordStrength = styled.span<FieldPasswordStrengthProps>`
	color: ${({ fair, strong, strongest }) => {
		if (fair) {
			return Color.ORANGE;
		}

		if (strong) {
			return Color.YELLOW;
		}

		if (strongest) {
			return Color.GREEN_3;
		}

		return Color.RED_2;
	}};
`;

const FieldPasswordProgress = styled.div<FieldPasswordProgressProps>`
	color: ${({ isChecked }) => (isChecked ? Color.BLUE_5 : '#BABABA')};
	display: block;
	display: flex;
	flex-direction: column;
	font-weight: 400;
	padding: 15px;
	align-items: center;

	line-height: 16px;

	${media.maxTablet`
		padding: 12px;
	`}
`;
const FieldPasswordProgressItem = styled.div`
	font-size: 22px;
	display: block;
`;
const FieldPasswordProgressDescription = styled.div`
	font-size: 12px;
	line-height: 16px;
	padding-top: 5px;
`;

const PasswordProgress = (props: { passwordStrength: PasswordStrength | undefined }) => {
	const { passwordStrength } = props;

	if (!passwordStrength) {
		return null;
	}

	return (
		<FieldPasswordProgressBar>
			<FieldPasswordProgress isChecked={passwordStrength.hasLowerCase}>
				<FieldPasswordProgressItem>a</FieldPasswordProgressItem>
				<FieldPasswordProgressDescription>Lowercase</FieldPasswordProgressDescription>
			</FieldPasswordProgress>
			<FieldPasswordProgress isChecked={passwordStrength.hasUpperCase}>
				<FieldPasswordProgressItem>A</FieldPasswordProgressItem>
				<FieldPasswordProgressDescription>Uppercase</FieldPasswordProgressDescription>
			</FieldPasswordProgress>
			<FieldPasswordProgress isChecked={passwordStrength.hasSpecials}>
				<FieldPasswordProgressItem>#</FieldPasswordProgressItem>
				<FieldPasswordProgressDescription>Special</FieldPasswordProgressDescription>
			</FieldPasswordProgress>
			<FieldPasswordProgress isChecked={passwordStrength.hasTenChars}>
				<FieldPasswordProgressItem>10+</FieldPasswordProgressItem>
				<FieldPasswordProgressDescription>Characters</FieldPasswordProgressDescription>
			</FieldPasswordProgress>
		</FieldPasswordProgressBar>
	);
};

PasswordProgress.Title = (props: { passwordStrength: PasswordStrength | undefined }) => {
	const { passwordStrength } = props;

	if (!passwordStrength) {
		return null;
	}

	const passwordStrengthNumeric = Object.values(passwordStrength).filter((value) => value === true).length;

	if (passwordStrengthNumeric === 0) {
		return null;
	}

	if (passwordStrengthNumeric === 4) {
		return <FieldPasswordStrength strongest> Very Strong</FieldPasswordStrength>;
	}

	if (passwordStrengthNumeric === 3) {
		return <FieldPasswordStrength strong> Strong</FieldPasswordStrength>;
	}

	if (passwordStrengthNumeric === 2) {
		return <FieldPasswordStrength fair> Fair</FieldPasswordStrength>;
	}

	return <FieldPasswordStrength> Weak</FieldPasswordStrength>;
};

export default PasswordProgress;
