import styled from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

export const InvoiceButtonWrapper = styled.div`
	position: relative;
	align-self: flex-end;

	${media.maxSize800`
		align-self: flex-start;
	`}
`;

export const DropdownContainer = styled.div`
	position: absolute;
	right: 0;
	z-index: 1;
	background-color: ${Color.WHITE};
	border: 1px solid ${Color.GRAY_5};
	border-radius: 4px;

	& > :first-child {
		border-bottom: 1px solid ${Color.GRAY_5};

		:hover {
			background-color: ${Color.GRAY_2};
			border-radius: 4px 4px 0 0;
		}
	}

	& > :last-child {
		:hover {
			background-color: ${Color.GRAY_2};
			border-radius: 0 0 4px 4px;
		}
	}

	& > :first-child .injected-svg {
		margin: 0 8px 0 3px;
	}

	& > :last-child .injected-svg {
		margin-right: 4px;
	}

	${media.maxSize800`
		right: unset;
		left: 0;
	`}
`;

export const DropdownOption = styled.div`
	display: flex;
	align-items: center;
	width: 183px;
	font-family: Primary;
	font-size: 16px;
	line-height: 19px;
	color: ${Color.GRAY_9};
	padding: 9px 0 9px 13px;
	cursor: pointer;

	/* adjust svg to center */
	div,
	div div {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
