import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';

import Header from '../../../components/header/Header';
import { RouteProps } from '../../../typings';

const EmailVerifiedWrap = styled.div`
	padding-top: 20vh;
	text-align: center;
`;

const EmailVerified = ({ history }: RouteProps) => {
	setTimeout(() => history.push('/'), 4000);

	return (
		<>
			<Header white />
			<EmailVerifiedWrap>
				<h1>Your email is verified successfully</h1>
				<div>You will be redirected back to the dashboard after a few seconds ...</div>
			</EmailVerifiedWrap>
		</>
	);
};

export default withRouter(EmailVerified);
