import Select from 'react-select';
import styled, { css } from 'styled-components/macro';
import { Color } from '../../../gfx/constants';

interface OptionWrapperProps {
	isDefault: boolean;
	toolTipMessage?: string;
}

const DefaultTagMixin = css`
	content: 'Default';
	border-radius: 4px;
	background: ${Color.GRAY_3};
	color: ${Color.GRAY_10};
	padding: 4px 8px;
	font-size: 12px;
	line-height: 14px;
	font-family: PrimaryMedium;
	margin-left: 8px;
`;

const ToolTipMixin = css`
	position: absolute;
	left: 0;
	top: 10px;
	border-radius: 4px;
	background: ${Color.GRAY_6};
	color: ${Color.WHITE};
	padding: 3px 8px;
	font-size: 12px;
	line-height: 14px;
	font-family: Primary;
	margin-left: 8px;
`;

export const SingleValueWrapper = styled.div<{ isDefault: boolean }>`
	${(props) =>
		props.isDefault &&
		css`
			.Select__single-value {
				overflow: visible;
				::after {
					${DefaultTagMixin}
				}
			}
		`}
`;

export const AddonFieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: center;
`;

export const OptionWrapper = styled.div<OptionWrapperProps>`
	.Select__option {
		padding-left: 9px;
	}

	${(props) =>
		props.isDefault &&
		css`
			.Select__option {
				::after {
					${DefaultTagMixin}
				}
			}
		`}

	${(props: OptionWrapperProps) =>
		props.toolTipMessage &&
		css`
			.Select__option {
				position: relative;
				:hover::after {
					${ToolTipMixin};
					content: '${props.toolTipMessage}';
				}
			}
		`}
`;

export const StyledSelect = styled(Select)<{ selectedColor: string; focusedColor: string }>`
	width: 100%;

	.Select__control {
		border: none;
		appearance: none;
		background: transparent;
		border-radius: 4px;
		height: 48px;
		cursor: pointer;
		width: 100%;
		box-shadow: none;

		${(props) =>
			props.isMulti &&
			css`
				height: auto;
				min-height: 48px;
			`}
	}

	.Select__menu {
		min-width: 250px;
		border-radius: 4px;
		background-color: ${Color.WHITE};
		margin-top: 4px;
		font-family: Primary;
		font-size: 16px;
		line-height: 24px;
		color: ${Color.GRAY_9};
		text-align: left;
	}

	.Select__menu-list {
		border-radius: 4px;
		padding: 0;

		${OptionWrapper}:not(:last-child) {
			border-bottom: 1px solid ${Color.GRAY_5};
		}

		${OptionWrapper}:first-child .Select__option--is-focused, ${OptionWrapper}:first-child .Select__option--is-selected {
			border-radius: 4px 4px 0 0;
		}

		${OptionWrapper}:last-child .Select__option--is-focused, ${OptionWrapper}:last-child .Select__option--is-selected {
			border-radius: 0 0 4px 4px;
		}
	}

	.Select__option {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.Select__option :not(.Select__option--is-disabled) {
		cursor: pointer;
	}

	.Select__option--is-focused {
		background-color: ${(props) => props.focusedColor};
	}

	.Select__option--is-selected {
		background-color: ${(props) => props.selectedColor};
		color: ${Color.GRAY_9};
	}

	.Select__value-container {
		max-height: 100%;
		max-width: 360px;
	}

	.Select__indicator-separator {
		opacity: 0;
	}

	.Select__dropdown-indicator {
		svg {
			height: 25px;
			width: 25px;
		}
	}

	/* dropdown opened, rotate indicator */
	.Select__control--menu-is-open .Select__dropdown-indicator svg {
		transform: rotate(180deg);
	}
`;

export const AddOnNode = styled.a<{ toggle: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	font-family: Primary;
	width: 85px;

	::after {
		content: '';
		width: 12px;
		right: 0px;
		height: 12px;
		position: relative;
		border-style: solid;
		margin-left: 10px;
		border-width: 0px 2px 2px 0px;
		transform: rotate(45deg) translate(0, -25%);
		border-color: ${Color.GRAY_6};
	}

	${(props) =>
		props.toggle === true &&
		css`
			::after {
				transform: rotate(45deg) translate(25%, 0%);
				border-width: 2px 0px 0px 2px;
			}
		`}
`;
