import React from 'react';
import ReactSVG from 'react-svg';

import { Invoice, InvoiceState, InvoiceTypes } from '../../../api/invoiceApi';
import Button from '../../../components/button/Button';
import { isInvoicePaid } from '../../../services/is-invoice-paid';

interface RenderReturnToMerchantBlockProps {
	invoice: Invoice;
	invoiceState: InvoiceState;
}

interface GetRedirectUrlProps {
	invoice: Invoice;
	invoiceState: InvoiceState;
}

const getRedirectUrl = ({ invoice, invoiceState }: GetRedirectUrlProps) => {
	if (invoiceState === InvoiceState.CANCELLED) {
		return invoice.redirectCancelUrl;
	}

	if (invoiceState === InvoiceState.FAILED) {
		return invoice.redirectFailUrl;
	}

	if (invoiceState === InvoiceState.WAITING_FOR_CONFIRMATION) {
		return invoice.redirectSuccessUrl;
	}
	if (isInvoicePaid(invoiceState)) {
		return invoice.redirectSuccessUrl;
	}

	return invoice.redirectFailUrl;
};

function inIframe() {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
}

export const ReturnToMerchantBlock = ({ invoice, invoiceState }: RenderReturnToMerchantBlockProps) => {
	if (invoice.type === InvoiceTypes.POS) {
		return (
			<Button.Pay href={'/private/merchant/pos-checkout'}>
				<ReactSVG src="/files/svg/invoice/ReturnToDagPay.svg" />
				<span>Return to Dagpay</span>
			</Button.Pay>
		);
	}

	if (invoice.type === InvoiceTypes.MERCHANT) {
		return (
			<Button.Pay href={getRedirectUrl({ invoiceState, invoice })} target={inIframe() ? '_parent' : ''}>
				<ReactSVG src="/files/svg/invoice/ReturnTo.svg" />
				<span>Return to merchant</span>
			</Button.Pay>
		);
	}

	if (invoice.type === InvoiceTypes.ESCROW) {
		return (
			<Button.Pay href={invoice.environment.statusUrl}>
				<span>Return to escrow deal</span>
			</Button.Pay>
		);
	}

	return null;
};
