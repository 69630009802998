import * as qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import { Environment } from '../../../../../../api/environmentApi';
import Button from '../../../../../../components/button/Button';
import {
	EscrowSupportModalWrapper,
	ModalButtonWrapper,
} from '../../../../../../components/escrow-state-display/EscrowStateDisplayStyle';
import StyledField from '../../../../../../components/field/FieldStyle';
import Loader from '../../../../../../components/loader/Loader';
import Modal from '../../../../../../components/modal/Modal';
import Notification from '../../../../../../components/notification/Notification';
import Pager from '../../../../../../components/pager/Pager';
import Table from '../../../../../../components/table/Table';
import View from '../../../../../../components/view/View';
import WithPermission from '../../../../../../components/with-permission/WithPermission';
import { CamelCasedIntegrationTypes, ITEMS_PER_PAGE, RoutesUrls, Scopes } from '../../../../../../constants';
import { EnvironmentColSizes, Gutter } from '../../../../../../gfx/constants';
import { ColumnedFlex, H1 } from '../../../../../../gfx/globals';
import { media } from '../../../../../../services/media';
import { useStoreActions, useStoreState } from '../../../../../../services/store';
import { RouteProps } from '../../../../../../typings';

const EnvironmentNotificationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 100%;
	margin-right: ${Gutter.SMALL};
`;

const EnvironmentNameWrapper = styled.div`
	display: flex;
	font-family: PrimaryMedium;

	${media.maxTable`
		margin-top: 15px;
		flex-direction: column;
		width: fit-content;

		${StyledField.Optional}{
			margin: 5px 0 0 0;

		}
	`}
`;

function EnvironmentsListView(props: RouteProps) {
	const [activeEnvironment, setActiveEnvironment] = useState<Environment | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean[]>([]);
	const [isSettingsOpened, setIsSettingsOpened] = useState<boolean[]>([]);
	// const [errorMessage, setErrorMessage] = useState('');
	const [page, setPage] = useState(1);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);

	const { verifications, parentVerifications, environments, activeViewer } = useStoreState((state) => ({
		environments: state.environment.environments,
		verifications: state.verification.verifications,
		parentVerifications: state.verification.parentUserVerifications,
		activeViewer: state.viewer.activeViewer,
	}));

	const { getEnvironments, deleteEnvironment } = useStoreActions((actions) => ({
		...actions.environment,
		...actions.verification,
	}));

	useEffect(() => {
		const fetchEnvironments = async (page: number) => {
			await getEnvironments({ page: page, itemsPerPage: ITEMS_PER_PAGE });
		};
		fetchEnvironments(page);
	}, [page, getEnvironments]);

	useEffect(() => {
		const newPage = qs.parse(props.location.search).page;

		if (newPage && typeof newPage === 'string') {
			setPage(parseInt(newPage as string, 10));
		}
	}, [page, props.location.search]);

	if (!environments) {
		return <Loader />;
	}

	const isWalletAdded = verifications ? verifications.isWalletAdded : false;

	const renderEnvironmentTable = (environment: Environment, index: number) => (
		<Table key={index}>
			<Table.Thead>
				<tr>
					<th colSpan={4}>
						<EnvironmentNameWrapper>
							{environment.name}{' '}
							<StyledField.Optional>{CamelCasedIntegrationTypes[environment.integrationType]} </StyledField.Optional>
						</EnvironmentNameWrapper>
					</th>
				</tr>
			</Table.Thead>

			<tbody>
				<Table.TrPrimary
					key={environment.id}
					onMouseLeave={isSettingsOpened[index] ? toggleSettingsVisibility(environment, index) : () => null}
				>
					<Table.FixedWidthTd cellWidth={EnvironmentColSizes.ENVIRONMENT_ID}>
						<Table.NoLabelDataWrapper wordBrake="break-all" justifyOption="flex-start">
							<ColumnedFlex>
								<Table.TdInsideTitle>Environment ID</Table.TdInsideTitle>
								{environment.id}
							</ColumnedFlex>
						</Table.NoLabelDataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={EnvironmentColSizes.USER_ID}>
						<Table.NoLabelDataWrapper wordBrake="break-all" justifyOption="flex-start">
							<ColumnedFlex>
								<Table.TdInsideTitle>User ID</Table.TdInsideTitle>
								{environment.userId}
							</ColumnedFlex>
						</Table.NoLabelDataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={EnvironmentColSizes.SECRET}>
						<Table.NoLabelDataWrapper wordBrake="break-all" justifyOption="flex-start">
							<ColumnedFlex>
								<Table.TdInsideTitle>Secret</Table.TdInsideTitle>
								{environment.secret}
							</ColumnedFlex>
						</Table.NoLabelDataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={EnvironmentColSizes.ACTIONS}>
						<Table.DataWrapper data-label="Actions">
							<Table.SettingsIcon onClick={toggleSettingsVisibility(environment, index)}>
								<ReactSVG src="/files/svg/private/TableSettings.svg" />
							</Table.SettingsIcon>
							{isSettingsOpened[index] && (
								<Table.HiddenSettings>
									<Link to={`environments/detail/${environment.integrationType}/${environment.id}`}>
										<Table.HiddenSettingsItem>
											<ReactSVG src="/files/svg/private/Edit.svg" />
											<span>Edit</span>
										</Table.HiddenSettingsItem>
									</Link>

									<button key={index} onClick={(e) => handleOpenModal(index)}>
										<Table.HiddenSettingsItem isDisable>
											<ReactSVG src="/files/svg/private/Delete.svg" />
											<span>Delete</span>
										</Table.HiddenSettingsItem>
									</button>
								</Table.HiddenSettings>
							)}
						</Table.DataWrapper>
					</Table.FixedWidthTd>
				</Table.TrPrimary>
			</tbody>
		</Table>
	);

	const getDeleteModal = () => {
		if (activeIndex === null || activeEnvironment === null) {
			return null;
		}

		const createdByAnotherUser = activeViewer && activeViewer.id !== activeEnvironment.creatorId;

		return (
			<Modal
				isOpen={isModalOpen[activeIndex]}
				ariaHideApp={false}
				onRequestClose={() => handleCloseModal(activeIndex)}
				small
				hasCloseButton
			>
				<EscrowSupportModalWrapper>
					<h1>Delete environment?</h1>
					<p>
						You are about to delete the environment <strong>{activeEnvironment.name}</strong>
						{createdByAnotherUser && (
							<span>
								, created by another user <strong>{activeEnvironment.creator.firstName}</strong>{' '}
								<strong>{activeEnvironment.creator.lastName}</strong>.
							</span>
						)}
					</p>
					<ModalButtonWrapper>
						<Button.Secondary white onClick={() => handleCloseModal(activeIndex)}>
							Cancel
						</Button.Secondary>
						<Button.Secondary
							type="button"
							red
							onClick={() => handleDeleteEnvironment(activeEnvironment.id, activeIndex)}
						>
							Delete
						</Button.Secondary>
					</ModalButtonWrapper>
					{/* <View.Error center>{errorMessage || ''}</View.Error> */}
				</EscrowSupportModalWrapper>
			</Modal>
		);
	};

	const toggleSettingsVisibility =
		(environment: Environment, index: number) =>
		(_e: React.MouseEvent<HTMLSpanElement | HTMLDivElement> | KeyboardEvent) => {
			const isSettingsOpenedCopy = isSettingsOpened.slice();

			isSettingsOpenedCopy[index] = !isSettingsOpened[index];

			setIsSettingsOpened(isSettingsOpenedCopy);
			setActiveEnvironment(environment);
			setActiveIndex(index);
		};

	const handlePageChanged = (newPage: number) => {
		setPage(newPage);
	};

	const handleRedirectToCreateEnvironment = () => {
		props.history.push(`environments/choose-environment-type`);
	};

	const handleOpenModal = (index: number) => {
		const isModalOpenCopy = isModalOpen.slice();
		isModalOpenCopy[index] = true;
		setIsModalOpen(isModalOpenCopy);
	};

	const handleCloseModal = (index: number) => {
		const isModalOpenCopy = isModalOpen.slice();
		isModalOpenCopy[index] = false;
		setIsModalOpen(isModalOpenCopy);
	};

	const handleDeleteEnvironment = async (environmentId: string, index: number) => {
		const isModalOpenCopy = isModalOpen.slice();

		try {
			const response = await deleteEnvironment({ environmentId });

			isModalOpenCopy[index] = false;
			setIsModalOpen(isModalOpenCopy);

			if (response && response.success) {
				setIsModalOpen(isModalOpenCopy);

				toast.success('Environment successfully deleted');
				props.history.push(RoutesUrls.ENVIRONMENTS);
			}
		} catch (e) {
			// setErrorMessage(e);
			toast.error(e);

			return;
		}
	};

	return (
		<View paddingBottom={37} paddingTop={37}>
			<H1> Integrations</H1>
			<View.ListHeader>
				<EnvironmentNotificationWrapper>
					<Notification>Create a new environment and integrate it with your website to accept payments.</Notification>
					<Notification>
						Note that when using the Dagpay API for multiple projects you must create new unique API credentials for
						each project.
					</Notification>
					{!isWalletAdded && (
						<WithPermission permissions={[Scopes.WALLETS, Scopes.CREATE_WALLET]}>
							<Notification>
								Please <Link to={RoutesUrls.WALLETS_DETAIL}> add the wallet</Link> to create new environment.
							</Notification>
						</WithPermission>
					)}
				</EnvironmentNotificationWrapper>
				{((isWalletAdded && environments.items.length > 0) || (parentVerifications?.isWalletAdded && environments.items.length > 0)) && (
					<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT]}>
						<Button.Secondary green onClick={handleRedirectToCreateEnvironment}>
							<span>Add integration</span>
						</Button.Secondary>
					</WithPermission>
				)}
			</View.ListHeader>

			{getDeleteModal()}

			{environments && environments.items.length > 0 ? (
				<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.GET_ENVIRONMENTS]}>
					{environments.items.map(renderEnvironmentTable).reverse()}
				</WithPermission>
			) : (
				<View.NoItemsFound>
					<ReactSVG src="/files/svg/private/NoTransactions.svg" />
					<p>You haven't created any integrations yet</p>
					<Button.Secondary white onClick={handleRedirectToCreateEnvironment}>
						Add integration
					</Button.Secondary>
				</View.NoItemsFound>
			)}
			<Pager
				totalItems={environments.itemCount}
				currentPage={page}
				onChange={handlePageChanged}
				totalPages={environments.pageCount}
			/>
		</View>
	);
}

export default withRouter(EnvironmentsListView);
