import * as React from 'react';
import * as qs from 'query-string';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';

import Header from '../../components/header/Header';
import { PageTitleTextMixin, secondaryTextMixin } from '../../gfx/globals';
import { RouteProps } from '../../typings';
import { Color } from '../../gfx/constants';

const LimitExceededWrap = styled.div`
	margin: auto auto;
	display: flex;
	padding: 18px;
`;
const IconWrap = styled.div`
	padding: 18px;
`;
const LimitExceededText = styled.div`
	margin: auto auto;
	h1 {
		${PageTitleTextMixin}
	}
	p {
		${secondaryTextMixin}
		margin: 0;
		a,
		a:visited {
			text-decoration: none;
			color: ${Color.BLUE_1};
		}
	}
`;

interface RouteParams {
	redirectUrl?: string;
}

const MerchantLimitExceeded = (props: RouteProps<RouteParams>) => {
	const redirectUrl = qs.parse(props.location.search).redirectUrl as string;
	const supportEmail = qs.parse(props.location.search).supportEmail as string;
	return (
		<>
			<Header white />
			<LimitExceededWrap>
				<IconWrap>
					<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M37.2131 37.164V35.914V37.164ZM4.78688 37.164V38.414V37.164ZM23.0512 5.12308L21.9734 5.75619L21.9734 5.75619L23.0512 5.12308ZM39.6635 33.4035L40.7413 32.7704L39.6635 33.4035ZM18.9488 5.12308L20.0266 5.75619V5.75619L18.9488 5.12308ZM2.33653 33.4035L3.41434 34.0366L2.33653 33.4035ZM37.2131 35.914H4.78688V38.414H37.2131V35.914ZM21.9734 5.75619L38.5857 34.0366L40.7413 32.7704L24.129 4.48996L21.9734 5.75619ZM17.871 4.48996L1.25872 32.7704L3.41434 34.0366L20.0266 5.75619L17.871 4.48996ZM21 2.75C19.7366 2.75 18.5199 3.38528 17.871 4.48996L20.0266 5.75619C20.1903 5.47743 20.5493 5.25 21 5.25V2.75ZM24.129 4.48996C23.4801 3.38529 22.2634 2.75 21 2.75V5.25C21.4507 5.25 21.8097 5.47743 21.9734 5.75619L24.129 4.48996ZM4.78688 35.914C4.15476 35.914 3.68739 35.6108 3.44492 35.2387C3.20856 34.8759 3.17421 34.4454 3.41434 34.0366L1.25873 32.7704C-0.32544 35.4672 1.91114 38.414 4.78688 38.414V35.914ZM37.2131 38.414C40.0889 38.414 42.3254 35.4672 40.7413 32.7704L38.5857 34.0366C38.8258 34.4454 38.7914 34.8759 38.5551 35.2387C38.3126 35.6108 37.8452 35.914 37.2131 35.914V38.414Z"
							fill="#17203C"
						/>
						<path
							d="M22.25 29.5108C22.25 28.8204 21.6904 28.2608 21 28.2608C20.3096 28.2608 19.75 28.8204 19.75 29.5108L22.25 29.5108ZM19.75 30.7863C19.75 31.4767 20.3096 32.0363 21 32.0363C21.6904 32.0363 22.25 31.4767 22.25 30.7863L19.75 30.7863ZM22.25 15.4798C22.25 14.7895 21.6904 14.2298 21 14.2298C20.3096 14.2298 19.75 14.7895 19.75 15.4798L22.25 15.4798ZM19.75 25.0464C19.75 25.7367 20.3096 26.2964 21 26.2964C21.6904 26.2964 22.25 25.7367 22.25 25.0464H19.75ZM19.75 29.5108L19.75 30.7863L22.25 30.7863L22.25 29.5108L19.75 29.5108ZM19.75 15.4798L19.75 25.0464H22.25L22.25 15.4798L19.75 15.4798Z"
							fill="#17203C"
						/>
					</svg>
				</IconWrap>
				<LimitExceededText>
					<h1>Cannot process payment</h1>
					<p>Unfortunately we are unable to process this payment.</p>
					{redirectUrl && (
						<p>
							<a href={redirectUrl} rel="noopener noreferrer">
								Return to merchant
							</a>
						</p>
					)}
					{supportEmail && !redirectUrl && (
						<p>
							Please reach out to the merchant for more info at{' '}
							<a href={`mailto:${supportEmail}`} rel="noopener noreferrer">
								{supportEmail}
							</a>
						</p>
					)}
				</LimitExceededText>
			</LimitExceededWrap>
		</>
	);
};

export default withRouter(MerchantLimitExceeded);
