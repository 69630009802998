import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Environment, EnvironmentType } from '../../../../../../api/environmentApi';
import Button, { ButtonTypes } from '../../../../../../components/button/Button';
import CopyButton from '../../../../../../components/copy-button/CopyButton';
import ErrorView from '../../../../../../components/error/ErrorView';
import { ModalButtonWrapper } from '../../../../../../components/escrow-state-display/EscrowStateDisplayStyle';
import { FieldOptionProps } from '../../../../../../components/field/Field';
import StyledField from '../../../../../../components/field/FieldStyle';
import Loader from '../../../../../../components/loader/Loader';
import Modal from '../../../../../../components/modal/Modal';
import Notification from '../../../../../../components/notification/Notification';
import PanelNew, { PanelSectionType } from '../../../../../../components/panel-new/PanelNew';
import PreviousLink from '../../../../../../components/previous-link/PreviousLink';
import HookSelectField from '../../../../../../components/react-hook-form/hook-select-field/HookSelectField';
import HookBaseField from '../../../../../../components/react-hook-form/HookBaseField';
import HookTextAreaField from '../../../../../../components/react-hook-form/HookTextAreaField';
import ReactHookForm, {
	HookFormColumn,
	HookFormSection,
	HookFormSeparator,
} from '../../../../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../../../../components/view/View';
import { CamelCasedIntegrationTypes, RoutesUrls } from '../../../../../../constants';
import { Color } from '../../../../../../gfx/constants';
import { CenteredFlex, H1 } from '../../../../../../gfx/globals';
import { getWalletOptions } from '../../../../../../services/get-wallet-options';
import { useStoreActions, useStoreState } from '../../../../../../services/store';
import { RouteProps } from '../../../../../../typings';

import { DownloadPluginButton, FieldNode, PluginDescription, PluginHeader, OverlayWithBackground } from './EnvironmentDetailStyle';

interface IntegrationFields {
	name: string;
	email: string;
	walletId: FieldOptionProps | null;
	description: string;
	statusUrl: string;
	integrationType: EnvironmentType;
	redirectSuccessUrl: string;
	redirectCancelUrl: string;
	redirectFailUrl: string;
	environmentId: string;
	userId: string;
	secret: string;
}

const validateOnlyHttpsUrl = /^https:\/\//

const integrationValidationSchema = Yup.object<IntegrationFields>().shape({
	name: Yup.string()
		.required('Name is required')
		.min(2, 'Name should contain at least 2 characters')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true),
	email: Yup.string().email('Invalid email'),
	walletId: Yup.object<FieldOptionProps>().typeError('Please choose the wallet'),
	integrationType: Yup.mixed().oneOf(Object.values(EnvironmentType)),
	statusUrl: Yup.string().matches(validateOnlyHttpsUrl,'Status URL must include secure “https://” to create environment')
		.strict(true),
	redirectSuccessUrl: Yup.string().matches(validateOnlyHttpsUrl,'Status URL must include secure “https://” to create environment').required('Redirect URL is required'),
	redirectCancelUrl: Yup.string().matches(validateOnlyHttpsUrl,'Status URL must include secure “https://” to create environment').required('Redirect URL is required'),
	redirectFailUrl: Yup.string().matches(validateOnlyHttpsUrl,'Status URL must include secure “https://” to create environment').required('Redirect URL is required'),
	description: Yup.string()
		.max(1024, 'The maximum length is 1024 characters')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true),
	environmentId: Yup.string().notRequired(),
	userId: Yup.string().notRequired(),
	secret: Yup.string().notRequired(),
});

interface RouteParams {
	id?: string;
	integrationType: EnvironmentType;
}

function EnvironmentsDetailView(props: RouteProps<RouteParams>) {
	const isCreatable = !props.match.params.id;
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [environment, setEnvironment] = useState<Environment | null>(null);
	const [isActionLoading, setIsActionLoading] = useState(false);
	const [mainUrl, setMainUrl] = useState('');
	const [wooCommerceSuccess, setWooCommerceSuccess] = useState('');
	const [wooCommerceCancel, setWooCommerceCancel] = useState('');
	const [wooCommerceFail, setWooCommerceFail] = useState('');

	const { wallets } = useStoreState((state) => ({
		wallets: state.wallet.wallets,
	}));

	const { getEnvironment, updateEnvironment, createEnvironment } = useStoreActions((actions) => ({
		...actions.environment,
	}));

	const changeMainUrl = (event: any) => {
		setMainUrl(event.target.value);
	};

	useEffect(() => {
		if (mainUrl) {
			setWooCommerceSuccess(mainUrl + "/checkout/order-received/");
			setWooCommerceCancel(mainUrl + "/checkout/cancel/");
			setWooCommerceFail(mainUrl + "/checkout/fail/");
		}
	}, [mainUrl]);

	useEffect(() => {
		const fetchEnvironment = async (environmentId?: string) => {
			if (!environmentId) {
				return;
			}

			const response = await getEnvironment({ environmentId });

			if (response && response.success) {
				if (!environment) {
					setEnvironment(response.payload);
					methods.reset({
						name: response.payload ? response.payload.name : '',
						email: response.payload ? response.payload.email : '',
						walletId: wallets ? getWalletOptions(wallets).filter((w) => w.isDefault)[0] : null,
						description: response.payload ? response.payload.description : '',
						statusUrl: response.payload
							? getModifiedStatusUrl(response.payload.statusUrl, match.params.integrationType)
							: '',
						redirectSuccessUrl: response.payload ? response.payload.redirectSuccessUrl : '',
						redirectCancelUrl: response.payload ? response.payload.redirectCancelUrl : '',
						redirectFailUrl: response.payload ? response.payload.redirectFailUrl : '',
						integrationType: match.params.integrationType,
						environmentId: response.payload ? response.payload.id : '',
						userId: response.payload ? response.payload.userId : '',
						secret: response.payload ? response.payload.secret : '',
					});
				}
			}
		};

		fetchEnvironment(props.match.params.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getEnvironment, environment, props.match.params.id, wallets]);

	const { match } = props;

	const defaultValues = {
		name: environment ? environment.name : '',
		email: environment ? environment.email : '',
		walletId: wallets ? getWalletOptions(wallets).filter((w) => w.isDefault)[0] : null,
		description: environment ? environment.description : '',
		statusUrl: environment ? getModifiedStatusUrl(environment.statusUrl, match.params.integrationType) : '',
		redirectSuccessUrl: environment ? environment.redirectSuccessUrl : '',
		redirectCancelUrl: environment ? environment.redirectCancelUrl : '',
		redirectFailUrl: environment ? environment.redirectFailUrl : '',
		integrationType: match.params.integrationType,
		environmentId: environment ? environment.id : '',
		userId: environment ? environment.userId : '',
		secret: environment ? environment.secret : '',
	};
	const methods = useForm<IntegrationFields>({
		resolver: yupResolver(integrationValidationSchema),
		defaultValues: defaultValues,
		shouldFocusError: true,
		shouldUnregister: false,
		mode: 'onChange',
	});

	if (!isCreatable && !environment) {
		return <Loader />;
	}

	if (!match.params.integrationType) {
		return <ErrorView />;
	}

	const handleRedirectToCreatedEnvironment = () => {
		props.history.push(RoutesUrls.ENVIRONMENTS);
		toast.success(`Environment is created successfully.`);
	};

	async function handleSaveEnvironment(input: IntegrationFields) {
		const { integrationType, id } = match.params;

		const { environmentId, userId, secret, ...newInput } = input;
		setIsActionLoading(true);

		const modifiedStatusUrl = trimSlash(
			input.statusUrl
				.replace('https://', '')
				.replace('http://', '')
				.replace('/dagcoin/response', '')
				.replace('?wc-api=dagcoin_handler', '')
				.replace('?fc=module&module=dagpay&controller=callback', '')
				.replace('?edd-listener=dagpay', '')
				.replace('?route=extension/payment/dagpay/callback', ''),
		);

		const switchStatusUrl = () => {
			switch (integrationType) {
				case EnvironmentType.WOOCOMMERCE:
					return `https://${modifiedStatusUrl.trim()}?wc-api=dagcoin_handler`;
				case EnvironmentType.MAGENTO:
					return `https://${modifiedStatusUrl.trim()}/dagcoin/response`;
				case EnvironmentType.OPENCART:
					return `https://${modifiedStatusUrl.trim()}?route=extension/payment/dagpay/callback`;
				case EnvironmentType.PRESTASHOP:
					return `https://${modifiedStatusUrl.trim()}?fc=module&module=dagpay&controller=callback`;
				case EnvironmentType.EASY_DIGITAL_DOWNLOADS:
					return `https://${modifiedStatusUrl.trim()}?edd-listener=dagpay`;
				default:
					return `https://${modifiedStatusUrl.trim()}/dagcoin/response`;
			}
		};

		let statusUrl = switchStatusUrl();

		if (integrationType === EnvironmentType.CUSTOM) {
			statusUrl = input.statusUrl;
		}

		if (!integrationType) {
			setIsActionLoading(false);
			return;
		}

		if (!input.walletId) {
			setIsActionLoading(false);
			return methods.setError('walletId', { message: 'Please choose the wallet' });
		}

		const response = id
			? await updateEnvironment({
					...newInput,
					id,
					statusUrl,
					integrationType,
					walletId: input.walletId.value.toString(),
			  })
			: await createEnvironment({
					...newInput,
					statusUrl,
					integrationType,
					walletId: input.walletId.value.toString(),
			  });

		if (response.error) {
			setIsActionLoading(false);
			return;
		}
		setIsActionLoading(false);
		if (isCreatable && response.payload) {
			const result = response.payload;

			const { id, walletId, ...newResult } = result;
			setIsCreateModalOpen(true);
			methods.reset({
				environmentId: result.id,
				walletId: getWalletOptions(wallets).filter((w) => w.isDefault)[0],
				...newResult,
			});

			return;
		}

		if (response.payload && !isCreatable) {
			props.history.push(RoutesUrls.ENVIRONMENTS);
			toast.success(`Environment is updated successfully.`);

			return;
		}
	}

	const getCreateModal = () => {
		const values = methods.getValues();
		return (
			<Modal ariaHideApp={false} isOpen={isCreateModalOpen} onRequestClose={handleRedirectToCreatedEnvironment} hasCloseButton>
				<H1>Your web platform configuration details</H1>
				<OverlayWithBackground>
					<CenteredFlex marginBottom={20}>
						<StyledField.Title>{values.name}</StyledField.Title>
						<StyledField.OptionalIntergration>
							{CamelCasedIntegrationTypes[values.integrationType]}
						</StyledField.OptionalIntergration>
					</CenteredFlex>
					<Notification maxWidth="440px">
						Use the following credentials to accept Dagcoin payments on your website. Refer to the {' '}
						<a href={RoutesUrls.DOCUMENTATION} target="_blank" rel="noopener noreferrer">
								Dagpay API documentation
						</a>{' '} to integrate or take a look at an example in our <a href="https://help.dagpay.io/en/" target="_blank" rel="noopener noreferrer">support section</a>.
					</Notification>
				</OverlayWithBackground>
				<HookBaseField name="environmentId" label="Environment ID" disabled addonSize={48} addonNode={
					<CopyButton value={values.environmentId}>
						<ReactSVG src="/files/svg/private/CopySquare.svg" />
					</CopyButton>
				} />
				<HookBaseField name="userId" label="User ID" disabled addonSize={48} addonNode={
					<CopyButton value={values.userId}>
						<ReactSVG src="/files/svg/private/CopySquare.svg" />
					</CopyButton>
				} />
				<HookBaseField name="secret" label="Secret" disabled addonSize={48} addonNode={
					<CopyButton value={values.secret}>
						<ReactSVG src="/files/svg/private/CopySquare.svg" />
					</CopyButton>
				}/>
				<ModalButtonWrapper>
					<Button.Secondary type={ButtonTypes.BUTTON} green onClick={handleRedirectToCreatedEnvironment}>
						Save and close
					</Button.Secondary>
				</ModalButtonWrapper>
			</Modal>
		);
	};

	const wrapInFieldNode = (urlRightSide: string) => <FieldNode>{urlRightSide}</FieldNode>;

	const chooseCorrectURLRightWrapper = (type: EnvironmentType) => {
		switch (type) {
			case EnvironmentType.WOOCOMMERCE:
				return wrapInFieldNode('?wc-api=dagcoin_handler');
			case EnvironmentType.MAGENTO:
				return wrapInFieldNode('/dagcoin/response');
			case EnvironmentType.EASY_DIGITAL_DOWNLOADS:
				return wrapInFieldNode('?edd-listener=dagpay');
			case EnvironmentType.OPENCART:
				return wrapInFieldNode('?route=extension/payment/dagpay/callback');
			case EnvironmentType.PRESTASHOP:
				return wrapInFieldNode('?fc=module&module=dagpay&controller=callback');
		}
	};

	return (
		<View>
			{getEnvironmentTypeDescription(match.params.integrationType)}
			{(!isCreatable && !environment) || !wallets ? (
				<Loader />
			) : (
				<PanelNew>
					<FormProvider {...methods}>
						<ReactHookForm onSubmit={methods.handleSubmit(handleSaveEnvironment)} autoComplete="off">
							<PanelNew.Section first white panelType={PanelSectionType.FORM} separateWithBorder>
								<HookFormSeparator>Environment Details</HookFormSeparator>
								<HookFormSection>
									<HookFormColumn>
										<HookBaseField
											name="name"
											label="Environment name"
											placeholder="Custom title to recognize integration"
										/>
										<HookBaseField
											name="email"
											label="Payment notifications"
											optionalBubble
											placeholder="Email address"
										/>
									</HookFormColumn>
									<HookFormColumn>
										<HookSelectField
											name="walletId"
											label="Wallet for receiving payments"
											options={getWalletOptions(wallets, environment?.walletId)}
											selectedColor={Color.GRAY_2}
											focusedColor={Color.GRAY_0}
											isSearchable
										/>
									</HookFormColumn>
								</HookFormSection>
								<HookFormSection>
									<HookTextAreaField name="description" label="Description" rows={3} placeholder="Description" />
								</HookFormSection>
								<HookFormSeparator>Configure status & redirect URLs</HookFormSeparator>
								<HookFormSection>
									<Notification box noIcon left gray>
										<h1>Status URL</h1>
										HTTP endpoint to which DagPay server will POST updates about the invoice state changes. Required
										to be a https URL
									</Notification>

									{match.params.integrationType === EnvironmentType.CUSTOM ?  
										<HookBaseField
											name="statusUrl"
											label="Status URL (server to server secure status)"
											placeholder="https://example.com/status"
											onPaste={(event: React.ClipboardEvent) => {
												event.preventDefault();
												methods.setValue(
													'statusUrl',
													onStatusUrlPaste(event.clipboardData.getData('Text'), match.params.integrationType),
												);
											}}
											subLabel={chooseCorrectURLRightWrapper(match.params.integrationType)}
										/>
									: match.params.integrationType === EnvironmentType.WOOCOMMERCE ?  
										<HookBaseField
											name="statusUrl"
											label="Status URL (server to server secure status)"
											placeholder="https://example.com"
											onPaste={(event: React.ClipboardEvent) => {
												event.preventDefault();
												methods.setValue(
													'statusUrl',
													onStatusUrlPaste(event.clipboardData.getData('Text'), match.params.integrationType),
												);
											}}
											subLabel={chooseCorrectURLRightWrapper(match.params.integrationType)}
											subLabelClass="right"
											hookBaseWidth="half"
											onChange={changeMainUrl}
										/>
									: 									
										<HookBaseField
											name="statusUrl"
											label="Status URL (server to server secure status)"
											placeholder="https://example.com"
											onPaste={(event: React.ClipboardEvent) => {
												event.preventDefault();
												methods.setValue(
													'statusUrl',
													onStatusUrlPaste(event.clipboardData.getData('Text'), match.params.integrationType),
												);
											}}
											subLabel={chooseCorrectURLRightWrapper(match.params.integrationType)}
											subLabelClass="right"
											hookBaseWidth="half"
										/>
									}

									<Notification box noIcon left gray>
										<h1>Redirect URLs</h1>
										Return URLs for the browser to redirect back to from the payment view, depending on the final
										outcome of the transaction (can be the same for all states)
									</Notification>									
									{match.params.integrationType === EnvironmentType.WOOCOMMERCE && isCreatable ?  
										<HookBaseField
											name="redirectSuccessUrl"
											label="Browser redirect (SUCCESS)"
											placeholder="/checkout/order-received/"
											onPaste={(event: React.ClipboardEvent) => {
												event.preventDefault();
												methods.setValue(
													'redirectSuccessUrl',
													onStatusUrlPaste(event.clipboardData.getData('Text'), match.params.integrationType),
												);
											}}
											value={wooCommerceSuccess}
											onChange={e => setWooCommerceSuccess(e.target.value)}
										/>
									: 	<HookBaseField
											name="redirectSuccessUrl"
											label="Browser redirect (SUCCESS)"
											placeholder="https://example.com/api/success"
											onPaste={(event: React.ClipboardEvent) => {
												event.preventDefault();
												methods.setValue(
													'redirectSuccessUrl',
													onStatusUrlPaste(event.clipboardData.getData('Text'), match.params.integrationType),
												);
											}}
										/>
									}

									{match.params.integrationType === EnvironmentType.WOOCOMMERCE && isCreatable ?  
										<HookBaseField
											name="redirectCancelUrl"
											label="Browser redirect (CANCEL)"
											placeholder="/cancel"
											onPaste={(event: React.ClipboardEvent) => {
												event.preventDefault();
												methods.setValue(
													'redirectCancelUrl',
													onStatusUrlPaste(event.clipboardData.getData('Text'), match.params.integrationType),
												);
											}}
											value={wooCommerceCancel}
											onChange={e => setWooCommerceCancel(e.target.value)}
										/>
									: 	<HookBaseField
											name="redirectCancelUrl"
											label="Browser redirect (CANCEL)"
											placeholder="https://example.com/api/cancel"
											onPaste={(event: React.ClipboardEvent) => {
												event.preventDefault();
												methods.setValue(
													'redirectCancelUrl',
													onStatusUrlPaste(event.clipboardData.getData('Text'), match.params.integrationType),
												);
											}}
										/>
									}

									{match.params.integrationType === EnvironmentType.WOOCOMMERCE && isCreatable ?  
										<HookBaseField
											name="redirectFailUrl"
											label="Browser redirect (FAIL)"
											placeholder="/fail"
											onPaste={(event: React.ClipboardEvent) => {
												event.preventDefault();
												methods.setValue(
													'redirectFailUrl',
													onStatusUrlPaste(event.clipboardData.getData('Text'), match.params.integrationType),
												);
											}}
											value={wooCommerceFail}
											onChange={e => setWooCommerceFail(e.target.value)}
										/>
									: 	<HookBaseField
											name="redirectFailUrl"
											label="Browser redirect (FAIL)"
											placeholder="https://example.com/api/fail"
											onPaste={(event: React.ClipboardEvent) => {
												event.preventDefault();
												methods.setValue(
													'redirectFailUrl',
													onStatusUrlPaste(event.clipboardData.getData('Text'), match.params.integrationType),
												);
											}}
										/>
									}
								</HookFormSection>
								<Notification gray>
									For more information, refer to{' '}
									<a href={RoutesUrls.DOCUMENTATION} target="_blank" rel="noopener noreferrer">
										API integration documentation
									</a>{' '}
								</Notification>
								{!isCreatable && (
									<>
										<HookFormSeparator>Your web platform configuration details</HookFormSeparator>
										<HookFormSection>
											<Notification gray>
												Use the following API credentials to accept Dagcoin payments on your website.
											</Notification>
											<HookBaseField name="environmentId" label="Environment ID" disabled />
											<HookBaseField name="userId" label="User ID" disabled />
											<HookBaseField name="secret" label="Secret" disabled />
										</HookFormSection>
									</>
								)}
							</PanelNew.Section>

							<PanelNew.Section last gray panelType={PanelSectionType.BUTTON}>
								<PreviousLink
									title="Back"
									to={isCreatable ? RoutesUrls.CHOOSE_ENVIRONMENT_TYPE : RoutesUrls.ENVIRONMENTS}
								/>
								<PanelNew.ActionButtonsWrapper>
									<Button.Secondary
										green
										type={ButtonTypes.SUBMIT}
										isDisabled={isActionLoading || !methods.formState.isDirty}
										tabIndex={9}
										onClick={methods.handleSubmit(handleSaveEnvironment)}
									>
										{!isActionLoading && isCreatable ? 'Create environment' : !isCreatable ? 'Update environment' : <Loader size={20} color={Color.WHITE} width={120.5} />}
									</Button.Secondary>
								</PanelNew.ActionButtonsWrapper>
							</PanelNew.Section>

							{getCreateModal()}
						</ReactHookForm>
					</FormProvider>
				</PanelNew>
			)}
		</View>
	);
}

function getModifiedStatusUrl(statusUrl: string, integrationType: EnvironmentType) {
	return statusUrl && integrationType !== EnvironmentType.CUSTOM
		? trimSlash(
				statusUrl
					.replace('http://', 'https://')
					.replace('/dagcoin/response', '')
					.replace('?wc-api=dagcoin_handler', '')
					.replace('?edd-listener=dagpay', '')
					.replace('?route=extension/payment/dagpay/callback', '')
					.replace('?fc=module&module=dagpay&controller=callback', ''),
		  )
		: statusUrl;
}

const onStatusUrlPaste = (pastedURL: string, integrationType: EnvironmentType) => {
	const modifiedStatusUrl =
		pastedURL && integrationType !== EnvironmentType.CUSTOM
			? trimSlash(
					pastedURL
						.replace('http://', 'https://')
						.replace('/dagcoin/response', '')
						.replace('?wc-api=dagcoin_handler', '')
						.replace('?edd-listener=dagpay', '')
						.replace('?route=extension/payment/dagpay/callback', '')
						.replace('?fc=module&module=dagpay&controller=callback', ''),
			  )
			: pastedURL;

	return modifiedStatusUrl;
};

function trimSlash(string: string) {
	if (string.substr(-1) === '/') {
		return string.substr(0, string.length - 1);
	}

	return string;
}

function getEnvironmentTypeDescription(integrationType: EnvironmentType) {
	const descriptions = {
		WOOCOMMERCE: {
			name: 'WooCommerce',
			help: 'https://help.dagpay.io/en/articles/294044-woocommerce-plugin-integration-guide',
			download: 'https://wordpress.org/plugins/dagpay-for-woocommerce/',
		},
		MAGENTO: {
			name: 'Magento',
			help: 'https://help.dagpay.io/en/articles/294034-magento-2-extension-integration-guide',
			download: 'https://marketplace.magento.com/dagpay-magento-plugin.html',
		},
		OPENCART: {
			name: 'OpenCart',
			help: 'https://help.dagpay.io/en/articles/294029-opencart-extension-integration-guide',
			download: 'https://www.opencart.com/index.php?route=marketplace/extension/info&extension_id=37845',
		},
		PRESTASHOP: {
			name: 'PrestaShop',
			help: 'https://help.dagpay.io/en/articles/294024-prestashop-module-integration-guide',
			download: 'https://github.com/dagpay/prestashop-dagpay/releases/download/v1.0.0/dagpay.zip',
		},
		EASY_DIGITAL_DOWNLOADS: {
			name: 'Easy Digital Downloads',
			help: 'https://help.dagpay.io/en/articles/294039-easy-digital-downloads-plugin-integration-guide',
			download: 'https://wordpress.org/plugins/dagpay-for-easy-digital-downloads/',
		},
		CUSTOM: {
			name: 'Custom',
			help: RoutesUrls.DOCUMENTATION,
			download: '',
		},
	};

	return (
		<PluginHeader>
			<H1>{descriptions[integrationType].name} Dagpay plugin integration</H1>
			<PluginDescription>
				<p>
					Refer to the{' '}
					<a href={descriptions[integrationType].help} target="_blank" rel="noopener noreferrer">
						{descriptions[integrationType].name !== 'Custom'
							? `${descriptions[integrationType].name} Dagpay plugin integration`
							: 'API integration documentation'}
					</a>{' '}
					instructions to get started with Dagpay for your website.
				</p>
				{descriptions[integrationType].download !== '' ? (
					<DownloadPluginButton target="_blank" rel="noopener noreferrer" href={descriptions[integrationType].download}>
						<ReactSVG src="/files/svg/icons/DownloadPlugin.svg" />
						Download Plugin
					</DownloadPluginButton>
				) : null}
			</PluginDescription>
		</PluginHeader>
	);
}

export default withRouter(EnvironmentsDetailView);
