import styled, { css, keyframes } from 'styled-components/macro';

import Button from '../../../../components/button/Button';
import { Color, Shadow } from '../../../../gfx/constants';
import { media } from '../../../../services/media';
import { ContentWrapper } from '../DagcoinCard/DagcoinCardContentStyle';

export const MobileSidebar = styled.div`
	background: ${Color.BLUE_8};
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	min-width: 272px;
	height: 100vh;
	height: 100vh;
	color: ${Color.WHITE};
	padding: 59px 25px 25px;
	overflow: hidden;
`;

export const HelpLink = styled.a`
	display: flex;
	padding-top: 7px;
	cursor: pointer;
	text-decoration: none;

	span {
		margin-right: 6px;
		color: ${Color.WHITE};
		height: 24px;
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
	}
`;

export const HelpIcon = styled.div<{ hasWhiteBackground?: boolean }>`
	height: 18px;
	width: 18px;
	border-radius: 15px;
	text-align: center;

	${(props) =>
		props.hasWhiteBackground &&
		css`
			div div svg path {
				fill: ${Color.GRAY_7};
			}
		`}
`;

export const Header = styled.div<{ color?: string }>`
	display: flex;
	justify-content: space-between;
	position: relative;
	width: 100%;
	margin-bottom: auto;

	${(props) =>
		props.color &&
		css`
			div div svg g path {
				fill: ${props.color};
			}
			div div svg g rect {
				stroke: ${props.color};
			}
		`}

	${HelpLink} {
		a {
			${(props) =>
				props.color &&
				css`
					color: ${props.color};
				`}
		}
	}

	${HelpIcon} {
		${(props) =>
			props.color &&
			css`
				div div svg path {
					fill: ${props.color};
				}
			`}
	}
`;

export const HeaderBlockIconWrapper = styled.div`
	position: relative;
	width: 127px;
	height: 45px;

	div div svg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
`;

export const Expiration = styled.div`
	display: flex;
	width: 100%;
	max-width: 450px;
	min-width: 250px;
	justify-content: space-between;
	margin-top: 18px;
`;

export const AwaitingText = styled.div`
	display: flex;
	align-items: center;
	height: 18px;
	color: ${Color.WHITE};
	font-size: 12px;
	letter-spacing: 0.07px;
	line-height: 16px;

	> div {
		margin-right: 5px;
	}
`;

export const ExpiresText = styled.div`
	display: flex;
	align-items: center;
	height: 18px;
	font-size: 12px;
	letter-spacing: 0.07px;
	line-height: 14px;
	text-align: right;
`;

export const DetailsWrapper = styled.div<{ bottomBorder?: boolean }>`
	padding: 20px 20px 0;

	${(props) =>
		props.bottomBorder &&
		css`
			border-bottom: 1px solid ${Color.OPTIONAL_TEXT};
		`}
`;

export const PaymentDetails = styled.div`
	display: flex;
	flex-direction: column;
	height: auto;
	width: 100%;
	max-width: 450px;
	min-width: 250px;
	font-size: 12px;
	letter-spacing: 0.07px;
	line-height: 14px;
	border: 1px solid ${Color.OPTIONAL_TEXT};
	border-radius: 7px;
	margin-top: 13px;
`;

export const PaymentDetailRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	font-size: 12px;
	letter-spacing: 0.07px;
	line-height: 14px;
	margin-bottom: 20px;
	align-items: center;
`;

export const PaymentDetailTitle = styled.div`
	display: flex;
	font-size: 12px;
	letter-spacing: 0.07px;
	line-height: 14px;
	justify-content: center;
	align-items: center;

	svg {
		height: 18px;
		width: 18px;
	}
`;

export const Title = styled.div`
	padding-left: 10px;
	font-size: 12px;
	letter-spacing: 0.07px;
	line-height: 14px;
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	${media.maxMobile`
	max-width: 100px;

	`}
`;

export const CoinAndCurrencyBlock = styled.div`
	display: flex;
	flex-direction: column;
`;

export const DagAmount = styled.div`
	display: flex;
	font-size: 12px;
	letter-spacing: 0.07px;
	line-height: 14px;
	align-items: center;
`;

export const CurrencyAmount = styled.div`
	display: flex;
	justify-content: flex-end;
	font-size: 12px;
	letter-spacing: 0.07px;
	line-height: 7px;
	opacity: 0.65;
`;

export const CopyInvoiceId = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 44px;
	font-size: 14px;
	color: #00bb68;
	font-weight: 500;
	line-height: 16px;
	cursor: pointer;

	div div svg path {
		margin-left: 5px;
		fill: #00bb68;
	}

	div div svg {
		margin-left: 5px;
	}
`;

const fadeAnimation = keyframes`
   0% { opacity: 0; }
   15% { opacity: 1; }
   85% { opacity: 1; }
   100% { opacity: 0; }
`;

export const MobilePopUpWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 34px;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${Color.ORANGE};
	color: ${Color.WHITE};
	animation: ${fadeAnimation};
	animation-duration: 2s;
	animation-iteration-count: 1;
	pointer-events: none;
`;

export const PopUpMessage = styled.div`
	font-size: 12px;
	line-height: 16px;
`;

export const TapToScan = styled.div`
	display: flex;
	flex-direction: column;
	height: 87px;
	margin: 22px auto 30px;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
`;

export const ScanText = styled.div`
	font-size: 14px;
	line-height: 16px;
	color: #ffffff;
	font-weight: 200;
`;

export const CardSlideWrapper = styled.div<{ slide?: boolean }>`
	position: absolute;
	overflow: hidden;
	z-index: 3;
	display: flex;
	align-items: center;
	top: 0;
	width: 100vw;
	max-width: 0;
	height: 100vh;
	background-color: ${Color.WHITE};
	/* min-width: 272px; */
	left: 100%;
	transition-property: all;
	transition-duration: 0.5s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

	${ContentWrapper} {
		align-items: center;
		justify-content: center;
	}

	${(props) =>
		props.slide &&
		css`
			max-width: 100%;
			left: 0;
		`}
`;

export const ModalInfoWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
`;

export const ModalTitle = styled.div`
	position: relative;
	height: 32px;
	color: #0b132b;
	font-weight: bold;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 10px;
`;

export const ModalMessage = styled.div`
	position: relative;
	color: #444444;
	text-align: center;
	font-size: 16px;
	line-height: 21px;
	margin: 0 15px 10px;
`;

export const ModalBackButton = styled(Button)`
	position: relative;
	background-color: ${Color.BLUE_8};
	margin: 20px 0 auto;
	width: 100%;

	:hover {
		box-shadow: ${Shadow.BLUE};
	}
`;

export const PayWithCardButton = styled(Button)<{ disabled: boolean }>`
	width: 100%;
	max-width: 450px;
	min-width: 250px;
	margin-bottom: auto;
	align-self: center;
	color: ${Color.WHITE};

	${(props) =>
		props.disabled &&
		css`
			visibility: hidden;
		`}
`;

export const CancelWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
`;

export const MobileCancelPayment = styled.div`
	cursor: pointer;
	color: ${Color.WHITE};
	text-decoration: underline;

	&:hover {
		color: ${Color.RED_2};
	}
`;
