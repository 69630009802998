import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

const activeClassName = 'nav-item-active';

export const PrivateMobileMenu = styled.div`
	display: none;

	${media.maxDesktop`
		display: inline-block !important;
		width: 100%;
		padding-top: 40px;
		align-self: flex-end;
		margin-top: auto;
		position: relative;

		::before {
			content: " ";
			background-color: rgba(215, 221, 235, 0.2);
			position: absolute;
			top: 30px;
			left: 0;
			right: 0;
			width: 100%;
			height: 1px;
		}
	`}
`;

export const PrivateMobileMenuItem = styled.div`
	width: 100%;
	text-align: left;

	a {
		font-size: 18px;
		color: ${Color.WHITE};
		position: relative;
		text-decoration: none;
		width: 100%;
		display: inline-block;
		border-radius: 6px;
		margin: 5px 0px;
		padding-left: 56px;
		height: 48px;
		line-height: 48px;
	}

	a:hover {
		background-color: rgba(186, 188, 195, 0.1);
	}

	svg {
		position: absolute;
		left: 16px;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	svg path {
		fill: ${Color.WHITE};
	}
`;

export const ArrowDownIcon = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	transition: 0.3s;

	svg {
		height: 9px;
		position: relative;
	}
`;

export const PrivateSidebarLogo = styled.div`
	display: flex;
	align-items: center;
	padding: 25px;
	justify-content: center;
	text-align: left;

	${media.maxDesktop`
			display: none;
		`}

	${media.maxSize1400`
			justify-content: flex-start;
			margin-left: -8px;

			svg g g path {
				opacity: 1;
			}

			svg path {
				opacity: 0;
				transition: 0.2s;
			}
		`}
`;

export const PrivateSidebarNavText = styled.div`
	color: ${Color.WHITE};
	position: relative;

	${media.maxSize1400`
			opacity: 0;
			transition: 0.1s;
		`}
`;

export const PrivateSidebarNavHasSub = styled.div`
	margin: 0px;
	padding: 0px;
	list-style: none;
`;

export const PrivateSidebarSub = styled.div`
	margin: 0px;
	padding: 0px;
	list-style: none;
`;

export const PrivateSidebarSubList = styled.div`
	margin: 0px;
	padding: 0px;
	list-style: none;
	display: none;
	&.${activeClassName} {
		background-color: rgba(186, 188, 195, 0.1);
	}
`;

export const PrivateSidebarSubMenuItem = styled.div`
	${media.maxSize1400`
		display: none;
		opacity: 0;
	`}

	div {
		padding-left: 38px;
	}

	transition: 0.3s;
	display: inline-block;
	width: 100%;
`;

export const StickyWrapper = styled.div`
	height: 100%;
`;

export const PrivateSidebarNavigation = styled.nav`
	padding-top: 65px;
	padding-bottom: 60px;
	display: inline-block;
	width: 100%;
`;

export const PrivateSidebarNavLink = styled(NavLink).attrs({ activeClassName })`
	align-items: center;
	font-weight: 400;
	margin: 0px 18px;
	height: 48px;
	color: ${Color.WHITE};
	margin-top: 12px;
	display: inline-block;
	width: calc(100% - 35px);
	font-size: 15px;
	border-radius: 6px;
	justify-content: left;
	padding-left: 32px;
	padding-right: 12px;
	line-height: 48px;
	text-decoration: none;
	float: left;

	&:hover {
		background-color: rgba(186, 188, 195, 0.1);
	}
	&.${activeClassName} {
		background-color: ${Color.GREEN_3};
	}

	&.active_dropdown ${ArrowDownIcon} {
		transform: rotate(180deg);
	}

	&.active_dropdown div:nth-child(1) path {
		fill: ${Color.GREEN_3};
	}

	&.active_dropdown ${ArrowDownIcon} path {
		stroke: ${Color.GREEN_3};
		fill: ${Color.GREEN_3};
	}

	&.active_dropdown ${PrivateSidebarNavText} {
		color: ${Color.GREEN_3};
	}

	${media.maxSize1400`
		padding-left: 20px;
		padding-right: 20px;
		margin: 0px 10px;
		margin-top: 12px;
		max-width: 240px;
		width: calc(100% - 20px);

			&.${activeClassName} ~ ${PrivateSidebarSubList} {
				display: none;
			}
		`}

	&.${activeClassName} ~ ${PrivateSidebarSubList} {
		display: block;

		${media.maxSize1400`
			display: none;
		`}
	}
`;

export const PrivateSidebarVersion = styled.div`
	position: absolute;
	left: 50%;
	bottom: 10px;
	transform: translateX(-50%);
	opacity: 0.6;

	${media.maxSize1400`
		display: none;
	`}
`;

export const PrivateSidebarIcon = styled.div`
	margin-right: 20px;
	min-width: 18px;
	float: left;

	div {
		margin: auto;
		display: flex;
		justify-content: center;
		height: 48px;
		align-items: center;
	}
`;

export const StyledPrivateSidebar = styled.div`
	position: relative;
	max-width: 260px;
	width: 100%;
	overflow: hidden;
	padding-bottom: 50px;
	background: ${Color.BLUE_9};
	color: ${Color.WHITE};

	${media.maxSize1400`
		max-width: 76px;
		min-width: 76px;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 999;
		height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
		
		::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none;
		scrollbar-width: none;
		transition: 0.5s;
		
		&:hover {
			min-width: 260px;
		}
		
		&:hover ${PrivateSidebarNavText} {
			opacity: 1;
			transition: 0.3s;
			min-width: 200px;
		}

		&:hover ${PrivateSidebarLogo} {
			justify-content: center;
			margin-left: 0px;
		}

		&:hover ${PrivateSidebarLogo} path {
			opacity: 1;
		} 

		&.${PrivateSidebarNavText} {
			display: none;
		}

		&:hover ${PrivateSidebarNavText} {
			display: block;
		}
		
		&:hover ${PrivateSidebarSubMenuItem} {
			display: block;
			opacity: 1;
		}
		`}

	${media.maxDesktop`
			display: none;

		${PrivateSidebarNavLink} {
			max-width: 100%;
			min-width: 100%;
			margin: 5px 0px;
		}

		${PrivateSidebarNavText} {
			opacity: 1;
			text-align: left;
			font-size: 18px;
		}

		${PrivateSidebarSubMenuItem} {
			opacity: 1;
		}

		${PrivateSidebarLogo} {
			display: none;
		}

		${PrivateSidebarVersion} {
			display: none;
		}

		${PrivateSidebarNavigation} {
			padding-top: 32px;
			padding-bottom: 20px;
			display: inline-block;
			width: 100%;
			min-height: calc(100vh - 100px);
			display: flex;
			flex-direction: column;
		}
		${PrivateSidebarNavigation}:before {
			content: " ";
			background-color: rgba(215, 221, 235, 0.2);
			position: absolute;
			top: 9px;
			left: 0;
			right: 0;
			width: 100%;
			height: 1px;
		}

		height: calc(100% - 62px);
		margin: 62px 20px 0px 20px;
		min-width: calc(100% - 40px);
		width: 100%;

		&:hover {
			min-width: calc(100% - 40px);
		}

		${PrivateSidebarIcon} svg {
			font-size: 20px;
			transform: scale(1.3);
		}
	`}
`;
