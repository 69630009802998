import React from 'react';

import { Rates } from '../../api/ratesApi';
import { Currency } from '../../constants';
import { roundCoinAmount } from '../../services/round-coin-amount';
import InvoicePrice from '../invoice-price/InvoicePrice';

interface Props {
	tax: string | number;
	currencyAmount: string | number;
	rates: Rates | null;
	currency: Currency | null;
}

export default function CalculatedInvoiceTotal(props: Props) {
	const { tax, currencyAmount, rates, currency } = props;

	const floatCurrencyAmount = parseFloat(currencyAmount.toString());
	const floatTax = parseFloat(tax.toString());

	const taxAmount = floatTax > 0 ? (floatTax / 100) * floatCurrencyAmount : 0;
	const totalWithTax = floatCurrencyAmount + taxAmount;
	let eurRate = 0;
	let conversionRate = 0;
	let dagAmount = 0;
	let conversionAmount = 0;

	if (rates && currency === Currency.DAG) {
		eurRate = rates.EUR;
		dagAmount = roundCoinAmount(totalWithTax);
		conversionAmount = roundCoinAmount(totalWithTax * eurRate);

		return <InvoicePrice currency={Currency.EUR} currencyAmount={conversionAmount} coinAmount={dagAmount} />;
	}

	if (rates && currency !== Currency.DAG) {
		if (currency !== null) {
			conversionRate = rates[currency as keyof Rates];
		}

		dagAmount = roundCoinAmount(totalWithTax / conversionRate);
		conversionAmount = roundCoinAmount(totalWithTax);

		if (currency) {
			return <InvoicePrice currency={currency} currencyAmount={conversionAmount} coinAmount={dagAmount} />;
		}
	}

	return <span>Not defined</span>;
}
