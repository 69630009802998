import styled from 'styled-components/macro';

import RadioButtonGroup from '../../../../components/radio-group/RadioGroup';
import { Color, Gutter, Shadow } from '../../../../gfx/constants';
import { SubtextDescriptionTextMixin } from '../../../../gfx/globals';
import { media } from '../../../../services/media';

interface UsersFilterLinkProps {
	active: boolean;
}

export const RotateArrow = styled.div`
	display: inline-block;
`;

export const RadioWrapper = styled.div`
	background: ${Color.GRAY_1};
	border-radius: 4px;
	margin-bottom: 30px;

	div {
		margin: 0px;
		color: ${Color.GRAY_9};
	}

	label div:first-of-type {
		border: none;
		background-color: transparent;
		cursor: pointer;
	}
`;

export const ModalBottomWrap = styled.div`
	div {
		margin: 0px;
		display: inline-block;
		width: 100%;
		color: ${Color.GRAY_9};
	}

	button {
		float: right;
	}
`;

export const InviteWrap = styled.div`
	label,
	p {
		color: ${Color.GRAY_9};
		font-size: 14px;
	}

	.radio-group {
		background: ${Color.GRAY_1};
		border-radius: 4px;
		padding: 30px 24px;
	}

	.radio-group label {
		line-height: 16px;
	}

	.radio-group p {
		line-height: 21px;
		padding-left: 35px;
		padding-top: 5px;
		font-weight: 400;
	}
`;

export const CloseInvitationModal = styled.div`
	position: absolute;
	width: 20px;
	height: 20px;
	cursor: pointer;
	right: 20px;
	top: 40px;

	&:after {
		content: ' ';
		background-color: ${Color.BEIGE};
		width: 15px;
		height: 2px;
		position: absolute;
		top: 0;
		left: 0;
		transform: rotate(45deg);
	}

	&:before {
		content: ' ';
		background-color: ${Color.BEIGE};
		width: 15px;
		height: 2px;
		position: absolute;
		top: 0;
		left: 0;
		transform: rotate(-45deg);
	}
`;

export const UserName = styled.p`
	font-family: PrimaryMedium;
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 550px;
	margin: auto;

	@media (max-width: 1300px) {
		max-width: 280px;
	}
	@media (max-width: 900px) {
		max-width: 180px;
	}
`;

export const UserEmail = styled.p`
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 550px;
	margin: auto;

	@media (max-width: 1300px) {
		max-width: 280px;
	}

	@media (max-width: 900px) {
		max-width: 180px;
	}
`;

export const UsersFilterLink = styled.a<UsersFilterLinkProps>`
	font-family: ${({ active }) => (active ? 'PrimaryMedium' : 'Primary')};
	color: ${Color.GRAY_8};
	padding-bottom: 10px;
	text-align: center;
	display: flex;

	&:hover {
		cursor: pointer;
		text-decoration: none;
	}
`;

export const UsersFilters = styled.nav`
	display: flex;
	font-family: Secondary;
	font-size: 15px;
	width: 100%;

	a {
		:not(:first-child) {
			margin-left: 20px;
		}

		${Color.GRAY_9}
		display: flex;
		align-items: center;
		justify-content: center;

		:last-of-type div {
			color: ${Color.GRAY_8};
		}
	}

	${media.maxDesktop`
		display: none;
		flex-wrap: wrap;
		justify-content: center;
		background: ${Color.WHITE};
		border: 1px solid ${Color.GRAY_5};
		box-shadow: ${Shadow.LIGHT};
		border-radius: 4px;
		position: absolute;
		top: 55px;
		left: 0px;
		z-index: 1;
	`}
`;

export const UserFilterMobile = styled.nav`
	display: none;

	${media.maxDesktop`
		display: block;

		${UsersFilters} {
			display: flex;
		}
	`}
`;

export const UsersFilterLinkForMobile = styled.a`
	width: 100%;
	padding: 0px 20px;
	font-size: 16px;
	border-bottom: 1px solid ${Color.GRAY_5};
	color: ${Color.GRAY_9};

	:last-of-type,
	:hover:last-of-type {
		border-bottom: none;
	}

	:hover {
		border-bottom: 1px solid ${Color.GRAY_5};
	}

	:last-of-type div {
		color: ${Color.GRAY_8};
	}
`;

export const UserFilterCurrent = styled.div`
	width: 100%;
	background: ${Color.WHITE};
	border: 1px solid ${Color.GRAY_5};
	box-shadow: ${Shadow.LIGHT};
	border-radius: 4px;
	height: 48px;
	flex-wrap: wrap;
	display: flex;
	line-height: 48px;
	padding: 0px 20px;
	cursor: pointer;

	a {
		font-family: 'Primary';
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 0px !important;
	}

	svg {
		position: absolute;
		right: 13px;
		top: 0;
		bottom: 0;
		margin: auto;
		transform: rotate(-90deg);
		height: 14px;
		width: 14px;

		path {
			fill: ${Color.GRAY_6};
		}
	}
	${RotateArrow} svg {
		transform: rotate(90deg);
	}
`;

export const UserFilterWrapper = styled.div`
	width: auto;
	position: relative;
	float: left;

	${media.maxDesktop`
		width: 100%;
	`}
`;

export const AddUserRadioGroup = styled(RadioButtonGroup)`
	margin-bottom: ${Gutter.LARGE};
`;

export const NavBarWrapper = styled.div`
	width: 100%;
	padding-bottom: 12px;
	border-bottom: 1px solid ${Color.GRAY_5};

	button {
		float: right;
		margin-top: -10px;
	}

	${media.maxDesktop`
		padding-bottom: 18px;
		margin-bottom: 10px;
		display: flex;
		flex-direction: column-reverse;

		button {
			float: left;
			margin-top: 0px;
			margin-bottom: 20px;
			margin-right: auto;
		}
	`}
`;

export const InviteUserConfirmationWrapper = styled.div`
	width: 100%;
`;

export const InviteUserConfirmationInnerWrapper = styled.div`
	display: inline-block;

	div {
		margin-bottom: 0px;
	}

	${media.maxTablet`
		max-width: calc(100% - 1px);
	`}
`;

export const UserStatusBulletList = styled.div`
	display: flex;
	justify-content: center;
	${SubtextDescriptionTextMixin}
	line-height: 24px;
`;

export const BulletMarker = styled.div<{ color: Color }>`
	border-radius: 5px;
	background-color: ${(props) => props.color};
	color: ${Color.WHITE};
	height: 24px;
	min-width: 40px;
	line-height: 24px;
	text-align: center;
	margin: 0 10px;
	float: left;
	user-select: none;
`;

export const ModalFooter = styled.div`
	div {
		margin-top: 0px;
		display: block;
	}

	button {
		float: right;
	}
`;

export const UserManagement = styled.div`
	width: 100%;

	${media.maxTablet`
		tr {
			position: relative;
			display: flex;
			flex-direction: column;
		}

		tr td {
			position: unset;
		}

		tr td:nth-child(1) {
			order: 1;
			padding-top: 20px !important;
		}

		tr td:nth-child(2) {
			order: 2;
			padding: 2px 20px;
		}

		tr td:nth-child(2) div {
			display: block;
			line-height: 14px;
			text-align: right;
		}

		tr td:nth-child(3) {
			order: 4;
			border-bottom-style: solid;
    		border-radius: 0 0 4px 4px;
			padding-bottom: 20px;
		}

		tr td:nth-child(4) {
			order: 3;
		}

		tr td:nth-child(5) {
			order: 5;
			position: absolute;
			top: 10px;
			right: 2px;
			padding: 0px;
			border: none;
		}

		tr td:nth-child(5) div:after {
			display: none;
		}

		td:nth-child(even) div {
			background: ${Color.WHITE} !important;
		}

		td:nth-child(4) div {
			background: ${Color.GRAY_1} !important;
			padding: 12px 14px !Important;
		}
	`}
`;
