import * as React from 'react';
import { Control, useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components/macro';
import { Color } from '../../gfx/constants';

export interface RadioSelectOption {
	value: any;
	label: string;
	description?: string;
	isActive?: boolean;
}

interface Props {
	name: string;
	label?: string;
	control: Control;
	options: RadioSelectOption[];
	disabled?: boolean;
	tabIndex?: number;
	children?: React.ReactNode;
	className?: string;
	isLeftAligned?: boolean;
	width?: number;
	isCentered?: boolean;
	isGreen?: boolean;
	marginBottom?: number;
	verifyIdentityView?: boolean;
}

interface RadioWrapperProps {
	marginBottom?: number;
}

interface RadioWrapProps {
	isLeftAligned?: boolean;
}

interface CheckMarkProps {
	isActive?: boolean;
	isGreen?: boolean;
}

interface RadioBlockProps {
	isActive?: boolean;
	isFlex?: boolean;
	verifyIdentityView?: boolean;
}

interface RadioContentProps {
	width?: number;
}

interface RadioLabelProps {
	isCentered?: boolean;
}

const RadioWrap = styled.div<RadioWrapProps>`
	width: ${({ isLeftAligned }) => !isLeftAligned && '100%'};

	label {
		cursor: pointer;
		user-select: none;
		position: relative;
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}
`;

const RadioLabel = styled.div<RadioLabelProps>`
	display: flex;
	align-items: center;
	justify-content: ${({ isCentered }) => isCentered && 'center'};
	font-family: PrimaryMedium;
`;

const RadioContent = styled.div<RadioContentProps>`
	display: inline-block;
	width: ${({ width }) => width && `${width}px`};
	p {
		color: ${Color.GRAY_9};
		padding-left: 36px;
	}
`;

const RadioWithLabel = styled.div`
	position: relative;
	padding-left: 36px;
	line-height: 24px;

	@media (max-width: 1024px) {
		padding-top: 10px;
		padding-bottom: 10px;
	}
`;

const RadioBlock = styled.div<RadioBlockProps>`
	display: ${({ isFlex }) => isFlex && 'flex'};
	align-items: ${({ isFlex }) => isFlex && 'center'};
	border: 1px solid ${Color.WHITE};
	border-radius: 5px;
	padding: 22px;
	width: ${({ isFlex, verifyIdentityView }) => {
		if (verifyIdentityView) {
			return 'auto';
		}
		if (!isFlex) {
			return '100%';
		}

		return 'auto';
	}};
	&:focus-within {
		background-color: #f0f8fd;
		border: 1px solid ${Color.BLUE_1};
		color: ${Color.BLUE_1};
	}
	${(props) =>
		props.isActive &&
		css`
			background-color: #f0f8fd;
			border: 1px solid ${Color.BLUE_1};
			color: ${Color.BLUE_1};
		`}
`;

const CheckMark = styled.div<CheckMarkProps>`
	position: absolute;
	top: 50%;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: transparent;
	border-radius: 50%;
	border:	2px solid ${({ isActive, isGreen }) => (isActive && !isGreen ? `${Color.BLUE_2}` : isActive && isGreen ? `${Color.GREEN_3}` : `${Color.GRAY_5}`)} !important;
	transform: translateY(-50%);
	::before {
		content: '';
		height: 10px;
		width: 10px;
		position: absolute;
		background-color: ${({ isActive, isGreen }) => (isActive && !isGreen ? `${Color.BLUE_2}` : isActive && isGreen ? `${Color.GREEN_2}`: 'transparent')};
		border-radius: 50%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

export const StyledRadioButton = styled.input`
	position: absolute;
	appearance: none;
	outline: none;
	top: 50%;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: transparent;
	margin-right: 12px;
	transform: translateY(-50%);
`;

const RadioWrapper = styled.div<RadioWrapperProps>`
	margin: 22px auto;
	${(props) =>
		props.marginBottom &&
		css`
			margin-bottom: ${props.marginBottom}px;
		`}
`;

export default function HookRadioField(props: Props) {
	const { name, disabled, options, isLeftAligned, width, isCentered, isGreen, marginBottom } = props;
	const { register } = useFormContext();
	return (
		<RadioWrapper marginBottom={marginBottom}>
			{options.map((option, index) => {
				return (
					<label key={index}>
						<RadioBlock verifyIdentityView isActive={option.isActive}>
							<RadioWrap isLeftAligned={isLeftAligned}>
								<RadioContent width={width}>
									<RadioWithLabel>
										<StyledRadioButton
											type="radio"
											title={option.label}
											value={option.value}
											disabled={disabled}
											ref={register}
											name={name}
										/>
										<RadioLabel isCentered={isCentered}>
											{<CheckMark isActive={option.isActive} isGreen={isGreen}/>}
											{option.label}
										</RadioLabel>
									</RadioWithLabel>
									<p>{option.description}</p>
								</RadioContent>
							</RadioWrap>
						</RadioBlock>
					</label>
				);
			})}
		</RadioWrapper>
	);
}
