import * as React from 'react';
import ReactSVG from 'react-svg';
import DashboardSection from '../../../components/dashboard-section/DashboardSection';
import View from '../../../components/view/View';
import WithPermission from '../../../components/with-permission/WithPermission';
import { RoutesUrls, Scopes, VerificationState } from '../../../constants';
import { ColumnedFlex, H1, JustifiedLeftFlex } from '../../../gfx/globals';
import { GoBackLink, GoBackText } from '../../public/sign-up/FillDetailsStep';
import { PreviousWrapper } from '../../public/sign-up/SignUpViewStyle';
import { BusinessVerificationViewDescription } from './VerifyStyle';

interface Props {
	tier2VerificationStatus: null | VerificationState | undefined;
	tier3VerificationStatus: null | VerificationState | undefined;
	isBusinessVerified: boolean;
}

export enum DashboardIconPath {
	SUCCESS = '/files/svg/private/Success.svg',
	EMAIL_VERIFICATION = '/files/svg/private/dashboard/EmailVerification.svg',
	PENDING = '/files/svg/private/dashboard/Pending.svg',
	FAIL = '/files/svg/private/dashboard/Fail.svg',
	IDENTITY_VERIFICATION = '/files/svg/private/dashboard/IdentityVerification.svg',
	BUSINESS_VERIFICATION = '/files/svg/private/dashboard/BusinessVerification.svg',
	ADD_WALLET = '/files/svg/private/dashboard/AddWallet.svg',
	MERCHANT_FINDER = '/files/svg/private/dashboard/MerchantFinder.svg',
	INFO = '/files/svg/private/Info.svg',
}

export default function VerifyBusinessTiersTpl(props: Props) {
	function getBusinessTier2Section() {
		const { tier2VerificationStatus } = props;

		switch (tier2VerificationStatus) {
			case VerificationState.APPROVED:
				return (
					<DashboardSection
						icon={DashboardIconPath.SUCCESS}
						title="Tier 2 (business address proof) verified!"
						text="Your business address has been successfully verified!"
					/>
				);

			case VerificationState.PENDING:
				return (
					<DashboardSection
						icon={DashboardIconPath.PENDING}
						title="Tier 2 (business address proof) verification pending"
						text="You will receive an email regarding the status of your business address verification. This may take up to 48h in most cases"
					/>
				);

			case VerificationState.REJECTED:
				return (
					<DashboardSection
						icon={DashboardIconPath.FAIL}
						title="Tier 2 (business address proof) verification rejected"
						text="Your business address proof has been rejected. Please review your submission to learn more about the reason for rejection"
						link={RoutesUrls.VERIFY_BUSINESS_ADDRESS_DOCUMENTS}
					/>
				);

			default:
				return (
					<DashboardSection
						icon={DashboardIconPath.MERCHANT_FINDER}
						title="Tier 2 (business address proof) verification"
						text="Required for Tier 2 - up to 10 000€/mo volume"
						link={RoutesUrls.VERIFY_BUSINESS_ADDRESS_DOCUMENTS}
					/>
				);
		}
	}
	function getBusinessTier3Section() {
		const { tier2VerificationStatus, tier3VerificationStatus } = props;

		if (!tier2VerificationStatus) {
			return (
				<DashboardSection
					icon={DashboardIconPath.BUSINESS_VERIFICATION}
					title="Tier 3 (additional business proof) verification"
					text="Please fill out a tier 2 business address application first"
				/>
			);
		}
		switch (tier3VerificationStatus) {
			case VerificationState.APPROVED:
				return (
					<DashboardSection
						icon={DashboardIconPath.SUCCESS}
						title="Tier 3 (additional business proof) verified!"
						text="Your Tier 3 application has been successfully verified!"
					/>
				);

			case VerificationState.PENDING:
				return (
					<DashboardSection
						icon={DashboardIconPath.PENDING}
						title="Tier 3 (additional business proof) verification pending"
						text="You will receive an email regarding the status of your verification. This may take up to 48h in most cases"
					/>
				);

			case VerificationState.REJECTED:
				return (
					<DashboardSection
						icon={DashboardIconPath.FAIL}
						title="Tier 3 (additional business proof) verification rejected"
						text="Your additional business proof has been rejected. Please review your submission to learn more about the reason for rejection"
						link={RoutesUrls.VERIFY_BUSINESS_PROOF_OF_SHARES_DOCUMENTS}
					/>
				);

			default:
				return (
					<DashboardSection
						icon={DashboardIconPath.BUSINESS_VERIFICATION}
						title="Tier 3 (additional business proof) verification"
						text="Required for Tier 3 - unlimited payment processing"
						link={RoutesUrls.VERIFY_BUSINESS_PROOF_OF_SHARES_DOCUMENTS}
					/>
				);
		}
	}

	return (
		<View noUnderlineForLinks>
			<H1 center>Upgrade your approved volume</H1>
			<BusinessVerificationViewDescription>
				In order to raise your monthly processing limits, we require additional information to verify your identity and
				your business. You can apply to raise your approved payments volume at any time.
			</BusinessVerificationViewDescription>
			{props.isBusinessVerified ? (
				<ColumnedFlex fullWidth>
					<WithPermission permissions={[Scopes.VERIFY, Scopes.VERIFY_BUSINESS, Scopes.GET_BUSINESS_VERIFICATION]}>
						{getBusinessTier2Section()}
					</WithPermission>
					<WithPermission permissions={[Scopes.VERIFY, Scopes.VERIFY_BUSINESS, Scopes.GET_BUSINESS_VERIFICATION]}>
						{getBusinessTier3Section()}
					</WithPermission>
				</ColumnedFlex>
			) : (
				<>
					<H1 center>Upgrade your approved volume</H1>
					<View.Description>
						In order to raise your monthly processing limits, we require you to verify your TIER 1 business info first.
						You can apply to raise your approved payments volume at any time afterwards.
					</View.Description>
				</>
			)}
			<JustifiedLeftFlex>
				<PreviousWrapper>
					<GoBackLink to={RoutesUrls.DASHBOARD}>
						<ReactSVG src="/files/svg/backArrowGreen.svg" />
						<GoBackText>Back to dashboard</GoBackText>
					</GoBackLink>
				</PreviousWrapper>
			</JustifiedLeftFlex>
		</View>
	);
}
