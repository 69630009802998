import Select from 'react-select';
import styled, { css } from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { OptionWrapper } from '../react-hook-form/hook-select-field/HookSelectFieldStyle';

export const StyledSelect = styled(Select)<{ selectedColor: string; focusedColor: string }>`
	width: 100%;

	.Select__control {
		border: none;
		appearance: none;
		background: transparent;
		border-radius: 4px;
		height: 48px;
		cursor: pointer;
		width: 100%;
		box-shadow: none;

		${(props) =>
			props.isMulti &&
			css`
				height: auto;
				min-height: 48px;
			`}
	}

	.Select__menu {
		border-radius: 4px;
		background-color: ${Color.WHITE};
		margin-top: 4px;
		font-family: Primary;
		font-size: 16px;
		line-height: 24px;
		color: ${Color.GRAY_9};
		min-width: 250px;
		right: 0;
	}

	.Select__menu-list {
		border-radius: 4px;
		padding: 0;

		${OptionWrapper}:not(:last-child) {
			border-bottom: 1px solid ${Color.GRAY_5};
		}

		${OptionWrapper}:first-child .Select__option--is-focused, ${OptionWrapper}:first-child .Select__option--is-selected {
			border-radius: 4px 4px 0 0;
		}

		${OptionWrapper}:last-child .Select__option--is-focused, ${OptionWrapper}:last-child .Select__option--is-selected {
			border-radius: 0 0 4px 4px;
		}
	}

	.Select__option {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.Select__option :not(.Select__option--is-disabled) {
		cursor: pointer;
	}

	.Select__option--is-focused {
		background-color: ${(props) => props.focusedColor};
	}

	.Select__option--is-selected {
		background-color: ${(props) => props.selectedColor};
		color: ${Color.GRAY_9};
	}

	.Select__value-container {
		max-height: 100%;
		max-width: 380px;
	}

	.Select__indicator-separator {
		opacity: 0;
	}

	.Select__dropdown-indicator {
		svg {
			height: 25px;
			width: 25px;
			fill: ${Color.BEIGE};
		}
	}

	/* dropdown opened, rotate indicator */
	.Select__control--menu-is-open .Select__dropdown-indicator svg {
		transform: rotate(180deg);
	}
`;
