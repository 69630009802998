import WsRpcClient, { Json } from './ws-rpc-client';

export enum SubscribeMethod {
	INVOICE_STATE_CHANGED = 'invoice-state-changed',
	POS_APP_PAIRED = 'pos-app-paired',
	VERIFY_ONCE_CALLBACK = 'verify-once-callback',
}

export default class InvoiceRpcClient extends WsRpcClient {
	constructor(url: string, protected subscribeMethod: SubscribeMethod, protected entityId: string) {
		super(url);
	}

	handleInvoiceStateChanged(_invoice: Json) {}

	handlePosPaired(_data: Json) {}

	handleVerifyOnceCallback(_data: Json) {}

	protected async onConnection() {
		// subscribe for invoice state change events
		await this.request('subscribe', { event: `${this.subscribeMethod}:${this.entityId}` });
	}

	protected async handleMethod(method: string, _args: string[], params: Json) {
		switch (method) {
			case SubscribeMethod.INVOICE_STATE_CHANGED:
				if (!params.data) {
					throw new Error('Got invoice state change event but data is missing');
				}

				this.handleInvoiceStateChanged(params.data as Json);
				break;

			case SubscribeMethod.POS_APP_PAIRED:
				if (!params.data) {
					throw new Error('Got pos paired change event but data is missing');
				}

				this.handlePosPaired(params.data as Json);
				break;

			case SubscribeMethod.VERIFY_ONCE_CALLBACK:
				if (!params.data) {
					throw new Error('Got verify once callback but data is missing');
				}

				this.handleVerifyOnceCallback(params.data as Json);
				break;

			default:
				throw new Error('got unknown rpc method');
		}
	}
}
