import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Color, Gutter } from '../../gfx/constants';
import { H2 } from '../../gfx/globals';
import { media } from '../../services/media';

export interface PanelProps {
	size?: number;
}

export const StyledPanel = styled.div`
	width: 100%;
	margin: 1% 0% 1% 0%;
	padding: 50px 15px;
	border: 1px solid rgba(220, 221, 224, 0.5);
	border-radius: 4px;
	background-color: ${Color.WHITE};
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);

	${media.fromTablet`
		padding: 50px ${Gutter.SMALL};
	`}

	${media.fromDesktop`
		padding: 50px;
	`}
`;

const Icon = styled.div<PanelProps>`
	margin-right: 20px;

	${media.maxDesktop`
	padding: 0;
`}

	${media.fromTablet`
	margin: 0 ${Gutter.LARGE} 0 calc(${Gutter.LARGE} / 2);
`}

svg {
		width: ${({ size }) => (size ? `${size}px` : '42px')};
		height: ${({ size }) => (size ? `${size}px` : '42px')};
		fill: ${Color.GREEN_3};
	}

	> div div {
		display: flex;
	}
`;

const InfoPanel = styled.div`
	.atm-icon path,
	:hover .atm-icon path {
		fill: ${Color.WHITE} !important;
	}

	.scale_icon .gray-icon svg path {
		fill: rgb(208, 222, 235) !important;
	}

	svg,
	svg path,
	svg rect {
		fill: #d0deeb !important;
	}

	.not-verified svg,
	.verified svg,
	.scale_icon svg {
		transform: scale(1.2);
	}

	.not-verified svg path,
	.verified svg path,
	.scale_icon svg path {
		fill: ${Color.WHITE} !important;
	}

	width: 100%;
	margin: 0px 0% 4px 0%;
	border-radius: 8px;
	background-color: ${Color.WHITE};
	display: table;
	position: relative;
	padding: 15px 22px;
	min-height: 114px;
	border: 1px solid ${Color.GRAY_5};
	transition: 0.3s;

	p,
	h2,
	svg path,
	svg rect {
		transition: 0.3s;
	}

	:hover {
		background: #eefff7;
		box-shadow: 0px 2px 64px rgba(20, 38, 82, 0.1);
		transition: 0.3s;

		p {
			color: ${Color.BLUE_9};
		}

		h2 {
			color: ${Color.GREEN_HOVER};
		}

		svg path,
		svg rect {
			fill: ${Color.GREEN_HOVER} !important;
		}
	}

	${Icon} {
		display: table-cell;
		vertical-align: middle;
	}

	${H2} {
		margin: 0 0 10px;
	}

	${media.maxDesktop`
		.not-verified svg,
		.verified svg,
		.scale_icon svg {
			transform: unset;
		}

		h2 {
			font-size: 16px;
		}

		min-height: 150px;
	`}
`;

const MerchantPanel = styled.div`
	.atm-icon path,
	:hover .atm-icon path {
		fill: ${Color.WHITE} !important;
	}

	.scale_icon .gray-icon svg path {
		fill: rgb(208, 222, 235) !important;
	}

	.not-verified svg,
	.verified svg,
	.scale_icon svg {
		transform: scale(1.2);
	}

	.not-verified svg path,
	.verified svg path,
	.scale_icon svg path {
		fill: ${Color.WHITE} !important;
	}

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 248px;
	min-height: 88px;
	width: 248px;
	height: 88px;
	border-radius: 4px;
	padding: 15px 22px;
	border: 1px solid ${Color.GRAY_5};
	transition: 0.3s;
	a {
		margin-bottom: 28px;
	}

	p,
	h2,
	svg path,
	svg rect {
		transition: 0.3s;
	}

	:hover {
		background-color: ${Color.WHITE};
		box-shadow: 0px 2px 64px rgba(20, 38, 82, 0.1);
		transition: 0.3s;

		p {
			color: ${Color.BLUE_9};
		}

		h2 {
			color: ${Color.GREEN_HOVER};
		}
	}

	${Icon} {
		display: table-cell;
		vertical-align: middle;
	}

	${H2} {
		margin: 0 0 10px;
	}

	${media.maxSize800`
		width: 100%;
	`}

	${media.maxDesktop`
		.not-verified svg,
		.verified svg,
		.scale_icon svg {
			transform: unset;
		}

		h2 {
			font-size: 16px;
		}
	`}
`;

const InfoWrapper = styled.div`
	display: table-cell;
	position: relative;
	vertical-align: middle;

	${media.fromDesktop`
		padding-left: 7px;
	`}
`;

const MerchantWrapper = styled.div`
	display: flex;
	flex-direction: column;

	a {
		margin-left: 24px;
	}
`;

interface Props {
	infoPanel?: boolean;
	merchantPanel?: boolean;
	className?: string;
	children: React.ReactNode;
}

const Panel = (props: Props) => {
	const { merchantPanel, infoPanel, children, className } = props;

	return infoPanel === true ? (
		<InfoPanel className={className}>
			<InfoWrapper>{children}</InfoWrapper>
		</InfoPanel>
	) : merchantPanel === true ? (
		<MerchantPanel className={className}>
			<MerchantWrapper>{children}</MerchantWrapper>
		</MerchantPanel>
	) : (
		<StyledPanel>{children}</StyledPanel>
	);
};

Panel.LinkWrapper = styled(Link)`
	width: 100%;

	:visited {
		color: ${Color.GRAY_5};
	}
`;

Panel.AnchorWrapper = styled.a`
	width: 100%;

	:visited div {
		outline: none;
	}
`;

Panel.Text = styled.p`
	margin: 0;
	color: ${Color.GRAY};
	font-family: Secondary;
	font-size: 15px;
	line-height: 20px;
`;

Panel.SectionWrapper = styled.div`
	padding-left: 25px;
	display: table-cell;
	vertical-align: middle;

	h2 {
		font-size: 18px;
	}

	p {
		font-size: 14px;
		color: ${Color.GRAY_9};
		font-family: Primary;
	}

	button {
		color: ${Color.BLUE_2};
	}

	${media.fromDesktop`
		padding-left: 7;
	`}
`;

Panel.TitleWrap = styled.div`
	display: flex;
`;

Panel.Tag = styled.span`
	margin-left: 10px;
	top: 5px;
	padding: 4px 8px;
	background: ${Color.GRAY_2};
	border-radius: 4px;
	font-size: 12px;
	font-family: PrimaryMedium;
	font-weight: 500;
	color: ${Color.GRAY_10};
	position: absolute;
	right: 0;

	${media.maxDesktop`
		position: relative;
		top: unset;
	`}
`;

Panel.TagWrap = styled.span`
	margin-bottom: 10px;
`;

Panel.Icon = Icon;

export default Panel;
