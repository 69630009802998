import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import styled from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

export interface HookTimerProps {
	endDate: Date;
	size: number;
	lineWidth?: number;
	label: string;
	blue?: boolean;
	green?: boolean;
	red?: boolean;
}

interface ColorProps {
	blue?: boolean;
	green?: boolean;
	red?: boolean;
}

interface TimerSlots {
	total: number;
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
}

const ProgressBarBackground = styled.circle`
	fill: none;
	stroke: ${Color.GRAY_19};
`;

const ProgressBarProgressLine = styled.circle<ColorProps>`
	fill: none;
	stroke: ${Color.BLUE_7};
	stroke: ${(props) =>
		(props.color && Color.GREEN_3) || (props.blue && Color.LIGHT_BLUE) || (props.red && Color.RED_2)};
`;

const ProgressBarWrapper = styled.div`
	margin: auto;
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${media.maxSize800`
		margin: 20px auto;
	`}
`;

const ProgressBarLabel = styled.span`
	font-family: primaryMedium;
	font-size: 15px;
	margin-bottom: 17px;
	text-align: center;
`;

const HookTimer = (props: HookTimerProps) => {
	const { blue, red, green, endDate } = props;
	const viewBox = `0 0 ${props.size} ${props.size}`;
	const radius = (props.size - (props.lineWidth ? props.lineWidth : 1)) / 2;

	const getTimeRemaining = useCallback(() => {
		const total = Date.parse(endDate.toISOString()) - Date.now();
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
		const days = Math.floor(total / (1000 * 60 * 60 * 24));

		return {
			total,
			days,
			hours,
			minutes,
			seconds,
		};
	}, [endDate]);

	const [timer, setTimer] = useState<TimerSlots>(getTimeRemaining());
	const totalTime = getTimeRemaining();

	const maxProgress = radius * Math.PI * 2;
	const progressOffset = maxProgress - (maxProgress * ((timer.total * 100) / totalTime.total)) / 100;

	useEffect(() => {
		let interval: NodeJS.Timeout;
		interval = setInterval(() => {
			setTimer(getTimeRemaining());
		}, 1000);
		if (timer.total <= 0) {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [timer, endDate, getTimeRemaining]);
	return (
		<ProgressBarWrapper>
			<ProgressBarLabel>{props.label}</ProgressBarLabel>
			<svg width={props.size} height={props.size} viewBox={viewBox}>
				<ProgressBarBackground
					cx={props.size / 2}
					cy={props.size / 2}
					r={radius}
					strokeWidth={`${props.lineWidth}px`}
				/>
				<ProgressBarProgressLine
					red={red}
					green={green}
					blue={blue}
					cx={props.size / 2}
					cy={props.size / 2}
					r={radius}
					strokeWidth={`${props.lineWidth}px`}
					// Start progress marker at 12 O'Clock
					transform={`rotate(-90 ${props.size / 2} ${props.size / 2})`}
					style={{
						strokeDasharray: maxProgress,
						strokeDashoffset: progressOffset,
					}}
				/>
				<text
					className="circle-text"
					x="50%"
					y="50%"
					dy=".3em"
					textAnchor="middle"
					fill={timer.total <= 0 ? 'red' : 'black'}
				>
					{timer.total <= 0 ? `Confirming...` : `${timer.hours}:${timer.minutes}:${timer.seconds}`}
				</text>
			</svg>
		</ProgressBarWrapper>
	);
};

export default HookTimer;
