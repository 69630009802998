import { media } from '../../../services/media';
import React from 'react';
import { StyledComponentProps } from 'styled-components';
import styled from 'styled-components/macro';
import { H2 } from '../../../gfx/globals';
import { Color } from '../../../gfx/constants';

const StyledNotSupported = styled.div`
	display: flex;
`;

const NotSupported = (props: StyledComponentProps<'div', {}, {}, never>) => (
	<StyledNotSupported>{props.children}</StyledNotSupported>
);

NotSupported.SidePanel = styled.div`
	width: 520px;
	background: linear-gradient(180deg, #1a2b56 0%, #0b132b 100%);
	color: ${Color.WHITE};
	flex-direction: column;
	padding: 88px;
	flex: 0.8;
	min-height: 100vh;

	${media.maxDesktop`
		min-width: 100%;
		padding: 20px;
	`}
`;

NotSupported.Wrapper = styled.div`
	width: 325px;
	margin-left: auto;
	align-self: flex-end;

	${media.maxDesktop`
	width: 100%;
	`}
`;

NotSupported.Header = styled.div`
	padding-bottom: 91px;

	${media.maxMobile`
		padding-bottom: 33px;
	`}
`;

NotSupported.Notification = styled.div`
	${H2}, p {
		color: ${Color.WHITE};
	}

	a {
		color: ${Color.GREEN_3};
	}
`;

export default NotSupported;
