import styled from 'styled-components/macro';
import { AtmPaymentsDefault } from '../../../../constants';
import { Color } from '../../../../gfx/constants';
import { media } from '../../../../services/media';

interface NotificationColor {
	color?: AtmPaymentsDefault;
}

export const NotificationUnderInput = styled.div`
	width: 100%;
	line-height: 24px;
	margin: -5px 0px 0px 0px !important;

	p {
		display: inline-block;
		font-weight: 400;
		font-size: 13px;
		margin: 0px !important;
		line-height: 24px;
	}

	span {
		display: inline-block;
		margin-left: 5px;
		font-weight: 400;
		font-size: 13px;
		line-height: 24px;
	}

	div {
		line-height: 24px;
		margin: 0px;
	}
`;

export const IsErrorMessage = styled.p`
	display: inline-block;
	font-weight: 400;
	font-size: 13px;
	margin: 0px !important;
	line-height: 24px;
	color: #fa4a5c;
`;

export const PosSeparator = styled.div`
	display: flex;
	align-items: center;
	color: ${Color.BLUE_10};
	font-size: 16px;
	border-bottom: 1px solid rgba(220, 221, 224, 0.5);
	font-weight: 500;
	line-height: 22px;
	padding-bottom: 18px;
	margin-bottom: 38px;
	margin-top: 38px;

	svg {
		margin-right: 12px;
	}
`;

export const FieldColumn = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;

	${media.maxDesktop`
		flex-direction: column;
	`}
`;

export const CustomIdField = styled.div`
	width: 53%;

	${media.maxDesktop`
		width: 100%;
	`}
`;

export const CustomFieldFullWidth = styled.div`
	width: 100%;
`;

export const AtmField = styled.div`
	width: 47%;
	padding-left: 55px;

	span {
		padding-top: 20px;
		display: inline-block;
		font-family: PrimaryMedium;
		color: ${Color.BLUE_9};
	}

	a,
	a:visited {
		color: ${Color.BLUE_1};
		padding-left: 5px;
	}

	${media.maxDesktop`
		width: 100%;
		padding-left: 0px;
	`}
`;

export const PosTotalAmount = styled.div`
	display: flex;
	flex-direction: column;
	color: ${Color.OPTIONAL_TEXT};

	font-size: 15px;
	font-weight: 500;
	line-height: 18px;
	margin-right: 100px;
	width: 200px;
	margin-left: auto;
	text-align: left;
	margin-bottom: 84px;

	span {
		margin-bottom: 11px;
	}
`;

export const PosButtonColumn = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 0px -5px;

	button {
		margin: 7px 5px;
		width: 100%;
	}

	@media (max-width: 560px) {
		flex-direction: column;
	}

	@media (max-width: 360px) {
		margin: 0;
	}
`;

export const PosModalTitleWrapper = styled.div`
	text-align: left;
	font-size: 14px;
	font-family: Secondary;
	margin-bottom: 20px;

	h2 {
		text-align: left !important;
		font-size: 24px;
		line-height: 26px;
	}
`;

export const NotificationWithIcon = styled.div`
	display: inline;
`;

export const NotificationIcon = styled.div`
	display: inline-block;

	svg {
		margin-bottom: -4px;
	}
`;

export const PosFormWrap = styled.div`
	width: 100%;
	padding: 45px 36px 55px 36px;
	background-color: ${Color.WHITE};
	border-radius: 4px;

	${media.maxDesktop`
		padding: 45px 20px 35px 20px;
	`}
`;

export const PosFormBottom = styled.div`
	width: 100%;
	background: ${Color.GRAY_3};
	padding: 18px 36px 18px 36px;
	border-top: 1px solid ${Color.GRAY_5};

	button {
		margin-right: 0;
	}

	${media.maxDesktop`
		padding: 18px 20px 18px 20px;
	`}
`;

export const PosForm = styled.div`
	width: 100%;
	max-width: 570px;
	margin: 0 auto;

	form {
		border: 1px solid ${Color.GRAY_5};
		box-shadow: none;
		padding: 0px;

		label {
			color: ${Color.GRAY_8};
			font-size: 14px;
			line-height: 16px;

			span {
				display: none;
			}
		}

		h3 {
			font-weight: 500;
			font-size: 18px;
			line-height: 22px;
			color: ${Color.GRAY_10};
			border-bottom: 1px solid ${Color.GRAY_5};
			margin-top: 0px;
			padding-bottom: 28px;
			margin-bottom: 30px;
		}

		.Select__single-value {
			color: ${Color.BLUE_9};
			font-size: 16px;
			font-family: Secondary;
		}

		.dark-currency .Select__control {
			border-radius: 0px;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
			padding-left: 5px;

			svg {
				fill: ${Color.BEIGE};
			}

			.Select__single-value {
				background: ${Color.GRAY_3};
				color: ${Color.GRAY_10};
				padding: 4px 8px;
				font-size: 12px;
				line-height: 14px;
				font-family: PrimaryMedium;
				border-radius: 4px;
			}
		}
		
		.dark-currency .Select__single-value {
			color: ${Color.GRAY_8};
			font-size: 16px;
			font-family: PrimaryMedium;
		}

		${media.maxDesktop`
			h3 {
				border-bottom: none;
				margin-bottom: 0px;
			}
		`}
	}
`;

export const PosModalWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	box-sizing: border-box;

	${media.maxDesktop`
		padding: 0 30px;
	`}
`;

export const AtmPaymentsNotification = styled.p<NotificationColor>`
	font-weight: 500;
	font-size: 15px;
	font-family: primaryMedium;
	span {
		color: ${(props) =>
			(props.color === AtmPaymentsDefault.ENABLED && Color.GREEN_3) ||
			(props.color === AtmPaymentsDefault.ASK && Color.ORANGE) ||
			(props.color === AtmPaymentsDefault.OFF && Color.RED_2)};
	}
`;

export const LimitNotificationText = styled.span<{ error?: boolean }>`
	color: ${(props) => props.error && Color.RED_2};
`;
