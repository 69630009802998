import axios from 'restyped-axios';
import { UserStatus } from 'verify-once';

import { VerificationState, VerificationType } from '../constants';
import { FreelanceVerificationRequest } from '../models/verificationModel';
import { ApiResponse } from '../typings';
export const api = axios.create<VerificationApi>();

export interface VerificationApi {
	'/verify': {
		GET: {
			response: ApiResponse<Verifications>;
		};
	};

	'/verify/identity': {
		GET: {
			response: ApiResponse<IdentityVerification>;
		};
	};

	'/verify/identity/initiate': {
		POST: {
			body: InitiateVerifyIdentityRequest;
			response: ApiResponse<InitiateIdentityResponse>;
		};
	};

	'/verify/business': {
		POST: {
			response: ApiResponse<BusinessVerification>;
		};
	};
	'/verify/business-address': {
		POST: {
			response: ApiResponse<BusinessVerification>;
		};
	};
	'/verify/business-ownership': {
		POST: {
			response: ApiResponse<BusinessVerification>;
		};
	};

	'/verify/freelance': {
		POST: {
			response: ApiResponse<BusinessVerification>;
		};
	};
}

export interface InitiateVerifyIdentityRequest {
	dateOfBirth: string;
}

export interface InitiateIdentityResponse {
	transactionId: string;
	url: string;
}

export interface Upload {
	id: string;
	userId: string;
	name: string;
	field: string;
	encoding: string;
	mimetype: string;
	url: string;
}

export interface UploadsArray {
	items: Upload[];
}

export enum UserVerificationStatus {
	NOT_VERIFIED = 'NOT_VERIFIED',
	INITIATED = 'INITIATED',
	PENDING = 'PENDING',
	REJECTED = 'REJECTED',
	FRAUD = 'FRAUD',
	PROFILE_MISMATCH = 'PROFILE_MISMATCH',
	MANUALLY_REJECTED = 'MANUALLY_REJECTED',
	CANT_RETRY = 'CANT_RETRY',
	VERIFIED = 'VERIFIED',
}

export enum VerificationStatus {
	UNINITIATED = 'UNINITIATED',
	INITIATED = 'INITIATED',
	PENDING = 'PENDING',
	VERIFIED = 'VERIFIED',
	FAILED = 'FAILED',
	LOCKED = 'LOCKED',
	DOCUMENT_EXPIRING_VERIFIED = 'DOCUMENT_EXPIRING_VERIFIED',
	DOCUMENT_EXPIRED_REJECTED = 'DOCUMENT_EXPIRED_REJECTED',
	VERIFICATION_EXPIRING_VERIFIED = 'VERIFICATION_EXPIRING_VERIFIED',
	VERIFICATION_EXPIRED_REJECTED = 'VERIFICATION_EXPIRED_REJECTED',
}

export interface IdentityVerification {
	id: string;
	userId: string;
	transactionId: string;
	verifyOnceUserId: string | null;
	verifyOnceUserStatus: UserStatus | null;
	isVerificationStatusForced: boolean;
	verificationStatus: UserVerificationStatus | null;
	identityVerificationStatus: VerificationStatus | null;
	addressVerificationStatus: VerificationStatus | null;
	firstName: string;
	lastName: string;
	dateOfBirth: string | null;
	profileMatch: boolean;
}

export interface BusinessVerification {
	id: string;
	userId: string;
	type: VerificationType;
	state: VerificationState;
	info: string;
	rejectionReason: string | null;
	createdDate: string;
	updatedDate: string;
	uploads: UploadsArray;
}

export interface BusinessTiers {
	FREELANCE: BusinessVerification | null;
	TIER1: BusinessVerification | null;
	TIER2: BusinessVerification | null;
	TIER3: BusinessVerification | null;
}

export interface CombinedVerificationsInfo {
	isEmailVerified: boolean;
	isWalletAdded: boolean;
	isIdentityVerified: boolean;
	isBusinessVerified: boolean;
	isComplete: boolean;
	identity: IdentityVerification | null;
	business: BusinessVerification | null;
	tiers: BusinessTiers;
}

export interface Verifications {
	parentUser: CombinedVerificationsInfo | null;
	user: CombinedVerificationsInfo | null;
}

export interface VerifyIdentityRequest {
	data: FormData;
}

export interface VerifyBusinessRequest {
	data: FormData;
}
export interface VerifyBusinessTierRequest {
	data: FormData;
}

export interface DisableVerificationNotificationRequest {
	verificationId: string;
}

export interface DisableVerificationNotificationResponse {
	success: boolean;
}

export const getVerificationsApi = async () => {
	const getVerificationsResponse = await api.get('/verify');

	return getVerificationsResponse.data;
};

export const getIdentityVerificationApi = async () => {
	const response = await api.get('/verify/identity');

	return response.data;
};

export const initiateVerifyIdentityApi = async (params: InitiateVerifyIdentityRequest) => {
	const initiateVerifyIdentityResponse = await api.post('/verify/identity/initiate', {
		...params,
	});

	return initiateVerifyIdentityResponse.data;
};

export const verifyBusinessApi = async (params: VerifyBusinessRequest) => {
	const verifyBusinessResponse = await api.post('/verify/business', params.data, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});

	return verifyBusinessResponse.data;
};
export const verifyBusinessAddressApi = async (params: VerifyBusinessTierRequest) => {
	const verifyBusinessResponse = await api.post('/verify/business-address', params.data, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});

	return verifyBusinessResponse.data;
};
export const verifyBusinessOwnershipApi = async (params: VerifyBusinessTierRequest) => {
	const verifyBusinessResponse = await api.post('/verify/business-ownership', params.data, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});

	return verifyBusinessResponse.data;
};

export const verifyFreelanceApi = async (params: FreelanceVerificationRequest) => {
	const verifyBusinessResponse = await api.post('/verify/freelance', {
		...params,
	});

	return verifyBusinessResponse.data;
};

export const disableVerificationNotificationApi = async (params: DisableVerificationNotificationRequest) => {
	const initiateVerifyIdentityResponse = await api.post('/verify/business/disableNotification', {
		...params,
	});

	return initiateVerifyIdentityResponse.data;
};
