import React from 'react';
import ReactSVG from 'react-svg';
import Button from '../../../../components/button/Button';
import ExternalInvoiceHeader from '../../../../components/ext-invoice-header/ExternalInvoiceHeader';
import Loader from '../../../../components/loader/Loader';
import { FaqLinks } from '../../../../constants';
import { Color } from '../../../../gfx/constants';
import { ExternalInvoiceGroupedStatus } from '../../../../services/get-external-invoice-grouped-status';
import { ContentTitle, MaximizedText, SecondaryViewWrapper } from '../ExternalInvoiceViewStyle';
import { ContentWrap, LoaderWrap, StatusSvgWrap } from './ExternalInvoiceStatusViewStyle';

const ExternalInvoiceFinalStatus = {
	[ExternalInvoiceGroupedStatus.PAID]: {
		title: 'Payment sent!',
		description: (
			<>
				You may navigate back to the merchant using the <br />
				button below.<br /><br />
				A receipt of the payment will be sent to your email<br /> after the transaction is confirmed
			</>
		),
		iconSrc: '/files/svg/private/Success_2.svg',
	},
	[ExternalInvoiceGroupedStatus.CANCELLED]: {
		title: 'Payment cancelled',
		description: (
			<>
				You may navigate back <br />
				to the merchant using the button below
			</>
		),
		iconSrc: '/files/svg/private/dashboard/Fail.svg',
	},
	[ExternalInvoiceGroupedStatus.EXPIRED]: {
		title: 'Payment expired',
		description: 'Please return to the merchant and start the payment process again',
		iconSrc: '/files/svg/private/dashboard/Fail.svg',
	},
	[ExternalInvoiceGroupedStatus.FAILED]: {
		title: 'Payment failed',
		description: 'Please return to the merchant and start the payment process again',
		iconSrc: '/files/svg/private/dashboard/Fail.svg',
	},
	// Should not be never displayed
	[ExternalInvoiceGroupedStatus.PENDING]: {
		title: 'Payment is pending',
		description: 'Please wait!',
		iconSrc: '/files/svg/private/dashboard/Pending.svg',
	},
};

export interface StatusViewProps {
	isExtInvoiceLoading?: boolean;
	externalInvoiceStatus?: ExternalInvoiceGroupedStatus | null;
	redirectUrl?: string;
	showHeader?: boolean;
}

const ExternalInvoiceStatusView = (props: StatusViewProps) => {
	const { showHeader, redirectUrl, externalInvoiceStatus, isExtInvoiceLoading } = props;
	return (
		<SecondaryViewWrapper disableStyles={!showHeader}>
			{showHeader && (
				<ExternalInvoiceHeader disableLogoClick hasWhiteBackground helpLink={FaqLinks.externalInvoiceHelp} />
			)}
			{isExtInvoiceLoading || !externalInvoiceStatus ? (
				<LoaderWrap>
					<Loader />
				</LoaderWrap>
			) : (
				<ContentWrap>
					<StatusSvgWrap>
						<ReactSVG src={ExternalInvoiceFinalStatus[externalInvoiceStatus].iconSrc} />
					</StatusSvgWrap>
					<ContentTitle>{ExternalInvoiceFinalStatus[externalInvoiceStatus].title}</ContentTitle>
					<MaximizedText marginTop={17} marginBottom={109}>
						{ExternalInvoiceFinalStatus[externalInvoiceStatus].description}
					</MaximizedText>
					{redirectUrl && (
						<Button centered backgroundColor={Color.BLUE_9} onClick={() => (window.location.href = `${redirectUrl}`)}>
							<ReactSVG src="/files/svg/icons/merchant.svg" />
							Return to merchant
						</Button>
					)}
				</ContentWrap>
			)}
			<MaximizedText marginTop={62}>Payments processed by Dagpay</MaximizedText>
		</SecondaryViewWrapper>
	);
};

export default ExternalInvoiceStatusView;
