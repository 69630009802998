import styled from 'styled-components/macro';

import Field from '../../../../components/field/Field';
import StyledField from '../../../../components/field/FieldStyle';
import { MultiSelectDropDown, MultiSelectLabel } from '../../../../components/multi-select-field/MultiSelectFieldStyle';
import { Color } from '../../../../gfx/constants';
import { media } from '../../../../services/media';

export const FiltersSection = styled.div`
	padding-bottom: 10px;
	width: 100%;
	max-width: 100%;

	input {
		min-height: 36px;
		padding-top: 0px;
		padding-left: 0px;
		width: calc(100% - 50px);

		&::-webkit-input-placeholder {
			color: ${Color.GRAY_8};
		}
		&::-moz-placeholder {
			color: ${Color.GRAY_8};
		}
		&:-ms-input-placeholder {
			color: ${Color.GRAY_8};
		}
		&:-moz-placeholder {
			color: ${Color.GRAY_8};
		}
	}

	.Select__placeholder {
		color: ${Color.GRAY_8};
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		box-shadow: 0 0 0 30px #f0f0f0 inset;
		-webkit-text-fill-color: black;
	}

	label {
		font-size: 14px;
		color: ${Color.OPTIONAL_TEXT};
	}

	.Select__value-container {
		padding-top: 0;
		padding-bottom: 0;

		> div + div {
			padding: 0;
			margin: 0;
		}
	}

	.Select__single-value {
		position: relative;
		transform: none;
	}

	.Select__input,
	.Select__control {
		min-height: 36px;
		height: 36px;
	}

	i {
		padding: 8px 8px;
		width: unset;
	}

	i:last-of-type {
		width: 24px;
	}

	button {
		align-self: flex-end;
		min-width: 72px;
		margin-bottom: 0px;
		padding-left: 15px;
		padding-right: 15px;
	}

	button[type='submit'] {
		margin-right: 10px;
	}

	${media.maxSize800`
		padding-bottom: 25px;
	`}
`;

export const SearchField = styled(Field)`
	width: 384px;
	flex-grow: 1;
	margin-left: 0;

	${media.maxTransactionTable`
		margin-bottom: 15px !important;
	`}
`;

export const SearchWithButton = styled.div`
	display: flex;
	position: relative;
	margin-top: 10px;

	button {
		position: absolute;
		right: 0;
		top: 0;
		margin: 0px !important;
		padding: 0px !important;
		width: 48px;
		min-width: 48px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 48px;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		border-left: 1px solid ${Color.GRAY_5};

		svg {
			margin: 5px 0px 0px 0px;
		}
	}

	input {
		&::-webkit-input-placeholder {
			color: ${Color.GRAY_7};
		}
		&::-moz-placeholder {
			color: ${Color.GRAY_7};
		}
		&:-ms-input-placeholder {
			color: ${Color.GRAY_7};
		}
		&:-moz-placeholder {
			color: ${Color.GRAY_7};
		}
	}

	i {
		border-right: none;
	}

	${media.maxSize800`
		width: 100%;
		margin-top: 0px;
	`}
`;

export const TransactionHeading = styled.div`
	display: flex;
	flex-direction: column;
`;

export const FilterRow = styled.div`
	display: flex;
	border-top: 1px solid ${Color.GRAY_5};
	padding-top: 24px;

	${media.maxTransactionTable`
		flex-direction: column;
		h1 {
			margin-bottom: 10px;
		}
	`}
`;

export const CalendarGroup = styled.div`
	display: flex;

	${media.maxTransactionTable`
		margin-bottom: 15px;
		> :nth-child(2){
			margin-left: 0 !important;
			 label{
				padding-left: 10px;
			}
		}

		input{
			margin-left: 15px;
		}
	`}

	${StyledField.Wrapper} {
		${media.maxTransactionTable`
			margin-left: 0;
		`}

		${media.fromTransactionTable`
			margin-left: 0;
		`}

		&:nth-child(1) {
			margin-left: 0;

			${StyledField.InputWrapper} {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-right: 0;
			}

			${StyledField.AddonIcon} {
				${media.maxTransactionTable`
					border-right: 1px solid ${Color.GRAY_5};
				`}
			}
		}

		&:nth-child(2) {
			${StyledField.Wrapper} {
				margin-left: 0;
			}

			${StyledField.InputWrapper} {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

			${StyledField.AddonIcon} {
				${media.maxTransactionTable`
					display: none;
				`}
			}
		}

		&:nth-child(n + 2) {
			${media.fromTransactionTable`
				margin-left: 0;
			`}
		}

		input {
			width: 95px;
			padding-left: 5px;
		}
	}
`;

export const DropdownGroup = styled.div`
	display: flex;
	width: 100%;

	> :first-child,
	> :nth-child(2) {
		min-width: 150px;
		max-width: 150px;
	}

	> :nth-child(3) {
		width: 100%;
		${MultiSelectLabel} {
			max-width: 250px;
		}
	}

	${media.maxSize1400`
	> :first-child,
	> :nth-child(2){
		min-width: 130px;
		max-width: 130px;
	}
	> :nth-child(3){
		${MultiSelectLabel}{
		max-width: 120px;
		}
	}
	`}

	${media.maxSize800`
		margin-bottom: 0px !important;
	`}

	${media.maxTransactionTable`
		width: 100%;
		margin-bottom: 15px;

		> :first-child{
			margin-left: 0 !important;
		}

		> :first-child,
		> :nth-child(2),
		> :nth-child(3){
			max-width: 100%;
		}

		${MultiSelectDropDown}{
				right: 0;
		}
		`}

		${media.maxTable`
		flex-direction: column;

		${StyledField.Wrapper}{
			margin: 0 0 10px 0 !important;
		}
	`}
`;

export const ButtonGroup = styled.div`
	display: flex;
	align-items: flex-end;

	& > :nth-child(2) {
		button {
			margin-left: 18px !important;
		}
	}

	button:nth-child(1) {
		margin-bottom: 0;
		margin-left: 30px;
	}

	button:nth-child(2) {
		margin-bottom: 0;
	}

	button:last-child {
		min-width: 95px;
		padding-right: 0px;
		padding-left: 0px;
	}
	svg {
		margin-top: 3px;
	}
	svg g g path {
		fill: ${Color.GRAY_1};
	}
	${media.maxSize800`
		flex-direction: row-reverse;
		margin-top: 5px;
		align-items: flex-start;

		button:nth-child(n + 2) {
			margin-left: auto !important;
			float: right;
		}

		button:last-child {
			float: right;
		}
		button div {
			margin-left: 10px;
		}
		div {
			margin-left: auto;
			width: 70%;
			text-align: right;
		}
	`}
`;

export const FilterGrid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 2;
	grid-template-rows: 3;

	.remove-icon-border i {
		border: none;
		margin-right: -12px;
	}

	${media.maxSize800`
		display: block;
	`}

	${TransactionHeading} {
		grid-column: 1 / 2;
		grid-row: 1 / 2;

		${media.maxSize800`
			grid-column: 1 / 3;
		`}
	}

	${SearchField} {
		input {
			min-height: 48px;
			line-height: 48px;
		}
		div {
			min-height: 48px;
			line-height: 48px;
			border-radius: 4px;
			max-width: 500px;
			float: right;
		}
		grid-column: 2 / 3;
		grid-row: 1 / 2;
		justify-self: end;

		${media.maxTable`
		grid-column: 1 / 3;
		grid-row: 2 / 3;
		`}
		${media.maxSize800`
			div {
				max-width: 100%;
			}
			margin-bottom: 24px !important;
		`}
	}

	${FilterRow} {
		grid-column: 1 / 3;
		grid-row: 2 / 3;

		${CalendarGroup} {
			${StyledField.Wrapper} {
				width: 100%;
			}
			${media.maxSize800`
				margin-bottom: 10px;
			`}
		}

		${media.maxTable`
				grid-column: 1 / 3;
				grid-row: 3 / 4;
		`}
	}
`;

export const ModalEmailAreaWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100px;
	width: 100%;
`;

export const ExportButtonWrapper = styled.div`
	position: relative;

	button:hover {
		background-color: transparent;
		color: ${Color.GRAY_8};
	}
`;

export const ExportSecondaryButtons = styled.div`
	position: absolute;
	bottom: 34px;

	.react-reveal {
		animation-duration: 300ms !important;
		margin-bottom: 4px;
	}
`;

export const SenderAddress = styled.div<{ index: number }>`
	color: ${Color.GRAY_9};
	font-family: Secondary;
	font-size: 13px;
	line-height: 19px;
	word-break: break-all;

	::before {
		color: ${Color.GRAY_11};
		font-family: PrimaryMedium;
		content: '${(props) => (!!props.index ? `${props.index}.` : '')}';
		margin-right: 5px;
	}

	${media.maxSize800`
		font-size: 14px;
		line-height: 21px;
	`}
`;
