import styled from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

interface NavLinkProps {
	active: boolean;
}

export const StyledNavigationBarWrap = styled.div`
	width: 100%;

	${media.maxDesktop`
		h2 {
			margin-top: 40px;
		}
		margin-top: 20px;
	`}
`;

export const StyledNavigationMobile = styled.div`
	width: 100%;
	display: none;

	${media.maxDesktop`
		display: block;
	`}
`;

export const StyledNavigationDesktop = styled.div`
	width: 100%;

	${media.maxDesktop`
		display: none;
	`}
`;

export const NavLink = styled.a<NavLinkProps>`
	border-bottom: ${({ active }) => active && `3px solid ${Color.GREEN_3}`};
	padding-bottom: 7px;
	text-align: center;
	color: ${({ active }) => (active ? `${Color.GRAY_12}` : `${Color.GRAY_7}`)};

	&:hover {
		cursor: pointer;
		border-bottom: 3px solid ${Color.GREEN_3};
		text-decoration: none;
	}

	${media.maxDesktop`
		margin-bottom: 10px;
	`}
`;

export const NavigationButton = styled.div`
	width: 100%;
	background: ${Color.WHITE};
	border: 1px solid ${Color.GRAY_5};
	box-shadow: 0px 1px 1px rgb(0 0 0 / 5%);
	border-radius: 4px;
	height: 48px;
	flex-wrap: wrap;
	display: flex;
	line-height: 48px;
	padding: 0px 20px;
	cursor: pointer;
	color: ${Color.GRAY_9};
    padding-bottom: 10px;
	font-family: 'Primary';
	font-size: 16px;
	position: relative;

	svg {
		position: absolute;
		right: 13px;
		top: 0;
		bottom: 0;
		margin: auto;
		transform: rotate(-90deg);
		height: 14px;
		width: 14px;
	
		path {
			fill: ${Color.GRAY_6};
		}
	}
`;

export const RotateArrow = styled.div`
	svg {
		transform: rotate(90deg);
	}
`;

export const StyledNavigationBar = styled.nav`
	display: flex;
	position: relative;
	width: 100%;

	&:after {
		content: '';
		position: absolute;
		top: calc(100% - 10px);
		left: 0;
		right: 0;
		height: 1px;
		background-color: ${Color.GRAY_5};

		${media.fromDesktop`
			top: calc(100% - 4px);
		`}
	}

	a {
		font-family: PrimaryMedium;
		font-size: 16px;

		:not(:last-child) {
			margin-right: 5%;
		}
	}

	${media.maxDesktop`
		flex-wrap: wrap;
		justify-content: center;
		background: ${Color.WHITE};
		border: 1px solid ${Color.GRAY_5};
		box-shadow: 0px 1px 1px rgb(0 0 0 / 5%);
		border-radius: 4px;
		position: absolute;
		top: 80px;
		left: 0px;
		z-index: 2;

		:after {
			display: none;
		}
		
		a {
			width: 100%;
			padding: 0px 20px;
			font-size: 16px;
			border-bottom: 1px solid ${Color.GRAY_5};
			color: ${Color.GRAY_9};
			font-family: 'Primary';
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin: 0px !important;
			line-height: 48px;

			:last-of-type,
			:last-of-type:hover {
				border: none;
			}

			:hover {
				border-bottom: 1px solid ${Color.GRAY_5};
			}
		}
	`}
`;