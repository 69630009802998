import React, { useState } from 'react';
import { push as Menu } from 'react-burger-menu';

import { BurgerMenuLink, StyledBurgerMenu } from './BurgerMenuStyle';

interface Props {
	children: React.ReactNode;
	right?: boolean;
	whiteBackground?: boolean;
}

export default function BurgerMenu(props: Props) {
	const [isOpen, setIsOpen] = useState(false);

	const { right, children, whiteBackground } = props;

	const arrayOfChildren = React.Children.toArray(children);

	return (
		<StyledBurgerMenu whiteBackground={whiteBackground}>
			<Menu right={right} isOpen={isOpen} outerContainerId="root" width={ '100%' } noOverlay={true}>
				{arrayOfChildren.map((child, index) => (
					<BurgerMenuLink
						key={`BurgerMenuLink-${index}`}
						isFirst={index === 0}
						isLast={index === arrayOfChildren.length - 1}
						onClick={() => setIsOpen(false)}
					>
						{child}{' '}
					</BurgerMenuLink>
				))}
			</Menu>
		</StyledBurgerMenu>
	);
}
