import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Color } from '../../../gfx/constants';

interface DocumentationNavLinkProps {
	active?: number;
}

export const DocumentationWrap = styled.div`
	display: flex;
	align-items: flex-start;

	@media (max-width: 1024px) {
		flex-direction: column;
		margin-left: 0;
		margin-right: 0;
		max-width: 100%;
	}
`;

export const DocumentationSidebar = styled.nav`
	padding-top: 80px;
`;

export const DocumentationMiddleColumn = styled.div`
	min-height: 100vh;
	width: 100%;
`;

export const DocumentationHeader = styled.div`
	position: sticky;
	top: 0;
	height: 80px;
	z-index: 100;
	background: white;
	display: flex;
	padding-left: 3vw;
	align-items: center;
	margin-bottom: 50px;
	border-bottom: 1px solid rgba(128, 128, 128, 0.3);

	a {
		text-decoration: none;
		color: black;
		font-family: PrimaryMedium;
	}

	& > a {
		@media (max-width: 1024px) {
			padding-left: 20px;
		}
	}
`;

export const DocumentationHeaderLogo = styled.div`
	@media (min-width: 1024px) {
		display: none;
	}
`;

export const DocumentationContent = styled.div`
	flex: 0.8;
	max-width: 980px;
	padding-left: 3vw;
	padding-right: 3vw;

	@media (min-width: 1480px) {
		max-width: 60vw;
	}

	@media (max-width: 1480px) {
		max-width: 70vw;
	}

	@media (max-width: 1024px) {
		max-width: 100vw;
	}
`;

const className = 'markdown-body';

export const DocumentationBody = styled.div.attrs({ className })`
	margin-bottom: 40px;

	strong {
		font-weight: unset !important;
		font-family: PrimaryMedium;
	}

	pre {
		background: #525668 !important;
		color: ${Color.WHITE};
	}

	h1,
	h2,
	h3 {
		margin-top: 0px;
		font-weight: unset !important;
		font-family: PrimaryMedium;
		font-size: 20px !important;
		border-bottom: none !important;
	}

	h2 {
		text-transform: capitalize;
		border-top: 1px solid #eaecef;
		padding-top: 30px;
	}

	p > a {
		color: ${Color.ORANGE};
	}
`;

export const DocumentationEndpointSection = styled.div`
	margin-bottom: 25px;

	tbody {
		tr {
			background: rgba(27, 31, 35, 0.05);
			border-bottom: 1px solid white;
		}

		td {
			min-width: 145px;

			&:before {
				content: '';
				position: absolute;
				right: 0;
				top: 0px;
				width: 1px;
				height: 100%;
				background: white;
			}
		}
	}
`;

export const DocumentationSectionGroupTitle = styled.a`
	display: block;
	text-transform: capitalize;
	padding-bottom: 20px;
	font-family: PrimaryMedium;
	margin-top: 48px;
	font-size: 26px;
`;

export const DocumentationNavLink = styled(Link)<DocumentationNavLinkProps>`
	display: block;
	color: #6d6d6d;
	font-family: Primary;
	font-size: 15px;
	text-decoration: none;
	text-transform: capitalize;
	width: fit-content;
	border-bottom: ${({ active }) => (active ? `2px solid ${Color.GREEN_3}` : `2px solid transparent`)};
	padding-top: 10px;

	&:first-of-type {
		font-family: PrimaryMedium;
		font-size: 17px;
	}

	&:hover {
		cursor: pointer;
		border-bottom: 2px solid ${Color.GREEN_3};
	}
`;

export const DocumentationSectionGroup = styled.div`
	border-bottom: 1px solid rgba(128, 128, 128, 0.3);
	margin-bottom: 10px;
`;

export const DocumentationEndpoint = styled.code`
	padding: 6px;
	font-size: 15px;
	background-color: rgba(27, 31, 35, 0.05);
	border-radius: 3px;
	font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
`;

export const DocumentationEndpointTitle = styled.div`
	font-family: PrimaryMedium;
	font-size: 20px;
	padding: 15px 0px;
`;

export const DocumentationEndpointDescription = styled.div`
	font-family: Secondary;
	padding-bottom: 15px;
`;

export const DocumentationEndpointRqRs = styled.div`
	font-family: PrimaryMedium;
	font-size: 14px;
	padding: 15px 0px;
`;

export const DocumentationSchemaUrl = styled.a`
	text-decoration: none;
	color: ${Color.ORANGE};
	font-family: PrimaryMedium;
`;

export const DocumentationTable = styled.table`
	border-collapse: collapse;
	font-size: 14px;
	margin: 0;
	padding: 0;
	text-align: left;
	width: 100%;

	thead {
		color: #0b132b;

		@media (max-width: 681px) {
			border: none;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}
	}

	a {
		color: #707070;
		font-family: PrimaryMedium;
		text-decoration: none;
	}

	th {
		padding: 0.925em;
		text-align: left;
		color: #0b132b;
		font-weight: normal;
		font-family: PrimaryMedium;
		font-size: 13px;
		line-height: 15px;
		text-transform: uppercase;
	}

	td {
		padding: 0.925em;
		word-break: normal;
		color: #0b132b;
		font-size: 14px;
		line-height: 16px;
		position: relative;

		&:last-child {
			&::before {
				display: none;
			}
		}

		@media (max-width: 681px) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: row-reverse;
			border-bottom: 1px solid rgba(220, 221, 224, 0.5);
			font-size: 0.8em;

			&:after {
				content: attr(data-label);
				float: left;
				font-family: PrimaryMedium;
				text-transform: uppercase;
			}

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	tbody {
		background: white;

		tr {
			border-radius: 4px 4px 4px 0;
			background: #f9f9f9;

			@media (max-width: 681px) {
				border-bottom: 3px solid #ebebeb;
				display: block;
				text-align: right;
			}
		}
	}
`;

export const StickyWrap = styled.div`
	width: 21vw;
	.sticky-outer-wrapper {
		width: 21vw;
	}
	.sticky-inner-wrapper {
		width: 21vw;
		padding-left: 9vw;
		padding-right: 3vw;
		padding-top: 3vw;
		padding-bottom: 3vw;
	}

	@media (max-width: 1024px) {
		display: none;
	}
`;

export const LogoWrapper = styled.div`
	position: fixed;
	top: 0;
	height: 80px;
	width: 21vw;
	background-color: #f3f3f4;
	z-index: 101;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 1024px) {
		display: none;
	}
`;

export const StickyBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 21vw;
	background-color: #f3f3f4;
	@media (max-width: 1024px) {
		display: none;
	}
`;
