// SIZES
export enum Size {
	SIDEBAR_WIDTH = '250px',
	HEADER_HEIGHT = '74px',
}

// SPACES
export enum Gutter {
	SMALL = '20px',
	MEDIUM = '30px',
	LARGE = '40px',
}

// COLORS
export enum Color {
	GREY_BORDER = '#D7ddeb',
	GRAY_PANELBORDER = '#e5e5e5',
	GRAY_TEXT = '#737886',
	GRAY_BUTTON = '#DCDDE0',
	GRAY_NAVBORDER = '#e9eaec',
	GRAY_MENULINK = '#969AA4',
	GRAY_LIGHT = '#dfe0e6',
	GREY_INFO = '#51526a',
	GRAY = '#4f4f4f',
	GRAY_DARK = '#434343',
	OPTIONAL_BACKGROUND = '#ced0d5',
	OPTIONAL_TEXT = '#2d354a',
	BEIGE = '#A7B2CC',
	ORANGE = '#ff9109',
	ORANGE_INFO = '#ff900a',
	ORANGE_HOVER = '#ff831b',
	GREEN = '#04d45b',
	GREEN_HOVER = '#069B53',
	RED = '#eb5b5e',
	RED_ERROR = '#E7332B',
	RED_HOVER = '#d6241c',
	BLUE = '#1749da',
	YELLOW = '#f8d31c',
	LIGHT_BLUE = '#1592e6',

	WHITE = '#FFFFFF',

	//escrow colors
	GRAY_0 = '#FAFBFC',
	GRAY_1 = '#F7F9FC',
	GRAY_2 = '#EFF3F8',
	GRAY_3 = '#E6EBF2',
	GRAY_4 = '#E1E4ED',
	GRAY_5 = '#D7DDEB',
	GRAY_6 = '#B4BECF',
	GRAY_7 = '#838EA8',
	GRAY_8 = '#53648B',
	GRAY_9 = '#405074',
	GRAY_10 = '#383B6D',
	GRAY_11 = '#243155',
	GRAY_12 = '#142652',
	GRAY_13 = '#DAE8F3',
	GRAY_14 = '#0A122B',
	GRAY_15 = '#191A2A',
	GRAY_16 = '#b8b7ad',
	GRAY_17 = '#e6e6e6',
	GRAY_18 = '#333',
	GRAY_19 = '#ddd',
	GRAY_20 = '#808080',
	GRAY_21 = '#BABCC3',

	BLUE_1 = '#43ADFF',
	BLUE_2 = '#0091FF',
	BLUE_3 = '#0C7ACD',
	BLUE_4 = '#1675F2',
	BLUE_5 = '#1592e6',
	BLUE_6 = '#3558A9',
	BLUE_7 = '#253584',
	BLUE_8 = '#182851',
	BLUE_9 = '#10124E',
	BLUE_10 = '#0B132B',
	BLUE_11 = '#373a47',

	GREEN_1 = '#04d45b',
	GREEN_2 = '#05C355',
	GREEN_3 = '#00BB68',
	GREEN_4 = 'rgba(4,212,91,0.1)',

	RED_1 = '#FFF5F6',
	RED_2 = '#FA4A5B',
	RED_3 = '#E7332B',
	RED_4 = '#d6241c',

	ORANGE_0 = '#ff9109',
	ORANGE_1 = '#ff900a',
	ORANGE_2 = '#ff831b',
}

// SHADOWS
export enum Shadow {
	GRAY = '0 9px 18px -6px rgba(220,221,224, 0.8)',
	ORANGE = '0 9px 18px -6px rgba(255, 116, 14, 0.48)',
	GREEN = '0 9px 18px -6px rgba(67, 212, 84, 0.48)',
	RED = '0 9px 18px -6px rgba(231, 51, 43, 1)',
	BLUE = '0px 9px 18px -6px rgba(14,24,50,0.48)',
	WHITE = '0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(56, 59, 109, 0.12);',
	LIGHT = '0px 1px 1px rgba(0, 0, 0, 0.05)',
}

// TABLE SIZES (in percentages)
const TABLE_ACTIONS_SIZE = 5;

export enum EmailInvoiceColSizes {
	STATUS = 18,
	CREATED = 14,
	DUE_DATE = 15,
	AMOUNT = 17,
	ACTIONS = TABLE_ACTIONS_SIZE,
	RECEIVER = 100 -
		(EmailInvoiceColSizes.STATUS +
			EmailInvoiceColSizes.CREATED +
			EmailInvoiceColSizes.DUE_DATE +
			EmailInvoiceColSizes.AMOUNT +
			EmailInvoiceColSizes.ACTIONS),
}

export enum EscrowColSizes {
	DEAL_CREATED = 15,
	DEAL_COMPLETED = 15,
	AMOUNT = 18,
	STATUS = 18,
	ACTIONS = TABLE_ACTIONS_SIZE,
	BUYER = 100 -
		(EscrowColSizes.DEAL_CREATED +
			EscrowColSizes.DEAL_COMPLETED +
			EscrowColSizes.AMOUNT +
			EscrowColSizes.STATUS +
			EscrowColSizes.ACTIONS),
}

export enum TransactionColSizes {
	DATE_AND_TIME = 20,
	INVOICE_NO = 28,
	STATUS = 13,
	AMOUNT = 16,
	ACTIONS = TABLE_ACTIONS_SIZE,
	TYPE = 100 -
		(TransactionColSizes.DATE_AND_TIME +
			TransactionColSizes.INVOICE_NO +
			TransactionColSizes.STATUS +
			TransactionColSizes.AMOUNT +
			TransactionColSizes.ACTIONS),
}

export enum EnvironmentColSizes {
	ENVIRONMENT_ID = 33,
	USER_ID = 33,
	ACTIONS = TABLE_ACTIONS_SIZE,
	SECRET = 100 - (EnvironmentColSizes.ENVIRONMENT_ID + EnvironmentColSizes.USER_ID + EnvironmentColSizes.ACTIONS),
}

export enum PosPairingColSizes {
	DATE_ADDED = 25,
	ACTIONS = TABLE_ACTIONS_SIZE,
	EMAIL = 25,
	DEVICE = 100 - (PosPairingColSizes.DATE_ADDED + PosPairingColSizes.ACTIONS + PosPairingColSizes.EMAIL),
}

export enum AtmColSizes {
	DATE_ADDED = 35,
	SERIAL = 30,
	ACTIONS = TABLE_ACTIONS_SIZE,
	STATUS = 100 - (AtmColSizes.DATE_ADDED + AtmColSizes.SERIAL + AtmColSizes.ACTIONS),
}

export enum VatmColSizes {
	DATE_ADDED = 30,
	ADDRESS = 50,
	ACTIONS = TABLE_ACTIONS_SIZE,
	STATUS = 100 - (VatmColSizes.DATE_ADDED + VatmColSizes.ADDRESS + VatmColSizes.ACTIONS),
}

export enum WalletColSizes {
	WALLET_NAME = 35,
	LAST_USED = 12,
	DEFAULT = 5,
	ACTIONS = TABLE_ACTIONS_SIZE,
	WALLET_ADDRESS = 100 -
		(WalletColSizes.WALLET_NAME + WalletColSizes.LAST_USED + WalletColSizes.DEFAULT + WalletColSizes.ACTIONS),
}
export enum UserColSizes {
	LAST_LOGIN = 15,
	STATUS = 10,
	ROLE = 25,
	ACTIONS = TABLE_ACTIONS_SIZE,
	USER = 100 - (UserColSizes.LAST_LOGIN + UserColSizes.STATUS + UserColSizes.ROLE + UserColSizes.ACTIONS),
}
export enum CustomerLimitColSizes {
	LIMIT_NAME = 18,
	DAILY_LIMIT = 14,
	MONTHLY_LIMIT = 14,
	UPDATED = 12,
	ACTIONS = TABLE_ACTIONS_SIZE,
	WALLET_ADDRESS = 100 -
		(CustomerLimitColSizes.LIMIT_NAME +
			CustomerLimitColSizes.DAILY_LIMIT +
			CustomerLimitColSizes.MONTHLY_LIMIT +
			CustomerLimitColSizes.UPDATED +
			CustomerLimitColSizes.ACTIONS),
}
