import * as React from 'react';
import { useState } from 'react';
import ReactSVG from 'react-svg';

import { NavSidebarIcons, RoutesUrls } from '../../constants';
import { hasPermission } from '../../services/has-permission';
import { privateRoutes } from '../../views/private/routes';
import Logo from '../logo/Logo';

import {
	PrivateSidebarIcon,
	PrivateSidebarLogo,
	PrivateSidebarNavigation,
	PrivateSidebarNavLink,
	PrivateSidebarVersion,
	StickyWrapper,
	StyledPrivateSidebar,
	PrivateSidebarNavText,
	PrivateSidebarSubMenuItem,
	ArrowDownIcon,
	PrivateMobileMenu,
	PrivateMobileMenuItem
} from './PrivateSidebarStyle';

export const getIcon = (iconName: NavSidebarIcons) => {
	switch (iconName) {
		case NavSidebarIcons.DASHBOARD:
			return <ReactSVG src="/files/svg/private/sidebar/Dashboard.svg" />;
		case NavSidebarIcons.POS:
			return <ReactSVG src="/files/svg/private/sidebar/Pos.svg" />;
		case NavSidebarIcons.EMAIL_INVOICE:
			return <ReactSVG src="/files/svg/private/sidebar/EmailInvoice.svg" />;
		case NavSidebarIcons.TRANSACTIONS:
			return <ReactSVG src="/files/svg/private/sidebar/Transactions.svg" />;
		case NavSidebarIcons.ESCROW:
			return <ReactSVG src="/files/svg/private/sidebar/Escrow.svg" />;
		case NavSidebarIcons.MERCHANT_TOOLS:
			return <ReactSVG src="/files/svg/private/sidebar/MerchantTools.svg" />;
		case NavSidebarIcons.SETTINGS:
			return <ReactSVG src="/files/svg/private/sidebar/Settings.svg" />;
		case NavSidebarIcons.DOCUMENTATION:
			return <ReactSVG src="/files/svg/private/sidebar/Documentation.svg" />;
		default:
			return <div />;
	}
};

export const arrowIcon = <ArrowDownIcon><ReactSVG src="/files/svg/private/sidebar/Arrow.svg" /></ArrowDownIcon>

export default function PrivateSidebar() {
	const privateSidebarLinks = privateRoutes.filter(
		(privateRoute) => privateRoute.navSidebarTitle && hasPermission(privateRoute.permissions),
	);

	const [expandedLink, setExpandedLink] = useState<string | null>(null);

	return (
		<StyledPrivateSidebar>
			<StickyWrapper>
				<PrivateSidebarLogo>
					<Logo to="/private/dashboard" />
				</PrivateSidebarLogo>
				<PrivateSidebarNavigation>
					{privateSidebarLinks.map((link) => {
						// render child links if link has any
						if (link.expanded) {
							return <div key={link.url}>
								<PrivateSidebarNavLink
									exact={true}
									key={link.url}
									to={link.url}
									target={link.openInNewTab ? '_blank' : ''}
									// only handle if link has children
									onClick={link.children ? (event: any) => {
										setExpandedLink(expandedLink === link.url ? null : link.url);
										// dont navigate when link expands to show child links
										event.preventDefault();
									} : null}
									className={link.url === expandedLink ? 'active_dropdown': null} 
								>
									{link.icon && <PrivateSidebarIcon>{getIcon(link.icon)}</PrivateSidebarIcon>}
									<PrivateSidebarNavText>{link.navSidebarTitle} {arrowIcon}</PrivateSidebarNavText>
								</PrivateSidebarNavLink>
						
								{link.children && link.children.map((childLink) => {
									const isChildOfExpandedLink = expandedLink && childLink.url.startsWith(expandedLink);
									const isVisibleLink = childLink.navLinkTitle !== undefined && hasPermission(childLink.permissions);
									return (
										isVisibleLink && isChildOfExpandedLink
									) &&
									<PrivateSidebarSubMenuItem key={childLink.url}>
										<PrivateSidebarNavLink
											exact={childLink.url !== RoutesUrls.PRIVATE ? false : true}
											key={childLink.url}
											to={childLink.url}
											target={childLink.openInNewTab ? '_blank' : ''}
										>
										<PrivateSidebarNavText>{childLink.navLinkTitle}</PrivateSidebarNavText>
										</PrivateSidebarNavLink>
									</PrivateSidebarSubMenuItem>
								})}
							</div>
						}

						return <PrivateSidebarNavLink
							exact={link.url !== RoutesUrls.PRIVATE ? false : true}
							key={link.url}
							to={link.url}
							target={link.openInNewTab ? '_blank' : ''}
						>
							{link.icon && <PrivateSidebarIcon>{getIcon(link.icon)}</PrivateSidebarIcon>}
							<PrivateSidebarNavText>{link.navSidebarTitle}</PrivateSidebarNavText>
						</PrivateSidebarNavLink>
					})}

					<PrivateMobileMenu>
						<PrivateMobileMenuItem>
							<a href="https://help.dagpay.io/en/" target="_blank" rel="noopener noreferrer"><ReactSVG src="/files/svg/private/SupportMobile.svg"/>Support</a>
						</PrivateMobileMenuItem>
						<PrivateMobileMenuItem>
							<a href={RoutesUrls.LOGOUT} id="logout"><ReactSVG src="/files/svg/private/ExitMobile.svg"/>Log out</a>
						</PrivateMobileMenuItem>
					</PrivateMobileMenu>
				</PrivateSidebarNavigation>
			</StickyWrapper>
			<PrivateSidebarVersion>
				{process.env.REACT_APP_VERSION ? `v. ${process.env.REACT_APP_VERSION}` : ''}
			</PrivateSidebarVersion>
		</StyledPrivateSidebar>
	);
}
