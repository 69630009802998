import styled, { css } from 'styled-components/macro';
import { Color, Gutter } from '../../../gfx/constants';
import { media } from '../../../services/media';

export const ExternalInvoiceWrapper = styled.div`
	height: 100%;
	width: 100%;
	min-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: auto;
`;

export const Container = styled.div`
	height: 100%;
	min-height: 100%;
	width: 100%;
	min-width: 100%;
	overflow: auto;

	${media.fromSize1000`
		display: flex;
		border-radius: 4px;
		width: 924px;
		min-width: 924px;
		height: 626px;
		min-height: 626px;
		margin: ${Gutter.SMALL} 0;
		box-shadow: 0 12px 21px 8px rgba(0, 0, 0, 0.2);
		overflow: hidden;
	`}
`;

export const Sidebar = styled.div`
	min-height: 100%;
	background: ${Color.BLUE_8};
	display: flex;
	flex-direction: column;
	position: relative;
	color: ${Color.WHITE};
	padding: 55px 26px 10px 52px;
	width: 352px;
	min-width: 352px;

	svg {
		fill: ${Color.GREEN_3};
	}

	${media.maxSize1000`
		padding: 100px;
		min-width: 100%;
	`}

	${media.maxSize800`
		padding: 50px;
	`}

	${media.maxSize481`
		padding: 20px;
	`}
`;

export const MaximizedContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;

	${media.maxSize1000`
		display: none;
	`}
`;

interface ContentTitleProps {
	color?: Color;
	weight?: number;
	size?: number;
	height?: number;
}

export const ContentTitle = styled.div<ContentTitleProps>`
	font-family: Primary;
	font-weight: ${(props) => (props.weight ? props.weight : 700)};
	font-size: ${(props) => (props.size ? `${props.size}px` : '24px')};
	line-height: ${(props) => (props.height ? `${props.height}px` : '28px')};
	text-align: center;
	color: ${(props) => (props.color ? props.color : 'inherit')};

	${media.maxSize481`
		font-size: 22px;
	`};
`;

export const MaximizedText = styled.div<{
	strong?: boolean;
	marginTop?: number;
	marginBottom?: number;
	hasAction?: boolean;
	color?: Color;
	height?: number;
}>`
	font-family: Secondary;
	font-size: 16px;
	line-height: ${(props) => (props.height ? `${props.height}px` : '21px')};
	text-align: center;
	margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : 0)};
	margin-bottom: ${(props) => (props.marginBottom ? `${props.marginBottom}px` : 0)};
	color: ${(props) => (props.color ? `${props.color}` : `${Color.GRAY_12}`)};

	${(props) =>
		props.strong &&
		css`
			font-family: PrimaryMedium;
			font-size: 14px;
			line-height: 16px;
		`}

	${(props) =>
		props.hasAction &&
		css`
			font-family: PrimaryMedium;
			font-size: 16px;
			line-height: 19px;
			text-decoration: underline;
			cursor: pointer;
		`}
`;

export const CopyBlockRow = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 16px 18px;
`;

export const CopyBlockWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${Color.GRAY_0};
	border: 1px solid ${Color.GRAY_3};
	border-radius: 4px;
	width: 400px;
	max-width: 400px;

	${CopyBlockRow}:first-child {
		border-bottom: 1px solid ${Color.GRAY_3};
	}
`;

export const CopyBlockRowLabel = styled.div`
	font-family: PrimaryMedium;
	font-size: 14px;
	line-height: 16px;
	color: ${Color.GRAY_7};
`;

export const CopyBlockRowValue = styled.div`
	display: flex;
	font-family: PrimaryMedium;
	font-size: 14px;
	line-height: 16px;
	color: ${Color.GRAY_DARK};
`;

export const ValueItem = styled.span`
	display: flex;
	margin-right: 7px;
	font-family: PrimaryMedium;
	font-size: 14px;
	line-height: 16px;
	color: ${Color.GRAY_DARK};
`;

export const CopyBlockRowButton = styled.div`
	font-family: PrimaryMedium;
	font-size: 14px;
	line-height: 16px;
	color: ${Color.GREEN_2};
	cursor: pointer;
`;

export const SidebarBlock = styled.div<{ marginTop?: number; marginBottom?: number }>`
	display: grid;
	align-items: center;
	grid-template-columns: 30px auto;
	grid-template-rows: auto auto;

	margin-top: ${(props) => props.marginTop && props.marginTop}px;
	margin-bottom: ${(props) => props.marginBottom && props.marginBottom}px;

	width: 100%;
	font-family: Primary;
	font-size: 16px;
	line-height: 19px;
	color: ${Color.WHITE};

	div,
	div div {
		display: flex;
		align-items: center;
	}
`;

export const SidebarBlockCell = styled.div<{
	columnStart: number;
	columnEnd: number;
	rowStart: number;
	rowEnd: number;
	paddingTop?: number;
}>`
	height: 100%;
	width: 100%;

	grid-column-start: ${(props) => props.columnStart};
	grid-column-end: ${(props) => props.columnEnd};
	grid-row-start: ${(props) => props.rowStart};
	grid-row-end: ${(props) => props.rowEnd};
	padding-top: ${(props) => props.paddingTop && props.paddingTop}px;

	font-family: Primary;
	font-size: 16px;
	line-height: 19px;
	color: ${Color.WHITE};

	/* adjust icons */
	div,
	div div {
		display: flex;
		align-items: center;
	}
`;

export const BoldCellItem = styled.div`
	display: flex;
	align-items: center;
	font-family: PrimaryMedium;
	font-size: 18px;
	line-height: 18px;
	color: ${Color.WHITE};
`;

export const BaseAmount = styled.div`
	height: 24px;
	font-family: Primary;
	font-size: 16px;
	line-height: 24px;
	color: ${Color.GRAY_7};
`;

export const CurrencyPair = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start !important;
	position: relative;
`;

export const ExpirationBlock = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 17px;
	letter-spacing: 0.09px;
	line-height: 23px;
	margin: auto;
	${media.fromSize481`
		margin: 10px auto auto;
	`}
	${media.fromSize1000`
		margin: 24px 0 90px 0;
	`};
`;

export const WarningContentWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	box-sizing: border-box;
	text-align: left;
	font-family: secondary;
	font-size: 14px;
	line-height: 21px;

	div {
		text-align: right;
		display: inline-block;
		float: right;
	}
`;

export const ContentWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	box-sizing: border-box;
	text-align: center;
	font-family: secondary;
	font-size: 14px;
	line-height: 21px;

	h2 {
		font-size: 16px;
		font-family: primaryMedium;
		line-height: 21px;
	}
	span {
		text-align: center;
		font-family: secondary;
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 20px;
	}

	${media.maxSize481`
		text-align: left;
	`}
`;

export const CancelBlock = styled.div`
	button:hover {
		background-color: ${Color.RED_3} !important;
		border: none !important;
	}
`;

export const CancelModalButtonWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	& > :first-child {
		margin-right: 14px;
	}

	${media.maxSize481`
		flex-direction: column;

		& > :first-child {
		margin-right: 0;
		margin-bottom: 14px;
	}
	`}
`;

export const UnorderedList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

export const SecondaryViewWrapper = styled.div<{ disableStyles?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	padding: 48px 80px 30px 80px;

	${(props) =>
		!props.disableStyles &&
		css`
			${media.maxSize1000`
				padding: 100px;
			`}

			${media.maxSize800`
				padding: 50px;
			`}
			
			${media.maxSize481`
				padding: 20px;
			`}
		`}
`;
