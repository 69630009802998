import React, { useState } from 'react';
import Fade from 'react-reveal';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactSVG from 'react-svg';

import { InvoiceTypes } from '../../../../api/invoiceApi';
import Modal from '../../../../components/modal/Modal';
import { Currency } from '../../../../constants';
import { Color } from '../../../../gfx/constants';
import { handleCopyToClipboard } from '../../../../services/handle-copy-to-clipboard';
import { InvoiceViewTplProps } from '../../InvoiceViewTpl';
import { DagcoinCardContent } from '../DagcoinCard/DagcoinCardContent';

import {
	AwaitingText,
	CancelWrapper,
	CardSlideWrapper,
	CoinAndCurrencyBlock,
	CopyInvoiceId,
	CurrencyAmount,
	DagAmount,
	DetailsWrapper,
	Expiration,
	ExpiresText,
	Header,
	HeaderBlockIconWrapper,
	HelpIcon,
	HelpLink,
	MobileCancelPayment,
	MobilePopUpWrapper,
	MobileSidebar,
	ModalBackButton,
	ModalInfoWrapper,
	ModalMessage,
	ModalTitle,
	PaymentDetailRow,
	PaymentDetails,
	PaymentDetailTitle,
	PayWithCardButton,
	PopUpMessage,
	ScanText,
	TapToScan,
	Title,
} from './MobileInvoiceDetailStyle';

interface Props extends InvoiceViewTplProps {
	payWrapperRef: React.MutableRefObject<any>;
	timer: JSX.Element | string;
	getQrCode(): JSX.Element | undefined;
}

export default function MobileInvoiceDetail(props: Props) {
	const { invoice, canCancel, handleCancelPayment, payWrapperRef, escrowProductInfo } = props;

	const [showQrCode, setShowQrCode] = useState<boolean | null>(null);
	const [animationIsActive, setAnimationIsActive] = useState(false);

	const handleOnClick = () => {
		setAnimationIsActive(true);
		handleCopyToClipboard(invoice.id);
	};

	const MobileCopyInvoiceId = () => (
		<CopyInvoiceId onClick={handleOnClick}>
			Copy Payment ID
			<ReactSVG src="/files/svg/invoice/CopyLink.svg" />
			{animationIsActive && (
				<MobilePopUpWrapper onAnimationEnd={() => setAnimationIsActive(false)}>
					<PopUpMessage>Payment ID copied to clipboard</PopUpMessage>
				</MobilePopUpWrapper>
			)}
		</CopyInvoiceId>
	);

	const ExpirationBlock = () => (
		<Expiration>
			<AwaitingText>
				<div>Awaiting payment</div>
				<ClipLoader color={Color.GREEN_3} sizeUnit={'px'} size={20} />
			</AwaitingText>
			<ExpiresText>Expires in {props.timer}</ExpiresText>
		</Expiration>
	);

	const DetailsBlock = () => (
		<PaymentDetails>
			<DetailsWrapper bottomBorder={invoice.type !== InvoiceTypes.POS}>
				<PaymentDetailRow>
					<PaymentDetailTitle>
						<ReactSVG src="/files/svg/invoice/WalletGreenIcon.svg" />
						<Title>Amount</Title>
					</PaymentDetailTitle>
					<CoinAndCurrencyBlock>
						<DagAmount>
							<ReactSVG src="/files/svg/invoice/DagcoinCurrencyIcon.svg" />
							{`${invoice.coinAmount} DAG`}
						</DagAmount>
						{invoice.currency !== Currency.DAG && (
							<CurrencyAmount>{`${invoice.currencyAmount} ${invoice.currency}`}</CurrencyAmount>
						)}
					</CoinAndCurrencyBlock>
				</PaymentDetailRow>

				<PaymentDetailRow>
					<PaymentDetailTitle>
						<ReactSVG src="/files/svg/invoice/ReceiverIcon.svg" />
						<Title>Received by</Title>
					</PaymentDetailTitle>
					<Title>{props.invoice ? props.invoice.merchantName : ''}</Title>
				</PaymentDetailRow>
				{escrowProductInfo && (
					<PaymentDetailRow>
						<PaymentDetailTitle>
							<ReactSVG src="/files/svg/invoice/ReceiverIcon.svg" />
							<Title>Description</Title>
						</PaymentDetailTitle>
						<Title>{escrowProductInfo}</Title>
					</PaymentDetailRow>
				)}
			</DetailsWrapper>
			{invoice.type !== InvoiceTypes.POS && <MobileCopyInvoiceId />}
		</PaymentDetails>
	);

	const QrBlock = () => (
		<TapToScan
			onClick={() => {
				setShowQrCode(true);
			}}
		>
			<ScanText>Tap to scan QR code</ScanText>
			<ReactSVG src="/files/svg/invoice/TapToScan.svg" />
		</TapToScan>
	);

	const handleModalClose = () => {
		setShowQrCode(false);
	};

	const getQrModal = () => {
		const { getQrCode, payWrapperRef } = props;
		const appElement = document.getElementById('root');

		if (payWrapperRef.current && appElement && showQrCode) {
			return (
				<Modal
					appElement={appElement}
					parentSelector={() => {
						// error on window resize, temporary fix
						// https://github.com/reactjs/react-modal/issues/769#issuecomment-533326866
						return payWrapperRef.current || { removeChild: () => {} };
					}}
					style={{
						overlay: { position: 'absolute' },
					}}
					ariaHideApp={false}
					isOpen={true /* showQrCode */}
					onRequestClose={() => handleModalClose()}
				>
					<ModalInfoWrapper>
						<ModalTitle>Pay invoice</ModalTitle>
						<ModalMessage>Scan QR code with your DagWallet to proceed with payment</ModalMessage>

						{getQrCode()}
						<ModalBackButton onClick={() => handleModalClose()}>Back</ModalBackButton>
					</ModalInfoWrapper>
				</Modal>
			);
		}

		return null;
	};

	const CancelBlock = () => (
		<>
			{canCancel && (
				<CancelWrapper>
					<MobileCancelPayment onClick={handleCancelPayment}>Cancel payment</MobileCancelPayment>
				</CancelWrapper>
			)}
		</>
	);

	const isCardPayment = /card-payment/.exec(props.location.pathname) !== null;

	return (
		<Fade>
			<MobileSidebar>
				<HeaderBlock />
				<ExpirationBlock />
				<DetailsBlock />
				<QrBlock />
				<PayWithCardButton
					disabled={invoice.atmOnly}
					type={'button'}
					onClick={() => props.history.push(`${props.location.pathname}/card-payment`)}
				>
					Pay with Dagcoin card
				</PayWithCardButton>
				<CancelBlock />
				<CardSlideWrapper slide={isCardPayment}>
					<DagcoinCardContent {...props} parentContainerRef={payWrapperRef} />
				</CardSlideWrapper>
				{getQrModal()}
			</MobileSidebar>
		</Fade>
	);
}

export function HeaderBlock({ color }: { color?: string }) {
	return (
		<Header color={color}>
			<HeaderBlockIconWrapper>
				<ReactSVG src="/files/svg/logos/DagcoinWhiteLogoSquared.svg" />
			</HeaderBlockIconWrapper>
			<HelpLink target="_blank" href="https://help.dagpay.io/en/articles/293969-how-do-i-pay-a-dagpay-invoice">
				<span>Help</span>
				<HelpIcon>
					<ReactSVG src="/files/svg/icons/Help.svg" />
				</HelpIcon>
			</HelpLink>
		</Header>
	);
}
